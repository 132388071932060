import {
  gameMarketAdminConstants
} from '../_constants';

export function gameMarketAdmin(state = {}, action) {

  switch (action.type) {
    case gameMarketAdminConstants.ADD_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state
      };
    case gameMarketAdminConstants.ADD_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case gameMarketAdminConstants.ADD_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case gameMarketAdminConstants.GET_LIST_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameMarketAdminConstants.GET_LIST_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getGameMarketAdminList.list,
        total: action.users.getGameMarketAdminList.total
      };
    case gameMarketAdminConstants.GET_LIST_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameMarketAdminConstants.GETALL_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameMarketAdminConstants.GETALL_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case gameMarketAdminConstants.GETALL_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gameMarketAdminConstants.UPDATE_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state
      };
    case gameMarketAdminConstants.UPDATE_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case gameMarketAdminConstants.UPDATE_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case gameMarketAdminConstants.DELETE_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameMarketAdminConstants.DELETE_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameMarketAdminConstants.DELETE_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameMarketAdminConstants.DISABLE_GAME_MARKET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameMarketAdminConstants.DISABLE_GAME_MARKET_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameMarketAdminConstants.DISABLE_GAME_MARKET_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}