import {
  userConstants, blogConstants
} from '../_constants';

export function blog(state = {}, action) {

  switch (action.type) {
    case blogConstants.GET_BLOG_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case blogConstants.GET_BLOG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogListItems: action.users.getBlogList.list,
        blogListTotal: action.users.getBlogList.total,
      };
    case blogConstants.GET_BLOG_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,

      };

    case blogConstants.UPDATE_BLOG_REQUEST:
      return {
        ...state
      };
    case blogConstants.UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case blogConstants.UPDATE_BLOG_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case blogConstants.ADD_BLOG_REQUEST:
      return {
        ...state
      };
    case blogConstants.ADD_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case blogConstants.ADD_BLOG_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case blogConstants.GET_LIST_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case blogConstants.GET_LIST_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getBlogList.list,
        total: action.users.getBlogList.list
      };
    case blogConstants.GET_LIST_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case blogConstants.GETALL_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case blogConstants.GETALL_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        videoItems: action.users.getAllvideo,
        videoTotal: action.users.getAllvideo
      };
    case blogConstants.GETALL_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_BLOG_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_BLOG_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_BLOG_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}