import React, { useState } from "react";

import Modal from 'react-modal';



export default function CreateUpdatePasswordDealer(props) {

  let { UpdatePasswordCreateModal, inputChange, handleAppSeetingHideModal, errorsUpdatePassword, updateEventOptionSubmit, fieldsUpdatePassword, handleFile,
    changeStartUpdateDate, changeEndUpdateDate,
    // allCategory, 
    contentFieldUpdate,
    setCurrentIndexUpdate, handleUpload, allGameItems,
    // handleUploadImageUser,
    handleSelectedTagUpdate, options, updateSelectedTag,
    deleteContentFieldUpdate, inputChangeContentUpdate, offset,
    // tagFieldUpdate, deletetagFieldUpdate, inputChangetagUpdate,
    rteChangeUpdate, modules, formats, categoryListItems, subCategoryListItems } = props;

  console.log("MODAL______fieldsUpdatePassword::", fieldsUpdatePassword);
  // console.log("MODAL______fieldsUpdatePassword.category::", fieldsUpdatePassword.category);

  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Update EventOption</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div className="flex space-x-2 mt-2">
                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Select Option :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      id="option"
                      name="option"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.option ? fieldsUpdatePassword.option : null}
                      onChange={inputChange}
                    >
                      <option selected value="">Plz Select Option</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                    {errorsUpdatePassword && errorsUpdatePassword["option"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["option"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="flex space-x-2 mt-2">
                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Select Price :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      id="price"
                      name="price"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.price ? fieldsUpdatePassword.price : null}
                      onChange={inputChange}
                    >
                      <option selected value="">Plz Select Price</option>
                      <option value="0.5">0.5</option>
                      <option value="1.0">1.0</option>
                      <option value="1.5">1.5</option>
                      <option value="2.0">2.0</option>
                      <option value="2.5">2.5</option>
                      <option value="3.0">3.0</option>
                      <option value="3.5">3.5</option>
                      <option value="4.0">4.0</option>
                      <option value="4.5">4.5</option>
                      <option value="5.0">5.0</option>
                      <option value="5.5">5.5</option>
                      <option value="6.0">6.0</option>
                      <option value="6.5">6.5</option>
                      <option value="7.0">7.0</option>
                      <option value="7.5">7.5</option>
                      <option value="8.0">8.0</option>
                      <option value="8.5">8.5</option>
                      <option value="9.0">9.0</option>
                      <option value="9.5">9.5</option>
                    </select>
                    {errorsUpdatePassword && errorsUpdatePassword["price"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["price"]}
                      </div>
                      : null}
                  </div>



                </div>
                <div className="flex space-x-2 mt-2">
                  <div className="w-full ">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Quantity</label>
                    <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsUpdatePassword && !errorsUpdatePassword["quantity"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="quantity" name="quantity" placeholder="Quantity..." value={fieldsUpdatePassword.quantity} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["quantity"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["quantity"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateEventOptionSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
