import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Sidebar from "../Sidebar";
import Video from "../../pages/video/Video";
import Blog from "../../pages/blog/blog";

import slider from "../../pages/slider/slider";
import AppSetting from "../../pages/AppSetting/AppSetting";
import Event from "../../pages/Event/Event";
import EventOption from "../../pages/EventOption/EventOption";
import DisableEvent from "../../pages/Event/DisableEvent";
import paymenttype from "../../pages/Payment Type/paymenttype";
import Offer from "../../pages/offer/offer";
import fancy from "../../pages/fancy/fancy";
import MainPage from "../../pages/MainPage/MainPage";
import WordLimit from "../../pages/WordLimit/WordLimit";
import RichTextEditor from "../../pages/RichTextEditor/RichTextEditor";
import Notification from "../../pages/notification";
import UserActive from "../../pages/useractive/UserActive";
import profileAndAddress from "../../pages/profileAndAddress/profileAndAddress";
import userPayment from "../../pages/userPayment/userPayment";
import userDetails from "../../pages/userDetails/userDetails";
import website from "../../pages/website/website";
import WebsiteticketManagement from "../../pages/WebsiteticketManagement/WebsiteticketManagement";
import WalletticketManagement from "../../pages/WalletticketManagement/WalletticketManagement";
import walletTicketdetails from "../../pages/walletTicketdetails/walletTicketdetails";
import EventDetails from "../../pages/EventDetails/EventDetails";
import Upi from "../../pages/upi/upi";
import Bank from "../../pages/Bank/bank";
import UserWebsiteManagement from "../../pages/UserWebsiteManagement/UserWebsiteManagement";
import Notify from "../../pages/Notify";


function Layout(props) {
  return (
    <div >
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="flex flex-col justify-center min-h-screen">
              <div className="flex h-screen overflow-hidden" >
                <Sidebar history={props.history} />
                <div className="flex flex-col flex-1 w-0 overflow-hidden">
                  <Header history={props.history} />
                  <Switch>
                    <Route path="/app/dashboard" component={withRouter(Dashboard)} />
                    <Route path="/app/event" component={withRouter(Event)} />
                    <Route path="/app/eventoption/:eventId" component={withRouter(EventOption)} />
                    <Route path="/app/disableevent" component={withRouter(DisableEvent)} />
                    <Route path="/app/video" component={withRouter(Video)} />
                    <Route path="/app/Blog" component={withRouter(Blog)} />
                    <Route path="/app/notify" component={withRouter(Notify)} />
                    <Route path="/app/fancy" component={withRouter(fancy)} />
                    <Route path="/app/wordLimit" component={withRouter(WordLimit)} />
                    <Route path="/app/RichTextEditor" component={withRouter(RichTextEditor)} />
                    <Route path="/app/slider" component={withRouter(slider)} />
                    <Route path="/app/appsetting" component={withRouter(AppSetting)} />
                    <Route path="/app/paymenttype" component={withRouter(paymenttype)} />
                    <Route path="/app/offer" component={withRouter(Offer)} />
                    <Route path="/app/notification" component={withRouter(Notification)} />
                    <Route path="/app/mainpage" component={withRouter(MainPage)} />
                    <Route path="/app/useractive" component={withRouter(UserActive)} />
                    <Route path="/app/website" component={withRouter(website)} />
                    <Route path="/app/websiteticketmanagement" component={withRouter(WebsiteticketManagement)} />
                    <Route path="/app/walletticketmanagement" component={withRouter(WalletticketManagement)} />
                    <Route path="/app/userwebsitemanagement" component={withRouter(UserWebsiteManagement)} />
                    <Route path="/app/profileAndAddress/:userId" component={withRouter(profileAndAddress)} />
                    <Route path="/app/walletticketdetails/:ticketId" component={withRouter(walletTicketdetails)} />
                    <Route path="/app/eventdetails/:eventId" component={withRouter(EventDetails)} />
                    <Route path="/app/Upi" component={withRouter(Upi)} />
                    <Route path="/app/Bank" component={withRouter(Bank)} />
                    <Route path="/app/userPayment/:userId" component={withRouter(userPayment)} />
                    <Route path="/app/userDetails/:userId" component={withRouter(userDetails)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
