import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddUserModal from "./components/CreateAddUserModal/CreateAddUserModal";
import UpdatePasswordUser from "./components/CreateUpdatePasswordUserModal/CreateUpdatePasswordUser";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class UserActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsAddUser: {},
      errorsAddUser: {},
      fieldsUpdatePassword: {},
      errorsUpdatePassword: {},
      viewRowData: {},
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      // "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsAddUser: {},
        errorsAddUser: {},
        addUserCreateModal: false,
        UpdatePasswordCreateModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, pageNo: data.selected });
    let datatemp = {
      // "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("value kya aa rhi h::::", value);
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      // "userType": "ACTIVE",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      // "userType/": "ACTIVE",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of user?',
      message: `Are you sure for ${data.userName} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.disableUser(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteUser = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      // "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.userName} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteUser(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      // "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {
    this.props.history.push("/app/profileAndAddress/" + data.id)
  }
  handleOpenCreateModalPaymentDetails = (data) => {
    console.log('datadatadata_____________', data);
    this.props.history.push("/app/userPayment/" + data.id)
  }
  handleOpenCreateModalIdDetails = (data) => {
    console.log('userPaymentuserPaymentuserPayment', data);
    this.props.history.push("/app/userDetails/" + data.id)
  }
  handleOpenCreateModalUpdatePassword = (data) => {
    console.log('dataatdttattttttttttttttttttttttttttttttttttttttttttttttttttt', data);
    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(userActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsAddUser[name] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    // console.log("name", value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  addUserSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationAddUser()) {
      let reqData = {
        "name": this.state.fieldsAddUser.name,
        "mobile": this.state.fieldsAddUser.mobile,
        "mobile": this.state.fieldsAddUser.mobile,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }
      let paginationData = {
        // "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(userActions.registerUserAdmin(reqData, paginationData));
    }

  }
  updateUserPasswordSubmit = () => {

    if (this.handleValidationUpdatePassword()) {
      let reqData = {
        "userId": this.state.fieldsUpdatePassword.id,
        "newpassword": this.state.fieldsUpdatePassword.newpassword,

      }
      let paginationData = {
        // "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(userActions.updateUserPasswordSubmit(reqData, paginationData));
    }

  }
  handleValidationUpdatePassword = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //Email
    if (!fieldsUpdatePassword["newpassword"] || fieldsUpdatePassword["newpassword"] === "") {
      formIsValid = false;
      errorsUpdatePassword["newpassword"] = "Cannot be empty";
    }

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }
  handleValidationAddUser = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //Email
    if (!fieldsAddUser["name"] || fieldsAddUser["name"] === "") {
      formIsValid = false;
      errorsAddUser["name"] = "Cannot be empty";
    }

    if (!fieldsAddUser["mobile"] || fieldsAddUser["mobile"] === "") {
      formIsValid = false;
      errorsAddUser["mobile"] = "Cannot be empty";
    }


    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }
  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  render() {

    let { users } = this.props;
    let { filesDetails } = users;
    let {
      items,
      userActiveTotal, loading, loginToThisAccountItems, profile, userActiveItems } = users;
    // console.log("{{{{{{{{{{{{:::::::loginToThisAccountItems", loginToThisAccountItems);
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^userActiveItems', this.state.fieldsUpdatePassword.id);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white  col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold text-dark-400 tracking-widest">Users</h3>
                    </div>



                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-4 left-5 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-3 py-3 rounded-full border border-dark-400 bg-gray-200 placeholder-gray-600 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 transition duration-150 ease-in-out text-normal font-normal sm:leading-5 pl-10" id="amount" placeholder="Search mobile..." type="amount" onChange={this.handleSearch} required />
                    </div>

                    <div className="flex justify-end ...">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-7 border border-blue-700 rounded flex justify-end "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div>

                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-gray-200">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                userActiveItems && userActiveItems.length > 0 ?
                                  userActiveItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.userName}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.mobile}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">
                                        <span className="inline-block pl-1">
                                          <button className="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 text-sm  rounded-md hover:shadow-lg hover:bg-yellow-400" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More</button>
                                        </span>
                                        {/* <span className="inline-block pl-1">
                                          <button className="bg-blue-600 px-4 py-2 shadow-sm font-medium tracking-wider text-sm border text-green-100 rounded-md hover:shadow-lg hover:bg-blue-400"
                                            onClick={() => this.handleOpenCreateModalPaymentDetails(element)}
                                          >
                                            User Payment</button>
                                        </span> */}

                                        {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> */}
                                        <span className="inline-block pl-1">
                                          {element.isDisable == false ?
                                            <button className="bg-green-700 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 text-sm rounded-md hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Enabled</label>
                                            </button>
                                            : <button className="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 text-sm rounded-md hover:shadow-lg hover:bg-red-400 cursor-pointer" onClick={() => this.disableUser(element)}>

                                              <label>Disabled</label>
                                            </button>}
                                        </span>
                                        {/* </td> */}

                                        <span className="inline-block pl-1">
                                          <button className="bg-red-600 px-4 py-2 shadow-sm font-medium text-sm  tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteUser(element)}>Delete</button>
                                        </span>
                                        {/* <span className="inline-block pl-1">
                                          <button className="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.handleOpenloginToThisAccountModal(element)}>Login</button>
                                        </span> */}
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-500">Not cound</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userActiveTotal && userActiveTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userActiveTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            userActiveTotal && userActiveTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={userActiveTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddUserModal
          filesDetails={filesDetails}
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          addUserSubmit={this.addUserSubmit}
          handleAddUserHideModal={this.handleAddUserHideModal}
        />

        <UpdatePasswordUser
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChange={this.inputChange}
          updateUserPasswordSubmit={this.updateUserPasswordSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={profile ? profile : {}}
          // errorsUpdatePassword={this.state.errorsUpdatePassword}
          // inputChange={this.inputChange}
          // appSettingSubmit={this.appSettingSubmit}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          loginToThisAccountItems={loginToThisAccountItems}
          // errorsUpdatePassword={this.state.errorsUpdatePassword}
          // inputChange={this.inputChange}
          // appSettingSubmit={this.appSettingSubmit}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(UserActive);
