export const rankingConstants = {

    GETALL_RANKING_REQUEST: 'GETALL_RANKING_REQUEST',
    GETALL_RANKING_SUCCESS: 'GETALL_RANKING_SUCCESS',
    GETALL_RANKING_FAILURE: 'GETALL_RANKING_FAILURE',

    GET_LIST_RANKING_REQUEST: 'GET_LIST_RANKING_REQUEST',
    GET_LIST_RANKING_SUCCESS: 'GET_LIST_RANKING_SUCCESS',
    GET_LIST_RANKING_FAILURE: 'GET_LIST_RANKING_FAILURE',

    ADD_RANKING_REQUEST: 'ADD_RANKING_REQUEST',
    ADD_RANKING_SUCCESS: 'ADD_RANKING_SUCCESS',
    ADD_RANKING_FAILURE: 'ADD_RANKING_FAILURE',

    DELETE_RANKING_REQUEST: 'DELETE_RANKING_REQUEST',
    DELETE_RANKING_SUCCESS: 'DELETE_RANKING_SUCCESS',
    DELETE_RANKING_FAILURE: 'DELETE_RANKING_FAILURE',

    UPDATE_RANKING_REQUEST: 'UPDATE_RANKING_REQUEST',
    UPDATE_RANKING_SUCCESS: 'UPDATE_RANKING_SUCCESS',
    UPDATE_RANKING_FAILURE: 'UPDATE_RANKING_FAILURE',

    DISABLE_RANKING_REQUEST: 'DISABLE_RANKING_REQUEST',
    DISABLE_RANKING_SUCCESS: 'DISABLE_RANKING_SUCCESS',
    DISABLE_RANKING_FAILURE: 'DISABLE_RANKING_FAILURE',

};
