import {
  gameConstants
} from '../_constants';

export function game(state = {}, action) {
  console.log('allGamesListByIdallGamesListByIdallGamesListById', state, "___", action);

  switch (action.type) {
    case gameConstants.ADD_GAME_REQUEST:
      return {
        ...state
      };
    case gameConstants.ADD_GAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case gameConstants.ADD_GAME_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case gameConstants.GET_LIST_GAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameConstants.GET_LIST_GAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getGameList.list,
        total: action.users.getGameList.total
      };
    case gameConstants.GET_LIST_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameConstants.GETALL_GAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameConstants.GETALL_GAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case gameConstants.GETALL_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameConstants.GETALL_GAME_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameConstants.GETALL_GAME_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGamesListById: action.users.getAllGameByGameTypeId,
      };
    case gameConstants.GETALL_GAME_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gameConstants.UPDATE_GAME_REQUEST:
      return {
        ...state
      };
    case gameConstants.UPDATE_GAME_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case gameConstants.UPDATE_GAME_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case gameConstants.DELETE_GAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameConstants.DELETE_GAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameConstants.DELETE_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameConstants.DISABLE_GAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameConstants.DISABLE_GAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameConstants.DISABLE_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}