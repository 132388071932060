export const marketConstants = {

    GETALL_MARKET_REQUEST: 'GETALL_MARKET_REQUEST',
    GETALL_MARKET_SUCCESS: 'GETALL_MARKET_SUCCESS',
    GETALL_MARKET_FAILURE: 'GETALL_MARKET_FAILURE',

    GET_LIST_MARKET_REQUEST: 'GET_LIST_MARKET_REQUEST',
    GET_LIST_MARKET_SUCCESS: 'GET_LIST_MARKET_SUCCESS',
    GET_LIST_MARKET_FAILURE: 'GET_LIST_MARKET_FAILURE',

    ADD_MARKET_REQUEST: 'ADD_MARKET_REQUEST',
    ADD_MARKET_SUCCESS: 'ADD_MARKET_SUCCESS',
    ADD_MARKET_FAILURE: 'ADD_MARKET_FAILURE',

    DELETE_MARKET_REQUEST: 'DELETE_MARKET_REQUEST',
    DELETE_MARKET_SUCCESS: 'DELETE_MARKET_SUCCESS',
    DELETE_MARKET_FAILURE: 'DELETE_MARKET_FAILURE',

    UPDATE_MARKET_REQUEST: 'UPDATE_MARKET_REQUEST',
    UPDATE_MARKET_SUCCESS: 'UPDATE_MARKET_SUCCESS',
    UPDATE_MARKET_FAILURE: 'UPDATE_MARKET_FAILURE',

    DISABLE_MARKET_REQUEST: 'DISABLE_MARKET_REQUEST',
    DISABLE_MARKET_SUCCESS: 'DISABLE_MARKET_SUCCESS',
    DISABLE_MARKET_FAILURE: 'DISABLE_MARKET_FAILURE',

};
