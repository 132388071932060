import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, categoryActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAddGameModal/CreateAddGameModal";
import CreateUpdatePasswordDealer from "./components/CreateUpdatePasswordGameModal/CreateUpdatePasswordGame";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import "./style.css"
import { RiDeleteBinLine } from "react-icons/ri";


class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      newsContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let data = {

      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNotificationList(data));
    // this.props.dispatch(newsActions.getAllNews());
    // this.props.dispatch(categoryActions.getAllCategory());
    // this.props.dispatch(newsActions.disableNotification(data));

    // this.props.dispatch(newsActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.news.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }




  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNotificationList(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNotificationList(data));
  }
  // handleSearch = (event) => {
  //   event.preventDefault();
  //   let { value } = event.target;
  //   this.setState({ keyWord: value, offset: 0 });
  //   let data = {
  //     "keyWord": value,
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(categoryActions.getCategoryList(data));
  // }
  disableNotification = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.author} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.disableNotification(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNotification = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNotification(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //   "id": data.id
    // }
    // this.props.dispatch(newsActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, newsRowData: data });
  }
  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(newsActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['news_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNotificationSubmit = () => {
    let { users } = this.props;


    let { filesDetails,
    } = users;
    console.log('filesDetails__________________', filesDetails);
    if (this.handleValidationAddNotification()) {
      let reqData = {
        // "newsId": "63086b654f402396ab7c73bd",
        "newsId": this.state.fieldsAddUser.newsId,
        "title": this.state.fieldsAddUser.title,
        "body": this.state.fieldsAddUser.body,
        "color": this.state.fieldsAddUser.color,
        "priority": this.state.fieldsAddUser.priority,
        "image": filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null
        // "image": this.state.fieldsAddUser && this.state.fieldsAddUser.image ? this.state.fieldsAddUser.image : null,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(newsActions.createNotification(reqData, paginationData));
    }
  }

  handleValidationAddNotification = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //news_id
    if (!fieldsAddUser["news_id"] || fieldsAddUser["news_id"] === "") {
      formIsValid = false;
      errorsAddUser["news_id"] = "Cannot be empty";
    }

    //title
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //body
    if (!fieldsAddUser["body"] || fieldsAddUser["body"] === "") {
      formIsValid = false;
      errorsAddUser["body"] = "Cannot be empty";
    }

    //color
    if (!fieldsAddUser["color"] || fieldsAddUser["color"] === "") {
      formIsValid = false;
      errorsAddUser["color"] = "Cannot be empty";
    }

    //priority
    if (!fieldsAddUser["priority"] || fieldsAddUser["priority"] === "") {
      formIsValid = false;
      errorsAddUser["priority"] = "Cannot be empty";
    }





    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "news_id": this.state.fieldsUpdatePassword.news_id,
        "title": this.state.fieldsUpdatePassword.title,
        "author": this.state.fieldsUpdatePassword.author,
        "descirption": this.state.fieldsUpdatePassword.descirption,
        "image": this.state.fieldsUpdatePassword && this.state.fieldsUpdatePassword.image ? this.state.fieldsUpdatePassword.image : null,
        // "content": this.state.fieldsUpdatePassword.content,
        // "imageUser": this.state.fieldsUpdatePassword && this.state.fieldsUpdatePassword.imageUser ? this.state.fieldsUpdatePassword.imageUser : null,
        // "categoryId": this.state.fieldsUpdatePassword.categoryId ? this.state.fieldsUpdatePassword.categoryId : null,
        // "pub_date": this.state.fieldsUpdatePassword.pub_date ? this.state.fieldsUpdatePassword.pub_date : moment(Date.now()).format('DD-MM-YYYY'),
        // "link": this.state.fieldsUpdatePassword.link,
        "slug": this.state.fieldsUpdatePassword.slug,
        "category": this.state.fieldsUpdatePassword.category,
        "subcategory": this.state.fieldsUpdatePassword.subcategory,
        // "source": this.state.fieldsUpdatePassword.source,
        // "eDNote": this.state.fieldsUpdatePassword.eDNote,
        // "byline": this.state.fieldsUpdatePassword.byline,
        // "priority": this.state.fieldsUpdatePassword.priority,
        "story": this.state.fieldsUpdatePassword.story,
      }



      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(newsActions.updateNews(reqData, paginationData));
    }

  }
  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //news_id
    if (!fieldsUpdatePassword["news_id"] || fieldsUpdatePassword["news_id"] === "") {
      formIsValid = false;
      errorsUpdatePassword["news_id"] = "Cannot be empty";
    }
    //title
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //author
    if (!fieldsUpdatePassword["author"] || fieldsUpdatePassword["author"] === "") {
      formIsValid = false;
      errorsUpdatePassword["author"] = "Cannot be empty";
    }
    //descirption
    if (!fieldsUpdatePassword["descirption"] || fieldsUpdatePassword["descirption"] === "") {
      formIsValid = false;
      errorsUpdatePassword["descirption"] = "Cannot be empty";
    }
    //category
    if (!fieldsUpdatePassword["category"] || fieldsUpdatePassword["category"] === "") {
      formIsValid = false;
      errorsUpdatePassword["category"] = "Cannot be empty";
    }
    //slug
    if (!fieldsUpdatePassword["slug"] || fieldsUpdatePassword["slug"] === "") {
      formIsValid = false;
      errorsUpdatePassword["slug"] = "Cannot be empty";
    }
    //subcategory
    if (!fieldsUpdatePassword["subcategory"] || fieldsUpdatePassword["subcategory"] === "") {
      formIsValid = false;
      errorsUpdatePassword["subcategory"] = "Cannot be empty";
    }
    //story
    if (!fieldsUpdatePassword["story"] || fieldsUpdatePassword["story"] === "") {
      formIsValid = false;
      errorsUpdatePassword["story"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }



  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }




  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
    // console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }

  // inputDiscriptionChange = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   let fieldsAddUser = this.state.fieldsAddUser;
  //   let words = value.split(' ').filter(Boolean);
  //   if (words.length > this.state.limit) {
  //     fieldsAddUser[name] = words.slice(0, this.state.limit).join(' ')
  //     this.setState({ fieldsAddUser });
  //   } else {

  //     fieldsAddUser[name] = value;
  //     this.setState({ fieldsAddUser });
  //   }

  // }


  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };







  deleteNotification = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNotification(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }











  render() {

    let { news, users, category } = this.props;
    let { items, total, loading, addUserSuccess, newsItems, notificationItems, notificationTotal } = news;
    let { allCategory, } = category;
    let { filesDetails } = users;

    console.log("RENDER_______items:", items);
    console.log("RENDER_______notificationItems:", notificationItems);
    console.log("RENDER_______notificationTotal", notificationTotal);

    // console.log("RENDER_______this.state.fieldsAddUser****:", this.state.fieldsAddUser);

    console.log("RENDER_______this.state.updateSelectedTag****:", this.state.updateSelectedTag);
    console.log("RENDER_______this.state.selectedTag****:", this.state.selectedTag);
    // console.log("RENDER_______this.state.fieldsAddUser****:", this.state.fieldsAddUser);
    console.log("RENDER__fieldsUpdatePassword****:", this.state.fieldsUpdatePassword);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    const categoryListItems = [
      { label: "NATIONAL", value: "NATIONAL" },
      { label: "BUSINESS", value: "BUSINESS" },
      { label: "FOREIGN", value: "FOREIGN" },
      { label: "SPORTS", value: "SPORTS" },
      { label: "INTERNATIONAL", value: "INTERNATIONAL" },
      { label: "INDIA", value: "INDIA" },
      { label: "MYFEED", value: "MYFEED" },
      { label: "TOPSTORIES", value: "TOPSTORIES" },
      { label: "TRENDING", value: "TRENDING" },
      { label: "CRIME", value: "CRIME" },
      { label: "EDUCATION", value: "EDUCATION" },
      { label: "PHOTOS", value: "PHOTOS" },
    ];

    const subCategoryListItems = [
      { label: "General", value: "GEN" },
      { label: "National", value: "NAT" },
      { label: "Legel", value: "LGL" },
      { label: "ESPL", value: "ESPL" },
      { label: "DSB", value: "DSB" },
      { label: "NRG", value: "NRG" },
      { label: "WRG", value: "WRG" },
      { label: "ERG", value: "ERG" },
      { label: "InterNational", value: "INT" },
      { label: "SRG", value: "SRG" },
      { label: "Business", value: "COM" },
      { label: "ECO", value: "ECO" },
      { label: "Cricket", value: "CRI" },
      { label: "Sport", value: "SPO" },
      { label: "Defence", value: "DEF" },
      { label: "Entertainment / Lifestyle", value: "ENT" },
      { label: "City Crime", value: "CTCRIME" },
      { label: "National Crime", value: "NATCRIME" },
      { label: "Regional Crime", value: "RGNCRIME" },
      { label: "International Crime", value: "INTCRIME" },
      { label: "Health", value: "HEALTH" },
      { label: "Religion", value: "RELIGION" },
      { label: "Technology", value: "TECHNOLOGY" },
      { label: "Nagpur Photos", value: "NAGPHOTO" },
      { label: "Regional Photos", value: "RGNPHOTO" },
      { label: "Pictures World", value: "WOLDPHOTO" },
    ];

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">News</h3>
                    </div>

                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute text-gray-600 top-4 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="w-full px-3 py-3 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-gray-200 border rounded-full appearance-none sm:w-64 border-dark-400 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>

                    <div className="flex justify-end ...">
                      <button className="flex justify-end py-1 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7 "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div>

                  </div>
                  <div className="pb-2 mt-4 overflow-hidden ">
                    {notificationItems ? <>
                      <div className="max-w-full overflow-x-auto ">
                        <div className="inline-block min-w-full ">
                          <div className="overflow-x-auto">
                            <table className="min-w-full border-0 divide-y divide-gray-800">
                              <thead className="bg-gray-200">
                                <tr className="">
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">#</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">date</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">News Id</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Title</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Image</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">status</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  notificationItems && notificationItems.length > 0 ?
                                    notificationItems.map((element, index) => (<React.Fragment key={element.id}>
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>



                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.newsId && element.newsId.title ? element.newsId.title : "NA"}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.newsId && element.newsId.news_id ? element.newsId.news_id : "NA"}
                                        </td>
                                        {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.newsId && element.newsId.imageLinkUrl ? element.newsId.imageLinkUrl : "NA"}</td> */}
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          <div className='flex justify-center'>
                                            <img className="object-cover h-10 rounded-sm w-14" src={element && element.newsId && element.newsId.imageLinkUrl ? element.newsId.imageLinkUrl : "NA"} alt="not found" />
                                          </div>
                                        </td>
                                        <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                            <div class="relative" onClick={() => this.disableNotification(element)}>
                                              <input type="checkbox" id="toggleB" class="sr-only" />
                                              <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                              <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>Enable/Disable</span>
                                            </div>
                                          </label>
                                        </td>
                                        <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600  whitespace-nowrap">
                                          <div className='flex space-x-2'>
                                            <span className="relative">
                                              <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteNotification(element)}>
                                                <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                                <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                              </div>
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>))
                                    : (<tr className="bg-white bg-opacity-5 " >
                                      <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                    </tr>)
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </> : null}
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            notificationTotal && notificationTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={notificationTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            notificationTotal && notificationTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={notificationTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateAddDealerModal
          options={options}
          newsItems={newsItems}
          categoryListItems={categoryListItems}
          subCategoryListItems={subCategoryListItems}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          addNotificationSubmit={this.addNotificationSubmit}
          handleFile={this.handleFile}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          // inputDiscriptionChange={this.inputDiscriptionChange}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />

        <CreateUpdatePasswordDealer
          options={options}
          categoryListItems={categoryListItems}
          subCategoryListItems={subCategoryListItems}
          allCategory={allCategory}
          offset={this.state.offset}
          updateSelectedTag={this.state.updateSelectedTag}
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChange={this.inputChange}
          handleFile={this.handleFile}
          updateNewsSubmit={this.updateNewsSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          contentFieldUpdate={this.contentFieldUpdate}
          deleteContentFieldUpdate={this.deleteContentFieldUpdate}
          setCurrentIndexUpdate={this.setCurrentIndexUpdate}
          inputChangeContentUpdate={this.inputChangeContentUpdate}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          modules={this.modules}
          formats={this.formats}
          rteChangeUpdate={this.rteChangeUpdate}
        />

        <ViewMoreDetailsModal
          options={options}
          categoryListItems={categoryListItems}
          subCategoryListItems={subCategoryListItems}
          offset={this.state.offset}
          allCategory={allCategory}
          modules={this.modules}
          formats={this.formats}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          newsRowData={this.state.newsRowData}
          updateSelectedTag={this.state.updateSelectedTag}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category } = state;
  return {
    news,
    users,
    category
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Notification);
