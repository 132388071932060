import {
  eventOptionConstants
} from '../_constants';

export function eventOption(state = {}, action) {

  switch (action.type) {
    case eventOptionConstants.ADD_EVENTOPTION_REQUEST:
      return {
        ...state
      };
    case eventOptionConstants.ADD_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case eventOptionConstants.ADD_EVENTOPTION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case eventOptionConstants.GET_LIST_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.GET_LIST_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getEventOptionList.list,
        total: action.users.getEventOptionList.total
      };
    case eventOptionConstants.GET_LIST_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case eventOptionConstants.GET_YES_LIST_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.GET_YES_LIST_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        yesOptionItem: action.users.getEventOptionYesList.list,
        yesOptionTotal: action.users.getEventOptionYesList.total
      };
    case eventOptionConstants.GET_YES_LIST_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case eventOptionConstants.GET_NO_LIST_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.GET_NO_LIST_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        noOptionItems: action.users.getEventOptionNoList.list,
        noOptionTotal: action.users.getEventOptionNoList.total
      };
    case eventOptionConstants.GET_NO_LIST_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case eventOptionConstants.GETALL_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.GETALL_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllMarket,
      };
    case eventOptionConstants.GETALL_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case eventOptionConstants.UPDATE_EVENTOPTION_REQUEST:
      return {
        ...state
      };
    case eventOptionConstants.UPDATE_EVENTOPTION_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case eventOptionConstants.UPDATE_EVENTOPTION_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case eventOptionConstants.DELETE_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.DELETE_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case eventOptionConstants.DELETE_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case eventOptionConstants.DISABLE_EVENTOPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventOptionConstants.DISABLE_EVENTOPTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case eventOptionConstants.DISABLE_EVENTOPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case eventOptionConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    case eventOptionConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };

    case eventOptionConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    default:
      return state
  }
}