import {
  offerConstants
} from '../_constants';

export function offer(state = {}, action) {
  console.log('allGamesListByIdallGamesListByIdallGamesListById', state, "___", action);

  switch (action.type) {
    case offerConstants.ADD_OFFER_REQUEST:
      return {
        ...state
      };
    case offerConstants.ADD_OFFER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case offerConstants.ADD_OFFER_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case offerConstants.GET_OFFER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.GET_OFFER_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        offerItems: action.users.getOffersList.list,
        offerTotal: action.users.getOffersList.total
      };
    case offerConstants.GET_OFFER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case offerConstants.GETALL_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.GETALL_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case offerConstants.GETALL_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case offerConstants.GETALL_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.GETALL_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllUser: action.users.getAllUserr,
      };
    case offerConstants.GETALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case offerConstants.GETALL_BET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.GETALL_BET_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGamesListById: action.users.getAllGameByGameTypeId,
      };
    case offerConstants.GETALL_BET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case offerConstants.UPDATE_BET_REQUEST:
      return {
        ...state
      };
    case offerConstants.UPDATE_BET_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case offerConstants.UPDATE_BET_FAILURE:
      return {
        ...state,
        error: action.error
      };


      case offerConstants.UPDATE_OFFER_REQUEST:
        return {
          ...state
        };
      case offerConstants.UPDATE_OFFER_SUCCESS:
        return {
          ...state,
          updateUserSuccess: true
        };
      case offerConstants.UPDATE_OFFER_FAILURE:
        return {
          ...state,
          error: action.error
        };



    case offerConstants.DELETE_OFFER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.DELETE_OFFER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case offerConstants.DELETE_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case offerConstants.DISABLE_OFFER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case offerConstants.DISABLE_OFFER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case offerConstants.DISABLE_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}