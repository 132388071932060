import React from "react";
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
// import { Menu, Transition } from '@headlessui/react'
import { FiLogOut } from "react-icons/fi";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Header(props) {
  const onClickMenu = (url) => {
    localStorage.removeItem('adminuser');
    props.history.push(url)
  }


  return (


    <>
      <div className="z-20 flex   border-b  bg-white justify-end h-16 items-center px-6 cursor-pointer"
        onClick={() => onClickMenu('login')}>
        <FiLogOut />LOGOUT
      </div>

    </>

  );
}
