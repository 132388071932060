export const scorecardConstants = {

    MATCHES_BY_CS_ID_REQUEST: 'MATCHES_BY_CS_ID_REQUEST',
    MATCHES_BY_CS_ID_SUCCESS: 'MATCHES_BY_CS_ID_SUCCESS',
    MATCHES_BY_CS_ID_FAILURE: 'MATCHES_BY_CS_ID_FAILURE',

    GETALL_SCORECARD_REQUEST: 'GETALL_SCORECARD_REQUEST',
    GETALL_SCORECARD_SUCCESS: 'GETALL_SCORECARD_SUCCESS',
    GETALL_SCORECARD_FAILURE: 'GETALL_SCORECARD_FAILURE',

    GET_LIST_SCORECARD_REQUEST: 'GET_LIST_SCORECARD_REQUEST',
    GET_LIST_SCORECARD_SUCCESS: 'GET_LIST_SCORECARD_SUCCESS',
    GET_LIST_SCORECARD_FAILURE: 'GET_LIST_SCORECARD_FAILURE',

    ADD_SCORECARD_REQUEST: 'ADD_SCORECARD_REQUEST',
    ADD_SCORECARD_SUCCESS: 'ADD_SCORECARD_SUCCESS',
    ADD_SCORECARD_FAILURE: 'ADD_SCORECARD_FAILURE',

    DELETE_SCORECARD_REQUEST: 'DELETE_SCORECARD_REQUEST',
    DELETE_SCORECARD_SUCCESS: 'DELETE_SCORECARD_SUCCESS',
    DELETE_SCORECARD_FAILURE: 'DELETE_SCORECARD_FAILURE',

    UPDATE_SCORECARD_REQUEST: 'UPDATE_SCORECARD_REQUEST',
    UPDATE_SCORECARD_SUCCESS: 'UPDATE_SCORECARD_SUCCESS',
    UPDATE_SCORECARD_FAILURE: 'UPDATE_SCORECARD_FAILURE',

    DISABLE_SCORECARD_REQUEST: 'DISABLE_SCORECARD_REQUEST',
    DISABLE_SCORECARD_SUCCESS: 'DISABLE_SCORECARD_SUCCESS',
    DISABLE_SCORECARD_FAILURE: 'DISABLE_SCORECARD_FAILURE',

};
