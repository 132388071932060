import {
  userConstants, liveScoreConstants
} from '../_constants';

export function liveScore(state = {}, action) {

  switch (action.type) {
    case liveScoreConstants.ADD_LIVE_SCORE_REQUEST:
      return {
        ...state
      };
    case liveScoreConstants.ADD_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case liveScoreConstants.ADD_LIVE_SCORE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case liveScoreConstants.GET_LIST_LIVE_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case liveScoreConstants.GET_LIST_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getLiveScoreList.list,
        total: action.users.getLiveScoreList.total
      };
    case liveScoreConstants.GET_LIST_LIVE_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case liveScoreConstants.GETALL_LIVE_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case liveScoreConstants.GETALL_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        liveScoreItems: action.users.getAllLiveScore,
        liveScoreTotal: action.users.getAllLiveScore
      };
    case liveScoreConstants.GETALL_LIVE_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_LIVE_SCORE_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_LIVE_SCORE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_LIVE_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_LIVE_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_LIVE_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_LIVE_SCORE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_LIVE_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}