export const gameResultDateConstants = {

    GETALL_GAME_RESULT_DATE_REQUEST: 'GETALL_GAME_RESULT_DATE_REQUEST',
    GETALL_GAME_RESULT_DATE_SUCCESS: 'GETALL_GAME_RESULT_DATE_SUCCESS',
    GETALL_GAME_RESULT_DATE_FAILURE: 'GETALL_GAME_RESULT_DATE_FAILURE',

    GET_LIST_GAME_RESULT_DATE_REQUEST: 'GET_LIST_GAME_RESULT_DATE_REQUEST',
    GET_LIST_GAME_RESULT_DATE_SUCCESS: 'GET_LIST_GAME_RESULT_DATE_SUCCESS',
    GET_LIST_GAME_RESULT_DATE_FAILURE: 'GET_LIST_GAME_RESULT_DATE_FAILURE',

    ADD_GAME_RESULT_DATE_REQUEST: 'ADD_GAME_RESULT_DATE_REQUEST',
    ADD_GAME_RESULT_DATE_SUCCESS: 'ADD_GAME_RESULT_DATE_SUCCESS',
    ADD_GAME_RESULT_DATE_FAILURE: 'ADD_GAME_RESULT_DATE_FAILURE',

    DELETE_GAME_RESULT_DATE_REQUEST: 'DELETE_GAME_RESULT_DATE_REQUEST',
    DELETE_GAME_RESULT_DATE_SUCCESS: 'DELETE_GAME_RESULT_DATE_SUCCESS',
    DELETE_GAME_RESULT_DATE_FAILURE: 'DELETE_GAME_RESULT_DATE_FAILURE',

    UPDATE_GAME_RESULT_DATE_REQUEST: 'UPDATE_GAME_RESULT_DATE_REQUEST',
    UPDATE_GAME_RESULT_DATE_SUCCESS: 'UPDATE_GAME_RESULT_DATE_SUCCESS',
    UPDATE_GAME_RESULT_DATE_FAILURE: 'UPDATE_GAME_RESULT_DATE_FAILURE',

    DISABLE_GAME_RESULT_DATE_REQUEST: 'DISABLE_GAME_RESULT_DATE_REQUEST',
    DISABLE_GAME_RESULT_DATE_SUCCESS: 'DISABLE_GAME_RESULT_DATE_SUCCESS',
    DISABLE_GAME_RESULT_DATE_FAILURE: 'DISABLE_GAME_RESULT_DATE_FAILURE',

};
