export const websiteConstants = {

    GET_WEBSITE_LIST_REQUEST: 'GET_WEBSITE_LIST_REQUEST',
    GET_WEBSITE_LIST_SUCCESS: 'GET_WEBSITE_LIST_SUCCESS',
    GET_WEBSITE_LIST_FAILURE: 'GET_WEBSITE_LIST_FAILURE',

    GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
    GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
    GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',


    GETALL_BET_REQUEST: 'GETALL_BET_REQUEST',
    GETALL_BET_SUCCESS: 'GETALL_BET_SUCCESS',
    GETALL_BET_FAILURE: 'GETALL_BET_FAILURE',

    GETALL_BET_BY_ID_REQUEST: 'GETALL_BET_BY_ID_REQUEST',
    GETALL_BET_BY_ID_SUCCESS: 'GETALL_BET_BY_ID_SUCCESS',
    GETALL_BET_BY_ID_FAILURE: 'GETALL_BET_BY_ID_FAILURE',

    GET_TICKET_BY_ID_REQUEST: 'GET_TICKET_BY_ID_REQUEST',
    GET_TICKET_BY_ID_SUCCESS: 'GET_TICKET_BY_ID_SUCCESS',
    GET_TICKET_BY_ID_FAILURE: 'GET_TICKET_BY_ID_FAILURE',


    GETALL_USER_REQUEST: 'GETALL_USER_REQUEST',
    GETALL_USER_SUCCESS: 'GETALL_USER_SUCCESS',
    GETALL_USER_FAILURE: 'GETALL_USER_FAILURE',

    ADD_WEBSITE_REQUEST: 'ADD_WEBSITE_REQUEST',
    ADD_WEBSITE_SUCCESS: 'ADD_WEBSITE_SUCCESS',
    ADD_WEBSITE_FAILURE: 'ADD_WEBSITE_FAILURE',

    APPROVE_TICKET_REQUEST: 'APPROVE_TICKET_REQUEST',
    APPROVE_TICKET_SUCCESS: 'APPROVE_TICKET_SUCCESS',
    APPROVE_TICKET_FAILURE: 'APPROVE_TICKET_FAILURE',

    APPROVE_ID_TICKET_REQUEST: 'APPROVE_ID_TICKET_REQUEST',
    APPROVE_ID_TICKET_SUCCESS: 'APPROVE_ID_TICKET_SUCCESS',
    APPROVE_ID_TICKET_FAILURE: 'APPROVE_ID_TICKET_FAILURE',

    REJECT_TICKET_REQUEST: 'REJECT_TICKET_REQUEST',
    REJECT_TICKET_SUCCESS: 'REJECT_TICKET_SUCCESS',
    REJECT_TICKET_FAILURE: 'REJECT_TICKET_FAILURE',

    DELETE_WEBSITE_REQUEST: 'DELETE_WEBSITE_REQUEST',
    DELETE_WEBSITE_SUCCESS: 'DELETE_WEBSITE_SUCCESS',
    DELETE_WEBSITE_FAILURE: 'DELETE_WEBSITE_FAILURE',

    UPDATE_WEBSITE_REQUEST: 'UPDATE_WEBSITE_REQUEST',
    UPDATE_WEBSITE_SUCCESS: 'UPDATE_WEBSITE_SUCCESS',
    UPDATE_WEBSITE_FAILURE: 'UPDATE_WEBSITE_FAILURE',

    DISABLE_WEBSITE_REQUEST: 'DISABLE_WEBSITE_REQUEST',
    DISABLE_WEBSITE_SUCCESS: 'DISABLE_WEBSITE_SUCCESS',
    DISABLE_WEBSITE_FAILURE: 'DISABLE_WEBSITE_FAILURE',

};
