import {
  userConstants, fancyConstants
} from '../_constants';

export function fancy(state = {}, action) {

  switch (action.type) {
    case fancyConstants.ADD_FANCY_REQUEST:
      return {
        ...state
      };
    case fancyConstants.ADD_FANCY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case fancyConstants.ADD_FANCY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case fancyConstants.GET_FANCY_By_Match_Id_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fancyConstants.GET_FANCY_By_Match_Id_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getFancyByMatchId,
        total: action.users.getFancyByMatchId
      };
    case fancyConstants.GET_FANCY_By_Match_Id_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case fancyConstants.GETALL_FANCY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fancyConstants.GETALL_FANCY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        fancyItems: action.users.getAllFancy,
        fancyTotal: action.users.getAllFancy
      };
    case fancyConstants.GETALL_FANCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_FANCY_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_FANCY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_FANCY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_FANCY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_FANCY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_FANCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_FANCY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_FANCY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_FANCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}