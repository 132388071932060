export const gameConstants = {

    GETALL_GAME_REQUEST: 'GETALL_GAME_REQUEST',
    GETALL_GAME_SUCCESS: 'GETALL_GAME_SUCCESS',
    GETALL_GAME_FAILURE: 'GETALL_GAME_FAILURE',

    GETALL_GAME_BY_ID_REQUEST: 'GETALL_GAME_BY_ID_REQUEST',
    GETALL_GAME_BY_ID_SUCCESS: 'GETALL_GAME_BY_ID_SUCCESS',
    GETALL_GAME_BY_ID_FAILURE: 'GETALL_GAME_BY_ID_FAILURE',

    GET_LIST_GAME_REQUEST: 'GET_LIST_GAME_REQUEST',
    GET_LIST_GAME_SUCCESS: 'GET_LIST_GAME_SUCCESS',
    GET_LIST_GAME_FAILURE: 'GET_LIST_GAME_FAILURE',

    ADD_GAME_REQUEST: 'ADD_GAME_REQUEST',
    ADD_GAME_SUCCESS: 'ADD_GAME_SUCCESS',
    ADD_GAME_FAILURE: 'ADD_GAME_FAILURE',

    DELETE_GAME_REQUEST: 'DELETE_GAME_REQUEST',
    DELETE_GAME_SUCCESS: 'DELETE_GAME_SUCCESS',
    DELETE_GAME_FAILURE: 'DELETE_GAME_FAILURE',

    UPDATE_GAME_REQUEST: 'UPDATE_GAME_REQUEST',
    UPDATE_GAME_SUCCESS: 'UPDATE_GAME_SUCCESS',
    UPDATE_GAME_FAILURE: 'UPDATE_GAME_FAILURE',

    DISABLE_GAME_REQUEST: 'DISABLE_GAME_REQUEST',
    DISABLE_GAME_SUCCESS: 'DISABLE_GAME_SUCCESS',
    DISABLE_GAME_FAILURE: 'DISABLE_GAME_FAILURE',

};
