export const paymentTypeConstants = {

    GETALL_PAYMENT_REQUEST: 'GETALL_PAYMENT_REQUEST',
    GETALL_PAYMENT_SUCCESS: 'GETALL_PAYMENT_SUCCESS',
    GETALL_PAYMENT_FAILURE: 'GETALL_PAYMENT_FAILURE',

    GET_LIST_PAYMENT_REQUEST: 'GET_LIST_PAYMENT_REQUEST',
    GET_LIST_PAYMENT_SUCCESS: 'GET_LIST_PAYMENT_SUCCESS',
    GET_LIST_PAYMENT_FAILURE: 'GET_LIST_PAYMENT_FAILURE',

    GET_REACHOUT_LIST_REQUEST: 'GET_REACHOUT_LIST_REQUEST',
    GET_REACHOUT_LIST_SUCCESS: 'GET_REACHOUT_LIST_SUCCESS',
    GET_REACHOUT_LIST_FAILURE: 'GET_REACHOUT_LIST_FAILURE',

    ADD_PAYMENT_REQUEST: 'ADD_PAYMENT_REQUEST',
    ADD_PAYMENT_SUCCESS: 'ADD_PAYMENT_SUCCESS',
    ADD_PAYMENT_FAILURE: 'ADD_PAYMENT_FAILURE',

    DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
    DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
    DELETE_PAYMENT_FAILURE: 'DELETE_PAYMENT_FAILURE',

    DELETE_REACHOUT_REQUEST: 'DELETE_REACHOUT_REQUEST',
    DELETE_REACHOUT_SUCCESS: 'DELETE_REACHOUT_SUCCESS',
    DELETE_REACHOUT_FAILURE: 'DELETE_REACHOUT_FAILURE',

    
    DISABLE_PAYMENT_REQUEST: 'DISABLE_PAYMENT_REQUEST',
    DISABLE_PAYMENT_SUCCESS: 'DISABLE_PAYMENT_SUCCESS',
    DISABLE_PAYMENT_FAILURE: 'DISABLE_PAYMENT_FAILURE',

    UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
    UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
    UPDATE_PAYMENT_FAILURE: 'UPDATE_PAYMENpaymentTypeT_FAILURE',


    DISABLE_PAYMENT_REQUEST: 'DISABLE_PAYMENT_REQUEST',
    DISABLE_PAYMENT_SUCCESS: 'DISABLE_PAYMENT_SUCCESS',
    DISABLE_PAYMENT_FAILURE: 'DISABLE_PAYMENT_FAILURE',

};
