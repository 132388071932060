
import { authHeader } from '../_helpers';
import { CONST } from '../_config';


export const newsService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getAllNews,
    createGameType,
    updateGameType,
    getGameTypeList,
    getNewsById,
    deleteGameType,
    disableNews,
    createNotification,
    getNotificationList,
    deleteNotification,
    disableNotification,
    getAllGameType,
    getGameTypeById
};
function logout() {
    // localStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getNewsById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTripById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNewsById: data.data
            }
            console.log("i am in service getNewsById", userObj);

            return userObj;
        });
}
function getNotificationList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNotificationList: data.data
            }
            console.log("i am in service getNotificationList", userObj);

            return userObj;
        });
}
function getGameTypeList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getGameTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getGameTypeList: data.data
            }
            console.log("i am in service getGameTypeList", userObj);

            return userObj;
        });
}
function getAllNews(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllNews`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllNews: data.data
            }
            console.log("i am in service getAllNews", userObj);

            return userObj;
        });
}
function getAllGameType() {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllGameType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllGameTypes: data.data
            }
            console.log("i am in service getAllGameType", userObj);

            return userObj;
        });
}

function getGameTypeById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getGameTypeById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getGameTypeById: data.data
            }
            console.log("i am in service getGameTypeById", userObj);

            return userObj;
        });
}

// function verifyEmail(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 verifyEmail: data.data
//             }
//             console.log("i am in service''...>> verifyEmail ::", userObj);

//             return userObj;
//         });
// }

function deleteGameType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteGameType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteGameType: data.data
            }
            console.log("i am in service''...>> deleteGameType ::", userObj);

            return userObj;
        });
}

function deleteNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteNotification: data.data
            }
            console.log("i am in service''...>> deleteNotification ::", userObj);

            return userObj;
        });
}

function disableNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateGameTypeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateGameTypeStatus: data.data
            }
            console.log("i am in service''...>> updateGameTypeStatus ::", userObj);

            return userObj;
        });
}

function updateGameType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateGameType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateGameType: data.data
            }
            console.log("I am in service updateGameType", userObj);

            return userObj;
        });
}
function createGameType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createGameType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createGameType: data.data
            }
            console.log("I am in service createGameType", userObj);

            return userObj;
        });
}
function createNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNotification: data.data
            }
            console.log("I am in service createNotification", userObj);

            return userObj;
        });
}




function disableNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptionsrequestOptions__________', requestOptions);
    return fetch(CONST.BACKEND_URL + `/updateNotificationStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log('datadatadata_____________', data);
            let userObj = {
                updateNotificationStatus: data.data
            }
            console.log('data.datadata.datadata.data________________', data.data);
            console.log("i am in service''...>> updateNotificationStatus ::", userObj);

            return userObj;
        });
}





function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}