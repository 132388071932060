import {
  betConstants
} from '../_constants';

export function bet(state = {}, action) {
  console.log('allGamesListByIdallGamesListByIdallGamesListById', state, "___", action);

  switch (action.type) {
    case betConstants.ADD_BET_REQUEST:
      return {
        ...state
      };
    case betConstants.ADD_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case betConstants.ADD_BET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case betConstants.GET_LIST_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.GET_LIST_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getBidList.list,
        total: action.users.getBidList.total
      };
    case betConstants.GET_LIST_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case betConstants.GETALL_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.GETALL_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case betConstants.GETALL_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case betConstants.GETALL_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.GETALL_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllUser: action.users.getAllUserr,
      };
    case betConstants.GETALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case betConstants.GETALL_BET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.GETALL_BET_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGamesListById: action.users.getAllGameByGameTypeId,
      };
    case betConstants.GETALL_BET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case betConstants.UPDATE_BET_REQUEST:
      return {
        ...state
      };
    case betConstants.UPDATE_BET_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case betConstants.UPDATE_BET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case betConstants.DELETE_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.DELETE_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case betConstants.DELETE_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case betConstants.DISABLE_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case betConstants.DISABLE_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case betConstants.DISABLE_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}