export const eventConstants = {

    GET_EVENT_BY_ID_REQUEST: 'GET_EVENT_BY_ID_REQUEST',
    GET_EVENT_BY_ID_SUCCESS: 'GET_EVENT_BY_ID_SUCCESS',
    GET_EVENT_BY_ID_FAILURE: 'GET_EVENT_BY_ID_FAILURE',

    GET_LIST_EVENT_REQUEST: 'GET_LIST_EVENT_REQUEST',
    GET_LIST_EVENT_SUCCESS: 'GET_LIST_EVENT_SUCCESS',
    GET_LIST_EVENT_FAILURE: 'GET_LIST_EVENT_FAILURE',

    ADD_EVENT_REQUEST: 'ADD_EVENT_REQUEST',
    ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
    ADD_EVENT_FAILURE: 'ADD_EVENT_FAILURE',

    DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
    DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',


    UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
    UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAILURE: 'UPDATE_EVENT_FAILURE',


    DISABLE_EVENT_REQUEST: 'DISABLE_EVENT_REQUEST',
    DISABLE_EVENT_SUCCESS: 'DISABLE_EVENT_SUCCESS',
    DISABLE_EVENT_FAILURE: 'DISABLE_EVENT_FAILURE',

    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',
};
