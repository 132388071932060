import React, { Component } from 'react';

class WordLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {

      fieldsVideo: {},
      limit: 5,
    }
  }



  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsVideo = this.state.fieldsVideo;
    let words = value.split(' ').filter(Boolean);
    if (words.length > this.state.limit) {
      fieldsVideo[name] = words.slice(0, this.state.limit).join(' ')
      this.setState({ fieldsVideo });
    } else {
      // setContent({ content: text, wordCount: words.length });

      fieldsVideo[name] = value;
      this.setState({ fieldsVideo });
    }

  }

  render() {
    console.log("!!!!!!!!!!!!!!:", this.state.fieldsVideo)

    return (
      <div>
        <h1>Hello</h1>
        <textarea name="content"
          onChange={this.inputAddUserChange}
        >


        </textarea>
      </div>
    )
  }
}

export default WordLimit