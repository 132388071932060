import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
// import { branch } from './branch.reducer';
import { dashboard } from './dashboard.reducer';
import { ranking } from './ranking.reducer';
import { news } from './news.reducer';
import { video } from './video.reducer';
import { liveScore } from './liveScore.reducer';
import { matchesInfo } from './matchesInfo.reducer';
import { info } from './info.reducer';
import { scorecard } from './scorecard.reducer';
import { playing11 } from './playing11.reducer';
import { live } from './live.reducer';
import { fancy } from './fancy.reducer';
import { category } from './category.reducer';
import { quote } from './quote.reducer';
import { game } from './game.reducer';
import { gameResult } from './gameResult.reducer';
import { gameResultDate } from './gameResultDate.reducer';
import { market } from './market.reducer';
import { gameMarketAdmin } from './gameMarketAdmin.reducer';
import { slider } from './slider.reducer';
import { bet } from './bet.reducer';
import { trip } from './trip.reducer';
import { date } from './date.reducer';
import { blog } from './blog.reducer';
import { offer } from './offer.reducer';
import { website } from './website.reducer';
import { upi } from './upi.reducer';
import { bank } from './bank.reducer';
import { paymentType } from './paymentType.reducer';
import { event } from './event.reducer';
import { eventOption } from './eventOption.reducer';


const rootReducer = combineReducers({
  event,
  authentication,
  users,
  alert,
  dashboard,
  ranking,
  news,
  video,
  liveScore,
  matchesInfo,
  info,
  scorecard,
  playing11,
  fancy,
  live,
  category,
  quote,
  game,
  gameResult,
  gameResultDate,
  market,
  gameMarketAdmin,
  bet,
  trip,
  date,
  blog,
  offer,
  slider,
  website,
  upi,
  bank,
  paymentType,
  eventOption,
});

export default rootReducer;
