import { userConstants, fancyConstants } from '../_constants';
import { userService, fancyService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const fancyActions = {

    login,
    logout,
    disableFancy,
    getAllFancy,
    createFancy,
    updateFancy,
    getFancyByMatchId,
    deleteFancy,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createFancy(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        fancyService.createFancy(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Fancy Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getFancyList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.ADD_FANCY_REQUEST } }
    function success(users) { return { type: fancyConstants.ADD_FANCY_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.ADD_FANCY_FAILURE, error } }
}
function updateFancy(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        fancyService.updateFancy(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Fancy Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getFancyList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.UPDATE_FANCY_REQUEST } }
    function success(users) { return { type: fancyConstants.UPDATE_FANCY_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.UPDATE_FANCY_FAILURE, error } }
}
function getAllFancy(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        fancyService.getAllFancy(data)
            .then(
                users => {
                    console.log("getAllFancy $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.GETALL_FANCY_REQUEST } }
    function success(users) { return { type: fancyConstants.GETALL_FANCY_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.GETALL_FANCY_FAILURE, error } }
}
function getFancyByMatchId(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        fancyService.getFancyByMatchId(data)
            .then(
                users => {
                    console.log("getFancyByMatchId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.GET_FANCY_By_Match_Id_REQUEST } }
    function success(users) { return { type: fancyConstants.GET_FANCY_By_Match_Id_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.GET_FANCY_By_Match_Id_FAILURE, error } }
}

function deleteFancy(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        fancyService.deleteFancy(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getFancyList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.DELETE_FANCY_REQUEST } }
    function success(users) { return { type: fancyConstants.DELETE_FANCY_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.DELETE_FANCY_FAILURE, error } }
}
function disableFancy(data, paginationData) {

    return dispatch => {
        dispatch(request());
        fancyService.disableFancy(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getFancyList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: fancyConstants.DISABLE_FANCY_REQUEST } }
    function success(users) { return { type: fancyConstants.DISABLE_FANCY_SUCCESS, users } }
    function failure(error) { return { type: fancyConstants.DISABLE_FANCY_FAILURE, error } }
}
