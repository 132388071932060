import { userConstants, newsConstants } from '../_constants';
import { userService, newsService } from '../_services';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions } from './';
// import { toast } from 'react-toastify';
export const newsActions = {

    login,
    logout,
    disableNews,
    getAllNews,
    createGameType,
    updateGameType,
    getGameTypeList,
    getNewsById,
    // getAllCategory,
    deleteGameType,
    uploadImageClear,
    createNotification,
    getNotificationList,
    deleteNotification,
    disableNotification,
    getAllGameType,
    getGameTypeById
};

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


function createGameType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.createGameType(data)
            .then(

                users => {
                    dispatch(alertActions.success("GameType Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getGameTypeList(paginationData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_GAME_TYPE_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_GAME_TYPE_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_GAME_TYPE_FAILURE, error } }
}
function createNotification(data, paginationData) {
    return dispatch => {
        dispatch(request());
        newsService.createNotification(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Notification Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getNotificationList(paginationData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_NOTIFICATION_FAILURE, error } }
}
function getNotificationList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getNotificationList(data)
            .then(
                users => {
                    console.log("getNotificationList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NOTIFICATION_FAILURE, error } }
}
function updateGameType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.updateGameType(data)
            .then(

                users => {
                    console.log('paginationData____________', paginationData);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("GameType Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getGameTypeList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.UPDATE_GAME_TYPE_REQUEST } }
    function success(users) { return { type: newsConstants.UPDATE_GAME_TYPE_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.UPDATE_GAME_TYPE_FAILURE, error } }
}
function getAllNews(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getAllNews(data)
            .then(
                users => {
                    console.log("getAllNews $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GETALL_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.GETALL_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GETALL_NEWS_FAILURE, error } }
}
function getAllGameType(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getAllGameType()
            .then(
                users => {
                    console.log("getAllGameType $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_ALL_GAME_TYPE_REQUEST } }
    function success(users) { return { type: newsConstants.GET_ALL_GAME_TYPE_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_ALL_GAME_TYPE_FAILURE, error } }
}
function getGameTypeById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getGameTypeById(data)
            .then(
                users => {
                    console.log("getGameTypeById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_GAME_TYPE_BY_ID_REQUEST } }
    function success(users) { return { type: newsConstants.GET_GAME_TYPE_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_GAME_TYPE_BY_ID_FAILURE, error } }
}
function getNewsById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getNewsById(data)
            .then(
                users => {
                    console.log("getNewsById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NEWS_BY_ID_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NEWS_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NEWS_BY_ID_FAILURE, error } }
}
function getGameTypeList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getGameTypeList(data)
            .then(
                users => {
                    console.log("getGameTypeList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_GAME_TYPE_LIST_REQUEST } }
    function success(users) { return { type: newsConstants.GET_GAME_TYPE_LIST_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_GAME_TYPE_LIST_FAILURE, error } }
}

function deleteGameType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.deleteGameType(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getGameTypeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DELETE_GAME_TYPE_REQUEST } }
    function success(users) { return { type: newsConstants.DELETE_GAME_TYPE_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DELETE_GAME_TYPE_FAILURE, error } }
}
function disableNews(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.disableNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getGameTypeList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DISABLE_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.DISABLE_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DISABLE_NEWS_FAILURE, error } }
}

function deleteNotification(datatemp, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        newsService.deleteNotification(datatemp)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getGameTypeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DELETE_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.DELETE_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DELETE_NOTIFICATION_FAILURE, error } }
}


function disableNotification(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.disableNotification(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getNotificationList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DISABLE_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.DISABLE_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DISABLE_NOTIFICATION_FAILURE, error } }
}