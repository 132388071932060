export const dateConstants = {

    GETALL_DATE_REQUEST: 'GETALL_DATE_REQUEST',
    GETALL_DATE_SUCCESS: 'GETALL_DATE_SUCCESS',
    GETALL_DATE_FAILURE: 'GETALL_DATE_FAILURE',

    GETALL_DATE_BY_ID_REQUEST: 'GETALL_DATE_BY_ID_REQUEST',
    GETALL_DATE_BY_ID_SUCCESS: 'GETALL_DATE_BY_ID_SUCCESS',
    GETALL_DATE_BY_ID_FAILURE: 'GETALL_DATE_BY_ID_FAILURE',

    GET_LIST_DATE_REQUEST: 'GET_LIST_DATE_REQUEST',
    GET_LIST_DATE_SUCCESS: 'GET_LIST_DATE_SUCCESS',
    GET_LIST_DATE_FAILURE: 'GET_LIST_DATE_FAILURE',

    ADD_DATE_REQUEST: 'ADD_DATE_REQUEST',
    ADD_DATE_SUCCESS: 'ADD_DATE_SUCCESS',
    ADD_DATE_FAILURE: 'ADD_DATE_FAILURE',

    DELETE_DATE_REQUEST: 'DELETE_DATE_REQUEST',
    DELETE_DATE_SUCCESS: 'DELETE_DATE_SUCCESS',
    DELETE_DATE_FAILURE: 'DELETE_DATE_FAILURE',

    UPDATE_DATE_REQUEST: 'UPDATE_DATE_REQUEST',
    UPDATE_DATE_SUCCESS: 'UPDATE_DATE_SUCCESS',
    UPDATE_DATE_FAILURE: 'UPDATE_DATE_FAILURE',

    DISABLE_DATE_REQUEST: 'DISABLE_DATE_REQUEST',
    DISABLE_DATE_SUCCESS: 'DISABLE_DATE_SUCCESS',
    DISABLE_DATE_FAILURE: 'DISABLE_DATE_FAILURE',

};
