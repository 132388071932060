import {
  eventConstants
} from '../_constants';

export function event(state = {}, action) {

  switch (action.type) {
    case eventConstants.ADD_EVENT_REQUEST:
      return {
        ...state
      };
    case eventConstants.ADD_EVENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case eventConstants.ADD_EVENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case eventConstants.GET_LIST_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.GET_LIST_EVENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getEventList.list,
        total: action.users.getEventList.total
      };
    case eventConstants.GET_LIST_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case eventConstants.GET_EVENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.GET_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        eventData: action.users.getEventById,
      };
    case eventConstants.GET_EVENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case eventConstants.UPDATE_EVENT_REQUEST:
      return {
        ...state
      };
    case eventConstants.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case eventConstants.UPDATE_EVENT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case eventConstants.DELETE_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case eventConstants.DELETE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case eventConstants.DISABLE_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.DISABLE_EVENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case eventConstants.DISABLE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case eventConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    case eventConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };

    case eventConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    default:
      return state
  }
}