import React from "react";

import Modal from 'react-modal';



export default function ViewVideoModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, videoRowData, allCategoryParent, offset } = props;

  return (


    <Modal isOpen={moreDetailsCreateModal} >

      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*Title*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">View Details</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleMoreDetailsHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            {/*Body*/}
            <div className="flex justify-between w-full">
              <div class="mr-2 w-full">
                <label className="text-black text-sm font-medium mt-2 mr-2">Category :</label>
                <select class="form-select border border-blue-200 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                  value={videoRowData && videoRowData.categoryId ? videoRowData.categoryId : null} >
                  <option selected>Plz Select Category</option>
                  {
                    allCategoryParent && allCategoryParent && allCategoryParent.length > 0 ?
                      allCategoryParent.map((element, index) => (

                        <option value={element.id}>{element && element.name ? element.name : "NA"}</option>

                      ))
                      : null
                  }
                </select>
              </div>

              <div className="w-full mr-2">
                <div className="mt-1 rounded-md shadow-sm relative">
                  <label class="block text-gray-700 text-sm" for="username">Video Id</label>
                  <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                    value={videoRowData.video_id} type="text"
                    disabled
                  />

                </div>
              </div>
              <div className="w-full mr-2">
                <div className="mt-1 rounded-md shadow-sm relative">
                  <label class="block text-gray-700 text-sm" for="username">Title</label>
                  <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                    value={videoRowData.title} type="text" />
                </div>
              </div>
            </div>

            <div className="flex justify-between w-full mt-2">
              <div className="w-full mr-2">
                <div className="mt-1  shadow-sm relative">
                  <label class="block text-gray-700 text-sm" for="username">Description</label>
                  <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                    value={videoRowData.description} type="text" />
                </div>
              </div>
              <div className="w-full mr-2">
                <div className="mt-1 rounded-md shadow-sm relative">
                  <label class="block text-gray-700 text-sm" for="username">Video</label>
                  <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                    value={videoRowData.video} type="text" />
                </div>
              </div>
              <div className="w-full mr-2">
                <div className="mt-1 rounded-md shadow-sm relative">
                  <label class="block text-gray-700 text-sm" for="username">Author</label>
                  <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                    value={videoRowData.author} type="text" />
                </div>
              </div>
            </div>


            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 mt-2">
              {
                videoRowData && videoRowData.tag && videoRowData.tag.length > 0 ?
                  videoRowData.tag.map((item, index) => (
                    <React.Fragment key={index}>
                      <>
                        <div>
                          {/* {
                            index === 0 ?
                              <button className="border bg-shine-400 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => tagFieldUpdate(index)}>Add Tag</button>
                              :
                              <button className="border bg-red-500 text-white px-2 py-1 text-sm font-semibold rounded-lg cursor-pointer" type="button" onClick={() => deletetagFieldUpdate(index)}>Delete Tag</button>
                          } */}
                          <label class="block text-gray-700 text-sm" for="username">Tag : {offset + index + 1}</label>
                          <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150border border-blue-200 placeholder-gray-500"
                            id="tag" name="tag" placeholder="tag" value={item}
                            // onClick={() => setCurrentIndex(index)}
                            type="text"
                          // onChange={inputChangetagUpdate} 
                          />
                        </div>
                      </>
                    </React.Fragment>
                  )) : null
              }
            </div>

          </div>
        </div>
      </div>


    </Modal>


  );
}
