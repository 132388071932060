import React from "react";

import Modal from 'react-modal';
// import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



export default function CreateUpdatePasswordDealer(props) {

  let { UpdatePasswordCreateModal, inputChange, handleAppSeetingHideModal, errorsUpdatePassword, updateWebsiteSubmit, fieldsUpdatePassword, handleFile, handleUpload
  } = props;

  console.log("MODAL______fieldsUpdatePassword::", fieldsUpdatePassword);
  // console.log("MODAL______fieldsUpdatePassword.category::", fieldsUpdatePassword.category);

  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Update Website</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>









              <form autoComplete="off">


                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Demo Id</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["demoId"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="demoId" name="demoId" placeholder="Id..." value={fieldsUpdatePassword.demoId} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["demoId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["demoId"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Demo Password</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["demoPass"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="demoPass" name="demoPass" placeholder="Password..." value={fieldsUpdatePassword.demoPass} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["demoPass"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["demoPass"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Website Name</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["websiteName"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="websiteName" name="websiteName" placeholder="Name..." value={fieldsUpdatePassword.websiteName} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["websiteName"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["websiteName"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Website URL</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["websiteUrl"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="websiteUrl" name="websiteUrl" placeholder="URL..." value={fieldsUpdatePassword.websiteUrl} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["websiteUrl"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["websiteUrl"]}
                      </div>
                      : null}
                  </div>
                </div>

                {/* <div className="flex flex-wrap w-full ">
                  <label htmlFor="websiteLogo" className="bg-shine-400 flex justify-center py-2 uppercase px-2 border rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow " >
                    Upload Image
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="websiteLogo"
                    name="websiteLogo"
                    type="file"
                    onChange={handleFile}
                  />
                </div> */}


                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-2">
                  <div className="w-full mr-2">
                    {
                      fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ?
                        <>

                          <div className="w-full mr-2 mt-1 ">
                            <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ? fieldsUpdatePassword.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mr-2 mt-1 ">
                          <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageLinkUrl ? fieldsUpdatePassword.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                    <div className="mt-1 relative shadow-md">
                      <div className="w-full flex justify-between flex-wrap">
                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username"> Website Logo </label>
                          <input type="file" name="image" onChange={handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 space-x-4 mt-1">
                          <button className="border bg-blue-500 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>






                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateWebsiteSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
