import React, { Component } from 'react';
import {
  dashboardActions, websiteActions
  //  transactionActions
} from '../../_actions';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { CgMoreVerticalO } from "react-icons/cg";
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
// import ReactPaginate from 'react-paginate';
// import {
//   isMobile
// } from "react-device-detect";


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(dashboardActions.getDashboardData(temp));
    let data = {

      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }
    this.props.dispatch(websiteActions.getTicketList(data));
    // this.props.dispatch(transactionActions.getUserTxList(temp));
    window.scrollTo(0, 0)
  }
  navigate = (url) => {
    console.log("urlurlurlurlurlurl_______SIdebar::", url);
    this.props.history.push(url);
  }
  // handlePageClick = (data) => {
  //   console.log("data  ", data);
  //   let offset = Math.ceil(data.selected * this.state.size);
  //   this.setState({ offset: offset, page: data.selected });
  //   let datatemp = {
  //     "keyWord": this.state.keyWord,
  //     "pageNo": data.selected + 1,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(transactionActions.getUserTxList(datatemp));
  // }

  // handleSearch = (event) => {
  //   event.preventDefault();
  //   let { value } = event.target;
  //   console.log("value kya aa rhi h::::", value);
  //   this.setState({ keyWord: value, offset: 0 });
  //   let data = {
  //     "keyWord": value,
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(transactionActions.getUserTxList(data));
  // }

  render() {

    let { dashboard, website } = this.props;
    let { dashboarditems } = dashboard;
    let { ticketItems, loading, ticketTotal } = website;
    // console.log("i am in render funtion items m data kya aa rha h::::", items);
    console.log("i am in render funtion dashboarditems m data kya aa rha h::::", ticketItems);
    console.log("RENDER1111111111111111", dashboarditems);

    return (
      <>
        {/* <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>  */}

        <div className="overflow-hidden z-0 focus:outline-none overflow-y-auto">
          <div className="relative">
            <div className="min-h-screen flex relative z-20">
              <div className=" flex overflow-hidden w-full">

                <div className="flex flex-col flex-1 overflow-hidden">
                  <main className="flex-1 relative ">
                    <div className="2xl:p-10 sm:p-5 p-3">
                      <div className="max-w-screen-3xl mx-auto">
                        {/* Card Section */}
                        <section className="">
                          <div className="grid grid-cols-1  gap-4 px-4 mt-8 sm:grid-cols-4 sm:px-8">
                            <div className="flex items-center  bg-white border rounded-sm overflow-hidden shadow-lg">
                              <div className="p-4 bg-green-400">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-12 w-12 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="px-4 text-gray-700">
                                <h3 className="text-sm tracking-wider">Total User</h3>
                                <p className="text-3xl">   {dashboarditems && dashboarditems.totalUser ? dashboarditems.totalUser : null}
                                </p>

                              </div>
                            </div>
                            <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow-lg">
                              <div className="p-4 bg-blue-400">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-12 w-12 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                  ></path>
                                </svg>
                              </div>
                              <div className="px-4 text-gray-700">
                                <h3 className="text-sm tracking-wider">Total Wallet Withdrawl</h3>
                                <p className="text-3xl"> {dashboarditems && dashboarditems.totalWalletWithdrawl ? dashboarditems.totalWalletWithdrawl : null}</p>
                              </div>
                            </div>
                            <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow-lg">
                              <div className="p-4 bg-indigo-400">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-12 w-12 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="px-4 text-gray-700">
                                <h3 className="text-sm tracking-wider">Today's Request</h3>
                                <p className="text-3xl">  {dashboarditems && dashboarditems.todaysRequests ? dashboarditems.todaysRequests : null}</p>
                              </div>
                            </div>
                            <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow-lg">
                              <div className="p-4 bg-red-400">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-12 w-12 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                                  ></path>
                                </svg>
                              </div>
                              <div className="px-4 text-gray-700">
                                <h3 className="text-sm tracking-wider">Total Wallet Deposit</h3>
                                <p className="text-3xl"> {dashboarditems && dashboarditems.totalWalletDeposit ? dashboarditems.totalWalletDeposit : null}</p>
                              </div>
                            </div>
                          </div>

                        </section>

                        {/* Transaction Section */}
                        <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md mt-8">
                          <div className="w-full flex flex-wrap  ">
                            <div className="ml-0 mr-auto">
                              <h3 className="pb-4 md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold uppercase text-dark-400 tracking-widest">Last Wallet Requests </h3>
                            </div>
                            {/* <div className="relative mt-5 lg:mt-0">
                              <div className="absolute top-4 left-5 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              </div>
                              <input className="appearance-none sm:w-64 w-full px-4 py-3 rounded-full border border-dark-400 bg-gray-200 placeholder-gray-600 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                            </div> */}
                          </div>
                          <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-lg-md">
                            <div className="overflow-x-auto max-w-full ">
                              <div className="inline-block min-w-full  ">
                                <div className="overflow-x-auto">
                                  <table className="min-w-full border-0 divide-y ">
                                    <thead className="bg-gray-200">
                                      <tr className="">
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Request From </th>
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Username</th>
                                        {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Mode</th> */}
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Transaction Type</th>
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Amount</th>
                                        {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Image</th> */}
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Updated At</th>
                                        <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Reply</th>
                                        {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">More</th> */}
                                        {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Status</th> */}
                                        {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th> */}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {
                                        ticketItems && ticketItems.length > 0 ?
                                          ticketItems.map((element, index) => (<React.Fragment key={element.id}>
                                            <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                              <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                                {this.state.offset + index + 1}</td>


                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId && element.userId.mobile ? element.userId.mobile : "-"} </td>
                                              {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.websiteId && element.websiteId.websiteUrl ? element.websiteId.websiteUrl : "-"} </td> */}
                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId ? element.userId.userName : "-"} </td>
                                              {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId && element.mode ? element.mode : "-"} </td> */}
                                              {/* {element && element.upiId && element.upiId !== null ?
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element.upiId.upi}</td>
                                        :
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankId ? element.bankId.acHolder : "-"}</td>
                                      } */}

                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>
                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>
                                              {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className=''>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "-"} alt="not found" />
                                        </div>
                                      </td> */}
                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.updatedAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.reply ? element.reply : "-"}</td>
                                              {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"><span className="relative">
                                        <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                          <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>

                                          <CgMoreVerticalO style={{ fontSize: "1.5rem" }} />
                                        </div>
                                      </span>
                                      </td> */}
                                              {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {
                                        element && element.status !== 0 ?
                                          <>
                                            {element.status === 1 ?
                                              <div className="pt-1 text-left">
                                                <span class="bg-green-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Approved</span>

                                              </div>
                                              :
                                              <div className="pt-1 text-left">
                                                <span class="bg-green-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Rejected</span>

                                              </div>
                                            }

                                          </>
                                          :
                                          <div className="pt-1 text-left">
                                            <span class="bg-yellow-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Pending</span>

                                          </div>
                                      }</td> */}




                                              {/* <td className="flex justify-center px-6 py-3 space-x-2 text-center text-gray-600  whitespace-nowrap">
                                        <div className='flex space-x-2'>


                                          {element && element.status === 0 ? <>
                                            <span className="relative">
                                              <div className="pt-1 text-left">
                                                <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded"
                                                  onClick={() => this.approveTicket(element)} >Approve</button>

                                              </div>
                                            </span>
                                            <span className="relative">
                                              <div className="pt-1 text-left">
                                                <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded"
                                                  onClick={() => this.rejectTicket(element)}>Reject</button>

                                              </div>
                                            </span>
                                            </>
                                            : <>
                                              {element.status === 1 ?
                                                <div className="pt-1 text-left">
                                                  <span class="text-green-600 font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Approved</span>

                                                </div>
                                                :
                                                <div className="pt-1 text-left">
                                                  <span class="text-red-600 font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Rejected</span>

                                                </div>
                                              }

                                            </>}


                                        </div>
                                      </td> */}
                                            </tr>
                                          </React.Fragment>))
                                          : (<tr className="bg-white bg-opacity-5 " >
                                            <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                          </tr>)
                                      }
                                    </tbody>
                                  </table>


                                </div>
                         
                         <Link>
                         <div className='flex justify-end mt-2' onClick={() => this.navigate('/app/walletticketmanagement')}>
                            <button class="bg-blue-500 flex hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        See More
                            {/* <img src="dist/img/see-more.svg" alt="" /> */}
                          </button>
                          </div></Link> 
                              </div>
                            </div>
                            <div className="pt-1 flex items-center justify-between">
                              <div className="mt-5 lg:mt-2 flex flex-end sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="mb-2 lg:mb-0">
                                </div>
                                {/* <div>

                                  {isMobile ?
                                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                                      {
                                        total && total > 10 ?
                                          <ReactPaginate
                                            previousLabel={'Prev'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={total / this.state.size}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-cls'}
                                            activeClassName={'active'}
                                          />
                                          : null}
                                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                                      {
                                        total && total > 10 ?
                                          <ReactPaginate
                                            previousLabel={'Previous'}
                                            nextLabel={'Next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={total / this.state.size}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-cls'}
                                            activeClassName={'active'}
                                          />
                                          : null}
                                    </nav>}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </section>

                    

                      
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard, website } = state;
  // console.log("transactiontransactiontransaction???????????", transaction);
  return {
    dashboard,
    website
  };
}
export default connect(mapStateToProps)(Dashboard);
