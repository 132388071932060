import {
  userConstants, scorecardConstants
} from '../_constants';

export function scorecard(state = {}, action) {

  switch (action.type) {

    case scorecardConstants.MATCHES_BY_CS_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scorecardConstants.MATCHES_BY_CS_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.matchesByCsId.list,
        total: action.users.matchesByCsId.total
      };
    case scorecardConstants.MATCHES_BY_CS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case scorecardConstants.ADD_SCORECARD_REQUEST:
      return {
        ...state
      };
    case scorecardConstants.ADD_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case scorecardConstants.ADD_SCORECARD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case scorecardConstants.GET_LIST_SCORECARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scorecardConstants.GET_LIST_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getScoreCardList.list,
        total: action.users.getScoreCardList.total
      };
    case scorecardConstants.GET_LIST_SCORECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case scorecardConstants.GETALL_SCORECARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case scorecardConstants.GETALL_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        scorecardItems: action.users.getAllScoreCard,
        scorecardTotal: action.users.getAllScoreCard
      };
    case scorecardConstants.GETALL_SCORECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_SCORECARD_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_SCORECARD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_SCORECARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_SCORECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_SCORECARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_SCORECARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_SCORECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}