export const liveConstants = {

    LIVE_MATCH_LIST_BY_MATCH_ID_REQUEST: 'LIVE_MATCH_LIST_BY_MATCH_ID_REQUEST',
    LIVE_MATCH_LIST_BY_MATCH_ID_SUCCESS: 'LIVE_MATCH_LIST_BY_MATCH_ID_SUCCESS',
    LIVE_MATCH_LIST_BY_MATCH_ID_FAILURE: 'LIVE_MATCH_LIST_BY_MATCH_ID_FAILURE',

    GETALL_LIVE_REQUEST: 'GETALL_LIVE_REQUEST',
    GETALL_LIVE_SUCCESS: 'GETALL_LIVE_SUCCESS',
    GETALL_LIVE_FAILURE: 'GETALL_LIVE_FAILURE',

    GET_LIST_LIVE_REQUEST: 'GET_LIST_LIVE_REQUEST',
    GET_LIST_LIVE_SUCCESS: 'GET_LIST_LIVE_SUCCESS',
    GET_LIST_LIVE_FAILURE: 'GET_LIST_LIVE_FAILURE',

    ADD_LIVE_REQUEST: 'ADD_LIVE_REQUEST',
    ADD_LIVE_SUCCESS: 'ADD_LIVE_SUCCESS',
    ADD_LIVE_FAILURE: 'ADD_LIVE_FAILURE',

    DELETE_LIVE_REQUEST: 'DELETE_LIVE_REQUEST',
    DELETE_LIVE_SUCCESS: 'DELETE_LIVE_SUCCESS',
    DELETE_LIVE_FAILURE: 'DELETE_LIVE_FAILURE',

    UPDATE_LIVE_REQUEST: 'UPDATE_LIVE_REQUEST',
    UPDATE_LIVE_SUCCESS: 'UPDATE_LIVE_SUCCESS',
    UPDATE_LIVE_FAILURE: 'UPDATE_LIVE_FAILURE',

    DISABLE_LIVE_REQUEST: 'DISABLE_LIVE_REQUEST',
    DISABLE_LIVE_SUCCESS: 'DISABLE_LIVE_SUCCESS',
    DISABLE_LIVE_FAILURE: 'DISABLE_LIVE_FAILURE',

};
