import React from "react";

import Modal from 'react-modal';
// import {
//   // Dialog,
//   // AppBar,
//   // Toolbar,
//   // IconButton,
//   // Container,
//   // CssBaseline,
//   // Grid,
//   Typography,
//   Box
// } from "@material-ui/core";
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, fancyRowData
  } = props;
  // console.log("fancyRowData__MODAL", fancyRowData);

  return (

    <Modal
      isOpen={moreDetailsCreateModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">View Ranking</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleMoreDetailsHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <input className="px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-red-500 placeholder-gray-500"
                      value={fancyRowData.name} disabled />
                  </div>
                </div>

                <div className="mt-6">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    {/* <input className="px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-red-500 placeholder-gray-500"
                      value={fancyRowData.image} /> */}

                    <img src={fancyRowData.imageLinkUrl} alt="Girl in a jacket" width="100" height="100" />


                  </div>
                </div>


              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
