import React, { useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Modal from 'react-modal';
// import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



export default function CreateUpdatePasswordDealer(props) {

  let { UpdatePasswordCreateModal, inputChange, handleAppSeetingHideModal, errorsUpdatePassword, updateEventSubmit, fieldsUpdatePassword, handleFile,
    changeStartUpdateDate, changeEndUpdateDate,
    // allCategory, 
    contentFieldUpdate,
    setCurrentIndexUpdate, handleUpload, allGameItems,
    // handleUploadImageUser,
    handleSelectedTagUpdate, options, updateSelectedTag,
    deleteContentFieldUpdate, inputChangeContentUpdate, offset,
    // tagFieldUpdate, deletetagFieldUpdate, inputChangetagUpdate,
    rteChangeUpdate, modules, formats, categoryListItems, subCategoryListItems } = props;

  console.log("MODAL______fieldsUpdatePassword::", fieldsUpdatePassword);
  // console.log("MODAL______fieldsUpdatePassword.category::", fieldsUpdatePassword.category);

  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Update Event</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>









              <form autoComplete="off">


                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Question</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdatePassword && !errorsUpdatePassword["que"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="que" name="que" placeholder="Question..." value={fieldsUpdatePassword.que} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["que"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["que"]}
                      </div>
                      : null}
                  </div>

                </div>
                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Match</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdatePassword && !errorsUpdatePassword["currentMatch"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="currentMatch" name="currentMatch" placeholder="Match..." value={fieldsUpdatePassword.currentMatch} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["currentMatch"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["currentMatch"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Winning Rate</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdatePassword && !errorsUpdatePassword["winAmtPercent"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="winAmtPercent" name="winAmtPercent" placeholder="Winning Rate..." value={fieldsUpdatePassword.winAmtPercent} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["winAmtPercent"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["winAmtPercent"]}
                      </div>
                      : null}
                  </div>
                </div>
                {/* <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Select Start Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          value={fieldsUpdatePassword.eventStartTime}
                          name="eventStartTime"
                          id="eventStartTime"
                          onChange={(newValue) => changeStartUpdateDate(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {errorsUpdatePassword && errorsUpdatePassword["eventStartTime"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["eventStartTime"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Select End Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          value={fieldsUpdatePassword.eventEndTime}
                          onChange={(newValue) => changeEndUpdateDate(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {errorsUpdatePassword && errorsUpdatePassword["eventEndTime"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["eventEndTime"]}
                      </div>
                      : null}
                  </div>
                </div> */}
                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Current Score</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdatePassword && !errorsUpdatePassword["currentScore"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="currentScore" name="currentScore" placeholder="Current Score..." value={fieldsUpdatePassword.currentScore} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["currentScore"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["currentScore"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Question Source</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdatePassword && !errorsUpdatePassword["queSource"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="queSource" name="queSource" placeholder="Question Source..." value={fieldsUpdatePassword.queSource} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["queSource"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsUpdatePassword["queSource"]}
                      </div>
                      : null}
                  </div>
                </div>













                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-2">
                  <div className="w-full mr-2">
                    {
                      fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ?
                        <>

                          <div className="w-full mr-2 mt-1 ">
                            <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ? fieldsUpdatePassword.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mr-2 mt-1 ">
                          <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageLinkUrl ? fieldsUpdatePassword.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                    <div className="mt-1 relative shadow-md">
                      <div className="w-full flex justify-between flex-wrap">
                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username"> Event </label>
                          <input type="file" name="image" onChange={handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 space-x-4 mt-1">
                          <button className="border bg-blue-500 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>







                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateEventSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
