import React from "react";

import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";


const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, updateSelectedTag, newsRowData, allCategory, options, offset, modules, formats, handleSelectedTagUpdate, categoryListItems, subCategoryListItems,
  } = props;

  return (

    <Modal
      isOpen={moreDetailsCreateModal}
    >
      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*Title*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">View News</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleMoreDetailsHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>

              <form autoComplete="off">
                <div className="flex justify-between w-full">


                  {/* <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      id="categoryId" name="categoryId"
                      value={newsRowData && newsRowData.categoryId ? newsRowData.categoryId : null}
                      disabled
                    >
                      <option selected>Plz Select Category</option>
                      {
                        allCategory && allCategory && allCategory.length > 0 ?
                          allCategory.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>

                  </div> */}

                  <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      disabled
                      value={newsRowData && newsRowData.category ? newsRowData.category : null}>
                      <option selected>Plz Select Category</option>
                      {
                        categoryListItems && categoryListItems && categoryListItems.length > 0 ?
                          categoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {/* {errorsUpdatePassword && errorsUpdatePassword["category"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["category"]}
                      </div>
                      : null} */}
                  </div>

                  <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Sub Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      disabled
                      value={newsRowData && newsRowData.subcategory ? newsRowData.subcategory : null}>
                      <option selected>Plz Select Category</option>
                      {
                        subCategoryListItems && subCategoryListItems && subCategoryListItems.length > 0 ?
                          subCategoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                  </div>


                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">News Id</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="news_id" name="news_id" placeholder="News Id" value={newsRowData.news_id} type="text"
                        disabled />
                    </div>
                  </div>

                </div>

                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Title</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="title" name="title" placeholder="Title" value={newsRowData.title} type="title"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Author</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="author" name="author" placeholder="Author" value={newsRowData.author} type="text"
                        disabled
                      />
                    </div>
                  </div>


                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Slug</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="slug" name="slug" placeholder="Slug" value={newsRowData.slug} type="slug"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Description</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="descirption" name="descirption" placeholder="Description" value={newsRowData.descirption} type="text"
                        disabled
                      />
                    </div>
                  </div>



                  {/* <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Sub Category</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="subcategory" name="subcategory" placeholder="Sub Category" value={newsRowData.subcategory} type="text"
                        disabled
                      />

                    </div>
                  </div> */}
                  {/* <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Source</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="source" name="source" placeholder="Source" value={newsRowData.source} type="text"
                        disabled
                      />

                    </div>
                  </div> */}
                </div>


                <div className="flex justify-between w-full mt-2">

                  {/* <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Publish Date</label>
                      <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                        id="pub_date" name="pub_date" placeholder="Publish Date" value={newsRowData.pub_date} type="text"
                        disabled
                      />

                    </div>
                  </div> */}

                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <ReactQuill
                        modules={modules}
                        formats={formats}
                        value={newsRowData.story}
                        disabled
                      />
                    </div>
                  </div>

                </div>

                <label class="block text-gray-700 text-sm" for="username">Image</label>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">

                  <Multiselect
                    options={options}
                    onSelect={handleSelectedTagUpdate}
                    selected={updateSelectedTag}
                    isObject={false}
                    displayValue="label"
                    value={(newsRowData && newsRowData.tag) ? newsRowData.tag.map((val) => { return val }) : ""}
                  />
                </div>

                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                  {
                    newsRowData && newsRowData.content && newsRowData.content.length > 0 ?
                      newsRowData.content.map((item, index) => (
                        <React.Fragment key={index}>
                          <>
                            <div>
                              {index === 0 ?
                                <button className="border bg-shine-400 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button"
                                >Add</button>
                                :
                                <button className="border bg-red-500 text-white px-2 py-1 text-sm font-semibold rounded-lg cursor-pointer" type="button"
                                >Delete</button>
                              }
                              <label class="block text-gray-700 text-sm" for="username">Content : {offset + index + 1}</label>
                              <input className="border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus ring w-full ease-linear transition-all duration-150 border-opacity-100 border border-red-500 "
                                id="content" name="content" placeholder="content" value={item}
                                type="text"
                              />
                            </div>
                          </>
                        </React.Fragment>
                      )) : null
                  }
                </div>


                <label class="block text-gray-700 text-sm" for="username">Image</label>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-2">
                  <div className="w-full mr-2">
                    {
                      newsRowData && newsRowData.imageUrlLink ?
                        <>

                          <div className="w-full mr-2 mt-1 ">
                            <img src={newsRowData && newsRowData.imageUrlLink ? newsRowData.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mr-2 mt-1 ">
                          <img src={newsRowData && newsRowData.imageLinkUrl ? newsRowData.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                    {/* <div className="mt-1 relative shadow-md">
                      <div className="w-full flex justify-between flex-wrap">
                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                          <input type="file" name="image"
                          />
                        </div>

                      </div>
                    </div> */}
                  </div>


                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >


  );
}
