import {
  sliderConstants
} from '../_constants';

export function slider(state = {}, action) {

  switch (action.type) {
    case sliderConstants.ADD_SLIDER_REQUEST:
      return {
        ...state
      };
    case sliderConstants.ADD_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case sliderConstants.ADD_SLIDER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case sliderConstants.NOTIFY_REQUEST:
      return {
        ...state
      };
    case sliderConstants.NOTIFY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case sliderConstants.NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case sliderConstants.GET_LIST_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GET_LIST_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getSliderList.list,
        total: action.users.getSliderList.total
      };
    case sliderConstants.GET_LIST_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sliderConstants.GET_REACHOUT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GET_REACHOUT_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        reachOutItems: action.users.getReachOutList.list,
        reachOutTotal: action.users.getReachOutList.total
      };
    case sliderConstants.GET_REACHOUT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sliderConstants.GETALL_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GETALL_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllMarket,
      };
    case sliderConstants.GETALL_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.UPDATE_SLIDER_REQUEST:
      return {
        ...state
      };
    case sliderConstants.UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case sliderConstants.UPDATE_SLIDER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case sliderConstants.DELETE_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DELETE_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case sliderConstants.DISABLE_SLIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DISABLE_SLIDER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DISABLE_SLIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sliderConstants.DELETE_REACHOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DELETE_REACHOUT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DELETE_REACHOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.DISABLE_REACHOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DISABLE_REACHOUT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DISABLE_REACHOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}