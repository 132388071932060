export const bankConstants = {

    GET_BANK_LIST_REQUEST: 'GET_BANK_LIST_REQUEST',
    GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
    GET_BANK_LIST_FAILURE: 'GET_BANK_LIST_FAILURE',

    GETALL_BANK_REQUEST: 'GETALL_BANK_REQUEST',
    GETALL_BANK_SUCCESS: 'GETALL_BANK_SUCCESS',
    GETALL_BANK_FAILURE: 'GETALL_BANK_FAILURE',

    GET_LIST_BANK_REQUEST: 'GET_LIST_BANK_REQUEST',
    GET_LIST_BANK_SUCCESS: 'GET_LIST_BANK_SUCCESS',
    GET_LIST_BANK_FAILURE: 'GET_LIST_BANK_FAILURE',

    ADD_BANK_REQUEST: 'ADD_BANK_REQUEST',
    ADD_BANK_SUCCESS: 'ADD_BANK_SUCCESS',
    ADD_BANK_FAILURE: 'ADD_BANK_FAILURE',

    DELETE_BANK_REQUEST: 'DELETE_BANK_REQUEST',
    DELETE_BANK_SUCCESS: 'DELETE_BANK_SUCCESS',
    DELETE_BANK_FAILURE: 'DELETE_BANK_FAILURE',

    UPDATE_BANK_REQUEST: 'UPDATE_BANK_REQUEST',
    UPDATE_BANK_SUCCESS: 'UPDATE_BANK_SUCCESS',
    UPDATE_BANK_FAILURE: 'UPDATE_BANK_FAILURE',

    DISABLE_BANK_REQUEST: 'DISABLE_BANK_REQUEST',
    DISABLE_BANK_SUCCESS: 'DISABLE_BANK_SUCCESS',
    DISABLE_BANK_FAILURE: 'DISABLE_BANK_FAILURE',

};
