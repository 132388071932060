export const offerConstants = {

    GET_OFFER_LIST_REQUEST: 'GET_OFFER_LIST_REQUEST',
    GET_OFFER_LIST_SUCCESS: 'GET_OFFER_LIST_SUCCESS',
    GET_OFFER_LIST_FAILURE: 'GET_OFFER_LIST_FAILURE',


    GETALL_BET_REQUEST: 'GETALL_BET_REQUEST',
    GETALL_BET_SUCCESS: 'GETALL_BET_SUCCESS',
    GETALL_BET_FAILURE: 'GETALL_BET_FAILURE',

    GETALL_BET_BY_ID_REQUEST: 'GETALL_BET_BY_ID_REQUEST',
    GETALL_BET_BY_ID_SUCCESS: 'GETALL_BET_BY_ID_SUCCESS',
    GETALL_BET_BY_ID_FAILURE: 'GETALL_BET_BY_ID_FAILURE',


    GETALL_USER_REQUEST: 'GETALL_USER_REQUEST',
    GETALL_USER_SUCCESS: 'GETALL_USER_SUCCESS',
    GETALL_USER_FAILURE: 'GETALL_USER_FAILURE',

    ADD_OFFER_REQUEST: 'ADD_OFFER_REQUEST',
    ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
    ADD_OFFER_FAILURE: 'ADD_OFFER_FAILURE',

    DELETE_OFFER_REQUEST: 'DELETE_OFFER_REQUEST',
    DELETE_OFFER_SUCCESS: 'DELETE_OFFER_SUCCESS',
    DELETE_OFFER_FAILURE: 'DELETE_OFFER_FAILURE',

    UPDATE_BET_REQUEST: 'UPDATE_BET_REQUEST',
    UPDATE_BET_SUCCESS: 'UPDATE_BET_SUCCESS',
    UPDATE_BET_FAILURE: 'UPDATE_BET_FAILURE',
    
    UPDATE_OFFER_REQUEST: 'UPDATE_OFFER_REQUEST',
    UPDATE_OFFER_SUCCESS: 'UPDATE_OFFER_SUCCESS',
    UPDATE_OFFER_FAILURE: 'UPDATE_OFFER_FAILURE',

    DISABLE_OFFER_REQUEST: 'DISABLE_OFFER_REQUEST',
    DISABLE_OFFER_SUCCESS: 'DISABLE_OFFER_SUCCESS',
    DISABLE_OFFER_FAILURE: 'DISABLE_OFFER_FAILURE',

};
