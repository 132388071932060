import {
  userConstants, matchesInfoConstants
} from '../_constants';

export function matchesInfo(state = {}, action) {

  switch (action.type) {

    case matchesInfoConstants.MATCHES_BY_CS_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchesInfoConstants.MATCHES_BY_CS_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.matchesByCsId.list,
        total: action.users.matchesByCsId.total
      };
    case matchesInfoConstants.MATCHES_BY_CS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchesInfoConstants.ADD_MATCHES_INFO_REQUEST:
      return {
        ...state
      };
    case matchesInfoConstants.ADD_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchesInfoConstants.ADD_MATCHES_INFO_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchesInfoConstants.GET_LIST_MATCHES_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchesInfoConstants.GET_LIST_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getMatchesInfoList.list,
        total: action.users.getMatchesInfoList.total
      };
    case matchesInfoConstants.GET_LIST_MATCHES_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchesInfoConstants.GETALL_MATCHES_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchesInfoConstants.GETALL_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        matchesInfoItems: action.users.getAllMatchesInfo,
        matchesInfoTotal: action.users.getAllMatchesInfo
      };
    case matchesInfoConstants.GETALL_MATCHES_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_MATCHES_INFO_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_MATCHES_INFO_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_MATCHES_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_MATCHES_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_MATCHES_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_MATCHES_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_MATCHES_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}