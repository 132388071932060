import {
   gameResultDateConstants
} from '../_constants';

export function gameResultDate(state = {}, action) {

  switch (action.type) {
    case gameResultDateConstants.ADD_GAME_RESULT_DATE_REQUEST:
      return {
        ...state
      };
    case gameResultDateConstants.ADD_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case gameResultDateConstants.ADD_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case gameResultDateConstants.GET_LIST_GAME_RESULT_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultDateConstants.GET_LIST_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAdsList.list,
        total: action.users.getAdsList.total
      };
    case gameResultDateConstants.GET_LIST_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameResultDateConstants.GETALL_GAME_RESULT_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultDateConstants.GETALL_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        adsItems: action.users.getAllAds,
        adsTotal: action.users.getAllAds
      };
    case gameResultDateConstants.GETALL_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gameResultDateConstants.UPDATE_GAME_RESULT_DATE_REQUEST:
      return {
        ...state
      };
    case gameResultDateConstants.UPDATE_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case gameResultDateConstants.UPDATE_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case gameResultDateConstants.DELETE_GAME_RESULT_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultDateConstants.DELETE_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameResultDateConstants.DELETE_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameResultDateConstants.DISABLE_GAME_RESULT_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultDateConstants.DISABLE_GAME_RESULT_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameResultDateConstants.DISABLE_GAME_RESULT_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}