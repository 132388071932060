
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const websiteService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getWebsiteList,
    disableWebsite,
    deleteWebsite,
    createWebsite,

    getAllBet,
    getAllUser,
    updateWebsite,
    getAllBetByBetTypeId,
    getTicketList,
    approveTicket,
    rejectTicket,
    getTicketByTicketId,
    updateTicketStatusOfId
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getWebsiteList(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptions___________requestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getWebsiteList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getWebsiteList: data.data
            }
            console.log("i am in service getWebsiteList", userObj);

            return userObj;
        });
}

function getTicketList(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptions___________requestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getTicketList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTicketList: data.data
            }
            console.log("i am in service getTicketList", userObj);

            return userObj;
        });
}
function getAllBet(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllBid`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllBet: data.data
            }
            console.log("i am in service getAllBid", userObj);

            return userObj;
        });
}
function getAllUser(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllUserr: data.data
            }
            console.log("i am in service getAllBid", userObj);

            return userObj;
        });
}
function getAllBetByBetTypeId(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllBetByBidTypeId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllBetByBetTypeId: data.data
            }
            console.log("i am in service getAllBetByBidTypeId", userObj);

            return userObj;
        });
}
function getTicketByTicketId(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTicketByTicketId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTicketByTicketId: data.data
            }
            console.log("i am in service getTicketByTicketId", userObj);

            return userObj;
        });
}



function deleteWebsite(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteWebsite`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteWebsite: data.data
            }
            console.log("i am in service''...>> deleteWebsite ::", userObj);

            return userObj;
        });
}
function disableWebsite(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateWebsiteStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateWebsiteStatus: data.data
            }
            console.log("i am in service''...>> updateWebsiteStatus ::", userObj);

            return userObj;
        });
}

function updateWebsite(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateWebsite`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateWebsite: data.data
            }
            console.log("I am in service updateWebsite", userObj);

            return userObj;
        });
}
function createWebsite(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createWebsite`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createWebsite: data.data
            }
            console.log("I am in service createWebsite", userObj);

            return userObj;
        });
}
function approveTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTicketStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                approveTicket: data.data
            }
            console.log("I am in service approveTicket", userObj);

            return userObj;
        });
}
function updateTicketStatusOfId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTicketStatusOfId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateTicketStatusOfId: data.data
            }
            console.log("I am in service updateTicketStatusOfId", userObj);

            return userObj;
        });
}
function rejectTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTicketStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                rejectTicket: data.data
            }
            console.log("I am in service rejectTicket", userObj);

            return userObj;
        });
}


function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}