import React, { Component } from 'react';
import { connect } from 'react-redux';
import { offerActions, userActions, newsActions, websiteActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"


class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      sliderContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    this.props.dispatch(websiteActions.getTicketByTicketId({
      ticketId: this.props.match.params.ticketId
    }));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.offer.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.offer.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  render() {

    let { website, users } = this.props;
    let { ticketDetail, loading } = website;
    console.log('ticketDetail_________________ticketDetail', ticketDetail);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        {ticketDetail ?

          <div class="bg-white">
            <div class="flex py-6">
              <div class="container mx-auto px-8">
                <div class="flex px-10">
                  <div class="w-1/4">



                    <div class="mb-4">
                      <h1 class="text-3xl font-medium text-grey-darkest">
                        {ticketDetail.userName}
                      </h1>
                      <p class="text-xl text-grey-dark font-light">
                        <img src={ticketDetail.imageLinkUrl} alt="Image Not Found"></img>

                      </p>
                    </div>


                  </div>
                  <div class="w-3/4 ml-6 mt-2">

                    <div class="flex">

                      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Mode
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {ticketDetail.mode}
                          </p>
                        </div>
                      </div>

                      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Amount
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {ticketDetail.amount}
                          </p>
                        </div>
                      </div>

                      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Status
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {ticketDetail.status == 0 ?
                              "Pending" : null}
                            {ticketDetail.status == 1 ?
                              "Approved" : null}
                            {ticketDetail.status == 2 ?
                              "Reject" : null}
                          </p>
                        </div>
                      </div>

                    </div>
                    <div class="flex">

                      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Tx Type
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {ticketDetail.txType}
                          </p>
                        </div>
                      </div>

                      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Type
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {ticketDetail.type}
                          </p>
                        </div>
                      </div>



                    </div>
                    <div class="flex">
                      <div class="w-full border px-4 py-4 mb-4 ml-4 rounded text-sm">
                        <div class="flex justify-center">
                          <h1 className='text-xl font-bold '>
                            Date/Time
                          </h1>
                        </div>
                        <div class="flex justify-center text-sm mt-6">
                          <p class="font-bold text-lg text-blue-800">
                            {moment(new Date(parseInt(ticketDetail.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div >



          </div >
          : null
        }












      </>

    );
  }
}
function mapStateToProps(state) {
  const { offer, users, category, news, website } = state;
  return {
    offer,
    users,
    category,
    news,
    website
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Offer);
