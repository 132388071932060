import React from "react";

import Modal from 'react-modal';
// import moment from 'moment';
// import { MultiSelect } from "react-multi-select-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";


import Multiselect from 'multiselect-react-dropdown';




export default function CreateAddDealerModal(props) {

  let { addUserCreateModal, handleHideModel, errorsAddUser, inputAddUserChange, changePasswordAdmin, fieldsAddUser, handleFile, allGameItems,
    handleUpload,
    options, handleSelectedTag, selectedTag,
    rteChange, modules, formats, categoryListItems, subCategoryListItems } = props;
  console.log("addUserCreateModal______________________", addUserCreateModal);



  return (

    <Modal
      isOpen={addUserCreateModal}

    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed left-0 top-0 w-full h-full inset-0 z-90 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full ">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Change Password</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleHideModel()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">
              <form autoComplete="on">



                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">New Password</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["newpassword"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="newpassword" name="newpassword" placeholder="New Password..." type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["newpassword"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsAddUser["newpassword"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Confirm Password</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["confirmpassword"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="confirmpassword" name="confirmpassword" placeholder="Confirm Password..." type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["confirmpassword"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsAddUser["confirmpassword"]}
                      </div>
                      : null}
                  </div>
                </div>









                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={changePasswordAdmin}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
