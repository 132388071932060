import {
  userConstants, liveConstants
} from '../_constants';

export function live(state = {}, action) {

  switch (action.type) {
    case liveConstants.LIVE_MATCH_LIST_BY_MATCH_ID_REQUEST:
      return {
        ...state
      };
    case liveConstants.LIVE_MATCH_LIST_BY_MATCH_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        items: action.users.liveMatchListByMatchId,
        total: action.users.liveMatchListByMatchId
      };
    case liveConstants.LIVE_MATCH_LIST_BY_MATCH_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case liveConstants.ADD_LIVE_REQUEST:
      return {
        ...state
      };
    case liveConstants.ADD_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case liveConstants.ADD_LIVE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case liveConstants.GET_LIST_LIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case liveConstants.GET_LIST_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getLiveList.list,
        total: action.users.getLiveList.total
      };
    case liveConstants.GET_LIST_LIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case liveConstants.GETALL_LIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case liveConstants.GETALL_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        liveItems: action.users.getAllLive,
        liveTotal: action.users.getAllLive
      };
    case liveConstants.GETALL_LIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_LIVE_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_LIVE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_LIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_LIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_LIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_LIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_LIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}