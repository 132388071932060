import React, { Component } from 'react';
import { connect } from 'react-redux';
import { categoryActions, userActions, bankActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import CreateAddModal from "./components/CreateAddModal/CreateAddModalBank";
import UpdateVideoModal from "./components/UpdateVideoModal/UpdateVideoModalBank";
import ViewVideoModal from "./components/ViewVideoModal/ViewVideoModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTagIndex: null,
      selectedTag: [""],
      updateSelectedTag: [""],
      isVideo: false,
      isVideoUpdate: false,
      videoRowData: {},
      fieldsBank: {
        tag: [""]
      },
      errorsVideo: {
        tag: [""]
      },
      fieldsUpdateVideo: {
        tag: [""]
      },
      errorsUpdateVideo: {
        tag: [""]
      },
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateVideoCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(bankActions.getBankList(data));



  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bank.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsBank: {},
        errorsVideo: {},
        addUserCreateModal: false,
        UpdateVideoCreateModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(bankActions.getBankList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(bankActions.getBankList(data));
  }

  disableBank = (data) => {
    console.log('disable000000000000000000', data);
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      acHolder: 'Confirm to disable Bank?',
      message: `Are you sure to disable ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(bankActions.disableBank(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteBank = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      acHolder: 'Confirm to Delete?',
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(bankActions.deleteBank(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      acHolder: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(categoryActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //   "id": data.id
    // }
    // this.props.dispatch(categoryActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, videoRowData: data });
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateVideoCreateModal: true, fieldsUpdateVideo: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateVideoCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(categoryActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsBank } = this.state;
    fieldsBank['video_id'] = val;
    this.setState({ addUserCreateModal: true, fieldsBank });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsBank = this.state.fieldsBank;
    let errorsVideo = this.state.errorsVideo;
    fieldsBank[name] = value;
    console.log(name, value);
    errorsVideo[name] = "";
    this.setState({ fieldsBank, errorsVideo });
  }
  inputChangeUpdateCategory = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateVideo = this.state.fieldsUpdateVideo;
    let errorsUpdateVideo = this.state.errorsUpdateVideo;
    fieldsUpdateVideo[name] = value;
    errorsUpdateVideo[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateVideo, errorsUpdateVideo });
  }

  createBankSubmit = () => {
    console.log('111111111111111111111111');
    let { users } = this.props;
    let { filesDetails } = users;
    console.log("RENDER________filesDetails:", filesDetails);

    if (this.handleValidationAddBank()) {
      let reqData = {
        "acHolder": this.state.fieldsBank.acHolder,
        "acType": this.state.fieldsBank.acType,
        "accountNo": this.state.fieldsBank.accountNo,
        "bank": this.state.fieldsBank.bank,
        "ifsc": this.state.fieldsBank.ifsc,
      }

      // accountNo
      // bank
      // ifsc
      // acHolder
      // acType

      console.log("createVideo>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(bankActions.createBank(reqData));
    }

  }

  handleValidationAddBank = () => {
    let fieldsBank = this.state.fieldsBank;
    let errorsVideo = {};
    let formIsValid = true;

    //acHolder
    if (!fieldsBank["acHolder"] || fieldsBank["acHolder"] === "") {
      formIsValid = false;
      errorsVideo["acHolder"] = "Cannot be empty acHolder";
    }

    //acType
    if (!fieldsBank["acType"] || fieldsBank["acType"] === "") {
      formIsValid = false;
      errorsVideo["acType"] = "Cannot be empty acType";
    }

    //accountNo
    if (!fieldsBank["accountNo"] || fieldsBank["accountNo"] === "") {
      formIsValid = false;
      errorsVideo["accountNo"] = "Cannot be empty accountNo";
    }

    //bank
    if (!fieldsBank["bank"] || fieldsBank["bank"] === "") {
      formIsValid = false;
      errorsVideo["bank"] = "Cannot be empty bank";
    }

    //ifsc
    if (!fieldsBank["ifsc"] || fieldsBank["ifsc"] === "") {
      formIsValid = false;
      errorsVideo["ifsc"] = "Cannot be empty ifsc";
    }

    console.log('errorsVideo__________errorsVideo', errorsVideo);

    this.setState({ errorsVideo: errorsVideo });
    return formIsValid;
  }

  updateVideoSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    console.log("RENDER________filesDetails:", filesDetails);
    if (this.handleValidationUpdateCategory()) {
      let reqData = {
        "id": this.state.fieldsUpdateVideo.id,
        "acHolder": this.state.fieldsUpdateVideo.acHolder,
        "acType": this.state.fieldsUpdateVideo.acType,
        "accountNo": this.state.fieldsUpdateVideo.accountNo,
        "bank": this.state.fieldsUpdateVideo.bank,
        "ifsc": this.state.fieldsUpdateVideo.ifsc,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>000000000", reqData);

      this.props.dispatch(bankActions.updateBank(reqData, paginationData));
    }

  }


  handleValidationUpdateCategory = () => {
    let fieldsUpdateVideo = this.state.fieldsUpdateVideo;
    let errorsUpdateVideo = {};
    let formIsValid = true;


    //acHolder
    if (!fieldsUpdateVideo["acHolder"] || fieldsUpdateVideo["acHolder"] === "") {
      formIsValid = false;
      errorsUpdateVideo["acHolder"] = "Cannot be empty acHolder";
    }

    //accountNo
    if (!fieldsUpdateVideo["accountNo"] || fieldsUpdateVideo["accountNo"] === "") {
      formIsValid = false;
      errorsUpdateVideo["accountNo"] = "Cannot be empty accountNo";
    }


    //acType
    if (!fieldsUpdateVideo["acType"] || fieldsUpdateVideo["acType"] === "") {
      formIsValid = false;
      errorsUpdateVideo["acType"] = "Cannot be empty acType";
    }

    //bank
    if (!fieldsUpdateVideo["bank"] || fieldsUpdateVideo["bank"] === "") {
      formIsValid = false;
      errorsUpdateVideo["bank"] = "Cannot be empty bank";
    }

    //ifsc
    if (!fieldsUpdateVideo["ifsc"] || fieldsUpdateVideo["ifsc"] === "") {
      formIsValid = false;
      errorsUpdateVideo["ifsc"] = "Cannot be empty ifsc";
    }



    this.setState({ errorsUpdateVideo: errorsUpdateVideo });
    return formIsValid;
  }


  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleSelectGameType = (e) => {
    console.log("handleSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }

  addTagField = () => {
    const { fieldsBank } = this.state;

    fieldsBank.tag.push('')
    this.setState({ fieldsBank })
    console.log("fieldsBank  ", fieldsBank);
  }

  deleteTagField = (index) => {
    const { fieldsBank } = this.state;
    fieldsBank.tag.splice(index, 1)
    this.setState({ fieldsBank });
  }

  setCurrentIndex = (currentTagIndex) => {

    this.setState({ currentTagIndex: currentTagIndex });
    console.log('kkkkkkkkkk  ', currentTagIndex);
  }

  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsBank = this.state.fieldsBank;
    console.log("fieldsBankfieldsBank  ", fieldsBank);
    fieldsBank["tag"][this.state.currentTagIndex] = value;
    this.setState({ fieldsBank });
  }

  tagFieldUpdate = () => {
    const { fieldsUpdateVideo } = this.state;

    fieldsUpdateVideo.tag.push('')
    this.setState({ fieldsUpdateVideo })
    // console.log("fieldsUpdateVideo  ", fieldsUpdateVideo);
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdateVideo } = this.state;
    fieldsUpdateVideo.tag.splice(index, 1)
    this.setState({ fieldsUpdateVideo });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdateVideo = this.state.fieldsUpdateVideo;
    fieldsUpdateVideo["tag"][this.state.currentTagIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdateVideo);
    this.setState({ fieldsUpdateVideo });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  handleFileVideo = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { category, bank, users } = this.props;
    let { total, allCategory, allCategoryParent } = category;
    let { loading, bankListItems } = bank;
    let { filesDetails } = users;

    console.log('bankListItems_____________bankListItems?????', bankListItems);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER"]
    console.log("RENDER________filesDetails:::::::::::::::::::::::::::::::::::::UPI", filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">

                  <div className="flex flex-wrap items-center w-full justify-between ">
                    <div className="">
                      <h3 className="text-xl font-semibold  ">Bank</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" acHolder="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      <button className="bg-blue-500 text-white py-2 text-sm px-6 rounded-md font-medium capitalize flex items-center " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add Bank </button>

                    </div>

                  </div>

                  <div className="pb-2 mt-4 overflow-hidden ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y  ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">ac Holder</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">bank</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">ifsc</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">acc No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">ac Type</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Status</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                bankListItems && bankListItems.length > 0 ?
                                  bankListItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.acHolder ? element.acHolder : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bank ? element.bank : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.ifsc ? element.ifsc : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.accountNo ? element.accountNo : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.acType ? element.acType : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {element.isDisable == false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableBank(element)}>

                                              <label>Enabled</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableBank(element)}>

                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>


                                      <td className=" px-2 py-3 text-gray-600 whitespace-nowrap space-x-2 text-center flex justify-center">
                                        <div className='flex space-x-2'>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8  text-white text-sm rounded'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteBank(element)}>
                                              <span className='tooltip-text bg-gray-500 p-6 mx-auto top-0 left-0 -mt-8 -ml-2 text-white text-sm rounded'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddModal
          options={options}
          selectedTag={this.state.selectedTag}
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsBank={this.state.fieldsBank}
          errorsVideo={this.state.errorsVideo}
          allCategoryParent={allCategoryParent}
          filesDetails={filesDetails}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          createBankSubmit={this.createBankSubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          setCurrentIndex={this.setCurrentIndex}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          isVideo={this.state.isVideo}
          offset={this.state.offset}
          handleSelectedTag={this.handleSelectedTag}
          handleFileVideo={this.handleFileVideo}
        />

        <UpdateVideoModal
          options={options}
          allCategoryParent={allCategoryParent}
          UpdateVideoCreateModal={this.state.UpdateVideoCreateModal}
          fieldsUpdateVideo={this.state.fieldsUpdateVideo}
          errorsUpdateVideo={this.state.errorsUpdateVideo}
          filesDetails={filesDetails}
          inputChangeUpdateCategory={this.inputChangeUpdateCategory}
          updateVideoSubmit={this.updateVideoSubmit}
          allCategory={allCategory}
          handleFile={this.handleFile}
          // handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleUpdatePasswordHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}
          offset={this.state.offset}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          setCurrentIndex={this.setCurrentIndex}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleFileVideo={this.handleFileVideo}
        />

        <ViewVideoModal
          offset={this.state.offset}
          allCategoryParent={allCategoryParent}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          videoRowData={this.state.videoRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { category, users, bank } = state;
  return {
    category,
    users,
    bank
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Bank);
