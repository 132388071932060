
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    adminLogin,
    getProfile,
    // deleteUser,
    logout,
    updateUserPasswordSubmit,
    registerUserAdmin,
    getUserByUserId,
    // getAllNotification,
    // updateNotificationStatus,
    // createNewUser,
    // uploadImage,
    // getRestaurant,
    // addMenu,
    // addItem,
    // updatePasswordUser,
    // sendFrom,
    // onChangeImageFile,
    // onChangeImageFileLogo,
    // addOrRemoveImageInRestaurant,
    // deleteItem,
    // statics,
    // deleteRestaurant,
    // disableItem,
    // deleteCategory,
    // updateRestaurant,
    // updateCategory,
    // updateItem,
    // updateRestaurantLogo,
    // disableRestaurant,disableCategory,
    getUserActive,
    getUserDeactive,
    disableUser,
    deleteUser,
    verifyEmail,
    loginToThisAccount,
    getProfileList,
    getProfileByUserId,
    getAllSeries,
    getAllMatch,
    createSport,
    createSeries,
    createMatch,
    getAllMarket,
    getAllMatchActive,
    updateMatchStatus,
    uploadImage,
    updateMatchMarketId,
    getOdds,
    getMatchBymatchId,
    updateMatchTeamImage,
    createMarket,
    uploadVideo,
    getUserPaymentDataByUIdOrPId,
    getAddressByUserId,
    getUserWebsiteIdByUserId,
    getAllPaymentType,
    createOrUpdateAppSetting,
    getAppSetting,
    changePasswordAdmin

};


function getUserPaymentDataByUIdOrPId(data) {
    console.log("getUserPaymentDataByUIdOrPId", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPaymentDataByUIdOrPId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPaymentDataByUIdOrPId: data.data
            }
            console.log("i am in service getUserPaymentDataByUIdOrPId ::", userObj);

            return userObj;
        });
}
function createOrUpdateAppSetting(data) {
    console.log("createOrUpdateAppSetting", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createOrUpdateAppSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createOrUpdateAppSetting: data.data
            }
            console.log("i am in service createOrUpdateAppSetting ::", userObj);

            return userObj;
        });
}
function changePasswordAdmin(data) {
    console.log("changePasswordAdmin", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changePasswordAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                changePasswordAdmin: data.data
            }
            console.log("i am in service changePasswordAdmin ::", userObj);

            return userObj;
        });
}
function getAppSetting(data) {
    console.log("getAppSetting", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAppSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAppSetting: data.data
            }
            console.log("i am in service getAppSetting ::", userObj);

            return userObj;
        });
}
function getAllPaymentType(data) {
    console.log("getAllPaymentType", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPaymentType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllPaymentType: data
            }
            console.log("i am in service getAllPaymentType ::", userObj);

            return userObj;
        });
}
function getUserWebsiteIdByUserId(data) {
    console.log("getUserWebsiteIdByUserId", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserWebsiteIdListByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserWebsiteIdByUserId: data.data
            }
            console.log("i am in service getUserWebsiteIdByUserId ::", userObj);

            return userObj;
        });
}
function getAddressByUserId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAddressByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAddressByUserId: data.data
            }
            console.log("i am in service getAddressByUserId ::", userObj);

            return userObj;
        });
}

function updateMatchMarketId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchMarketId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchMarketIdItems: data.data
            }
            console.log("i am in service updateMatchMarketId ::", userObj);

            return userObj;
        });
}
function createMarket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createMarket`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createMarket: data.data
            }
            console.log("i am in service createMarket ::", userObj);

            return userObj;
        });
}
function createMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createSerie: data.data
            }
            console.log("i am in service createMatchcreateMatchcreateMatchcreateMatch ::", userObj);

            return userObj;
        });
}

function uploadVideo(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                videoDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}

function createSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createSerie: data.data
            }
            console.log("i am in service createSeriescreateSeriescreateSeriescreateSeries ::", userObj);

            return userObj;
        });
}

function updateMatchStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchStatus: data.data
            }
            // console.log("i am in service updateMatchStatus ::", userObj);

            return userObj;
        });
}
function createSport(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSport`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createMatch: data.data
            }
            // console.log("i am in service createSportcreateSportcreateSportcreateSport ::", userObj);

            return userObj;
        });
}

function getAllMatchActive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMatchActive`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMatchActive?sportId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allMatchActive: data.data
            }
            console.log("i am in service getAllMatchActive:::::::::::", userObj);

            return userObj;
        });
}
function getAllMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMatch?sportId=${data.sportId}&seriesId=${data.seriesId}`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMatch?sportId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allMatch: data.data
            }
            console.log("i am in service getAllMatchgetAllMatchgetAllMatchgetAllMatch:::::::::::", userObj);

            return userObj;
        });
}
function updateMatchTeamImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchTeamImage`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/updateMatchTeamImage?matchId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchTeamImage: data
            }
            console.log("i am in service updateMatchTeamImage:::::::::::123", userObj);

            return userObj;
        });
}
function getAllMarket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMarket?matchId=${data.matchId}`, requestOptions)
        // return fetch(CONST.BACKEND_URL + `/getAllMarket?matchId=4&seriesId=11365612`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allMarket: data.data
            }
            console.log("i am in service getAllMarket:::::::::::123", userObj);

            return userObj;
        });
}

function getMatchBymatchId(data) {
    // console.log("getMatchBymatchIdSERVICE1", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMatchBymatchId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMatchBymatchId: data
            }
            // console.log("i am in service getMatchBymatchId ::", userObj);

            return userObj;
        });
}
function getOdds(marketId) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getOdds?marketId=${marketId}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getallOdds: data
            }
            // console.log("i am in service getOdds ::", userObj);

            return userObj;
        });
}
function getAllSeries(sportId) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "GET",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllSeries?sportId=${sportId}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                allSeries: data.data
            }
            // console.log("i am in service getAllSeriesgetAllSeriesgetAllSeries ::", userObj);

            return userObj;
        });
}

function getProfileList(data) {
    console.log("*****************************************", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfileList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("i am in service allSportallSportallSportallSport ::", data.data.list);

            let userObj = {
                getProfileList: data.data
            }

            return userObj;
        });
}
function getProfileByUserId(data) {
    console.log("*****************************************", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfileByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("i am in service allSportallSportallSportallSport ::", data.data.list);

            let userObj = {
                getProfileByUserId: data.data
            }

            return userObj;
        });
}
function getUserByUserId(data) {
    console.log("*****************************************", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("i am in service allSportallSportallSportallSport ::", data.data.list);

            let userObj = {
                getUserByUserId: data.data
            }

            return userObj;
        });
}



function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/adminLogin";
    // history.push(`#/adminLogin`);
    // window.location.reload();


}
function adminLogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    console.log("requestOptions@@@@@@@@@@@@@@@@@@@@@@@@@@", requestOptions);

    return fetch(CONST.BACKEND_URL + `/adminLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function verifyEmail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                verifyEmail: data.data
            }
            // console.log("i am in service''...>> verifyEmail ::", userObj);

            return userObj;
        });
}
function deleteUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteUser: data.data
            }
            // console.log("i am in service''...>> deleteUser ::", userObj);

            return userObj;
        });
}
function disableUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                disableUser: data.data
            }
            // console.log("i am in service''...>> disableUser ::", userObj);

            return userObj;
        });
}
function getUserActive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserActive: data.data
            }
            // console.log("i am in service getUserActive ::", userObj);

            return userObj;
        });
}
function getUserDeactive(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserDeactive: data.data
            }
            // console.log("i am in service getUserDeactive ::", userObj);

            return userObj;
        });
}

function registerUserAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createUserByAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                registerUserAdmin: data.data
            }
            // console.log("I am in service registerUserAdmin", userObj);

            return userObj;
        });
}
function updateUserPasswordSubmit(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePasswordUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                UpdatePasswordUser: data.data
            }
            // console.log("I am in service UpdatePasswordUser", userObj);

            return userObj;
        });
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/loginToUserAccount`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                loginToThisAccount: data.data
            }
            // console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}

function getProfile(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfileByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getProfile: data.data
            }
            // console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}

// function getRestaurant(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/useroverView`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 restaurantDetails: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }
// function getAllNotification(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 listOfNotification: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }
// function updateNotificationStatus(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateNotificationStatus`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 listOfNotification: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }
// function createNewUser(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createNewUser`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 createNewUser: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }
// function deleteUser(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 deleteUser: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }

// function addMenu(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/addMenu`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 addMenu: data.data
//             }
//             console.log();

//             return userObj;
//         });
// }

// function addItem(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/addItem`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 addItem: data.data
//             }
//             return userObj;
//         });
// }

// function updatePasswordUser(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updatePasswordUser`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 addItem: data.data
//             }
//             return userObj;
//         });
// }
// function sendFrom(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/sendFrom`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 addItem: data.data
//             }
//             return userObj;
//         });
// }

// function uploadImage(filedata) {

//     let header = new Headers({
//         "Authorization": authHeader().Authorization
//     });
//     var data = new FormData();
//     data.append('image', filedata);

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: data
//     }
//     return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 filesDetails: res.data
//             }
//             return userObj;
//         });
// }

// function onChangeImageFile(filedata,id) {

//     let header = new Headers({
//         "Authorization": authHeader().Authorization
//     });
//     var data = new FormData();
//     data.append('image', filedata);

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: data
//     }
//     return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj={
//                 "id": id,
//                 "isAdd": true,
//                 "imageName": res.data.uploadedImageName
//             }
//             console.log("userObj  ",userObj);

//             return this.addOrRemoveImageInRestaurant(userObj)

//         });
// }
// function onChangeImageFileLogo(filedata,id) {
//     let header = new Headers({
//         "Authorization": authHeader().Authorization
//     });
//     var data = new FormData();
//     data.append('image', filedata);
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: data
//     }
//     return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj={
//                 "restaurantId": id,
//                 "imageName": res.data.uploadedImageName
//             }
//             console.log("userObj  ",userObj);
//             return this.updateRestaurantLogo(userObj)
//         });
// }
// function addOrRemoveImageInRestaurant(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/addOrRemoveImageInRestaurant`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 addImageRestaurant: data.data
//             }
//             return userObj;
//         });
// }
// function updateRestaurantLogo(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateRestaurantLogo`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 addImageRestaurant: data.data
//             }
//             return userObj;
//         });
// }
// function deleteItem(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteItem`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 delItem: data.data
//             }
//             return userObj;
//         });
// }
// function disableItem(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/disableItem`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 disableItem: data.data
//             }
//             return userObj;
//         });
// }

// function deleteRestaurant(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/verifyEmail`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 deleteRestaurant: data.data
//             }
//             return userObj;
//     });
// }

// function disableRestaurant(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/disableUser`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 disableRestaurant: data.data
//             }
//             return userObj;
//     });
// }
// function disableCategory(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/disableMenu`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 disableCategory: data.data
//             }
//             return userObj;
//     });
// }
// function deleteCategory(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteMenu`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 deleteCategory: data.data
//             }
//             return userObj;
//     });
// }

// function updateRestaurant(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateRestaurant`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 updateRestaurant: data.data
//             }
//             return userObj;
//     });
// }

// function updateCategory(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateMenu`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 updateCategory: data.data
//             }
//             return userObj;
//     });
// }


// function updateItem(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateItem`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 updateItem: data.data
//             }
//             return userObj;
//     });
// }

// function statics() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header
//     }
//     return fetch(CONST.BACKEND_URL + `/adminoverView`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let userObj = {
//                 statics: data.data
//             }
//             return userObj;
//         });
// }

function handleResponse(response) {
    console.log('111111', response);
    return response.text().then(text => {
        console.log('text0000000000', text);
        const data = text && JSON.parse(text);
        console.log('response.ok_______', response.ok);
        if (!response.ok) {
            console.log('11111');
            if (response.status === 401) {
                logout();
                //location.reload(true);
            }
            console.log("111111", data);

            const error = (data && data.message) || response.statusText;

            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}