import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  console.log("hfjgheu55ytu", action);
  switch (action.type) {

    case userConstants.ADD_MATCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_MATCH_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createMatch: action.users.createMatch,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_PAYMENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        payment: action.users.getUserPaymentDataByUIdOrPId,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_PAYMENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_OR_UPDATE_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_OR_UPDATE_APP_SETTING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        appSetting: action.users.createOrUpdateAppSetting,
        // total: action.users.getUserActive.total
      };
    case userConstants.CREATE_OR_UPDATE_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CHANGE_ADMIN_PASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CHANGE_ADMIN_PASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // total: action.users.getUserActive.total
      };
    case userConstants.CHANGE_ADMIN_PASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_APP_SETTING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAppSetting: action.users.getAppSetting,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CREATE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createMarketItems: action.users.createMarket,
        // total: action.users.getUserActive.total
      };
    case userConstants.CREATE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_SERIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_SERIES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createSerie: action.users.createSerie,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_SERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_SPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_SPORT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        createSport: action.users.createMatch,
        // total: action.users.getUserActive.total
      };
    case userConstants.ADD_SPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GETALL_MARKET_REQUEST:
      return {
        ...state,
        loading: true,
        allMarket: null,
      };
    case userConstants.GETALL_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allMarket: action.users.allMarket,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_MATCH_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        updateMatchStatus: action.users.updateMatchStatus,
        // total: action.users.getUserActive.total
      };
    case userConstants.UPDATE_MATCH_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_MATCH_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_MATCH_ACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        isMatchImageUpdate: false,
        loading: false,
        filesDetails: {},
        allMatchActive: action.users.allMatchActive,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_MATCH_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_USER_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        isMatchImageUpdate: false,
        loading: false,
        filesDetails: {},
        getAllPaymentType: action.users.getAllPaymentType.data,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_USER_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_MATCH_REQUEST:
      return {
        ...state,
        loading: true,
        allMatch: null,
      };
    case userConstants.GETALL_MATCH_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // addUserSuccess: true,
        allMatch: action.users.allMatch,
        allMarket: null,
        // total: action.users.getUserActive.total
      };
    case userConstants.GETALL_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_MATCH_BY_MATCHID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MATCH_BY_MATCHID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getMatchBymatchIdItems: action.users.getMatchBymatchId,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_MATCH_BY_MATCHID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ODDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ODDS_SUCCESS:
      console.log("state state  ", state.getallOdds);
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getallOdds: action.users.getallOdds && action.users.getallOdds.marketId ? action.users.getallOdds : state.getallOdds,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_ODDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
        allSeries: null,
      };
    case userConstants.GETALL_SERIES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allSeries: action.users.allSeries,
        allMarket: null,
        allMatch: null
      };
    case userConstants.GETALL_SERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.GET_PROFILE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        profileItems: action.users.getProfileList.list,
        // profileTotal: action.users.getProfileList.total
      };
    case userConstants.GET_PROFILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_PROFILE_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROFILE_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userProfileItems: action.users.getProfileByUserId,
        // profileTotal: action.users.getProfileList.total
      };
    case userConstants.GET_PROFILE_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userItem: action.users.getUserByUserId,
        // profileTotal: action.users.getProfileList.total
      };
    case userConstants.GET_USER_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ADDRESS_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADDRESS_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userAddressItems: action.users.getAddressByUserId,
        Total: action.users.getAddressByUserId.total,
      };

    case userConstants.GET_ADDRESS_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_WEBSITE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_WEBSITE_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getUserWebsiteIdByUserId: action.users.getUserWebsiteIdByUserId.list,
        getUserWebsiteIdByUserIdTotal: action.users.getUserWebsiteIdByUserId.total,
      };

    case userConstants.GET_WEBSITE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GETALL_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userActiveItems: action.users.getUserActive.list,
        userActiveTotal: action.users.getUserActive.total
      };
    case userConstants.GETALL_USER_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GETALL_USER_DEACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_DEACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUserDeactive.list,
        total: action.users.getUserDeactive.total
      };
    case userConstants.GETALL_USER_DEACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.disableUser.list,
        // total: action.users.disableUser.total
      };
    case userConstants.DISABLE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.deleteUser.list,
        // total: action.users.deleteUser.total
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.verifyEmail.list,
        // total: action.users.verifyEmail.total
      };
    case userConstants.USER_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.ADD_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        loginToThisAccountItems: action.users.loginToThisAccount
        // loginToThisAccountTotal: action.users.loginToThisAccount.total
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_MATCH_TEAM_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_TEAM_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        // addUserSuccess: true,
        isMatchImageUpdate: true,
        updateMatchTeamImageItems: action.users.updateMatchTeamImage
      };
    case userConstants.UPDATE_MATCH_TEAM_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.users.getProfile
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_MATCH_MARKET_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_MATCH_MARKET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        updateMatchMarketIdItems: action.users.getProfile
      };
    case userConstants.UPDATE_MATCH_MARKET_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };

    case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };

    case userConstants.FILE_UPLOAD_STATUS_VIDEO_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetailsVideo: action.uploadImage.videoDetails
      };
    case userConstants.FILE_UPLOAD_STATUS_VIDEO_FAILURE:
      return {
        ...state
      };

    // case userConstants.GETALL_USER_NOTIFY_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.GETALL_USER_NOTIFY_SUCCESS:
    //   return {
    //     ...state,
    //     listOfNotification: action.users.listOfNotification.list,
    //     listOfNotificationtotal: action.users.listOfNotification.total
    //   };
    // case userConstants.GETALL_USER_NOTIFY_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case userConstants.UPDATE_USER_NOTIFY_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.UPDATE_USER_NOTIFY_SUCCESS:
    //   return {
    //     ...state,
    //     passwordUpdated: true
    //   };
    // case userConstants.UPDATE_USER_NOTIFY_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case userConstants.GET_RESTAURANT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.GET_RESTAURANT_SUCCESS:
    //   return {
    //     ...state,
    //     addMenuSuccess: false,
    //     addItemSuccess: false,
    //     updateitem: false,
    //     passwordUpdated: false,
    //     updateCategory: false,
    //     restaurantDetails: action.users.restaurantDetails
    //   };
    // case userConstants.GET_RESTAURANT_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    // case userConstants. :
    //   return {
    //     ...state
    //   };
    // case userConstants.ADD_RESTAURANT_USER_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case userConstants.ADD_RESTAURANT_USER_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    // case userConstants.ADD_MENU_REQUEST:
    //   return {
    //     ...state
    //   };
    // case userConstants.ADD_MENU_SUCCESS:
    //   return {
    //     ...state,
    //     addMenuSuccess: true
    //   };
    // case userConstants.ADD_MENU_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    // case userConstants.ADD_ITEM_REQUEST:
    //   return {
    //     ...state
    //   };
    // case userConstants.ADD_ITEM_SUCCESS:
    //   return {
    //     ...state,
    //     addItemSuccess: true
    //   };
    // case userConstants.ADD_ITEM_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    // case userConstants.UPDATE_CATEGORY_REQUEST:
    //   return {
    //     ...state
    //   };
    // case userConstants.UPDATE_CATEGORY_SUCCESS:
    //   return {
    //     ...state,
    //     updateCategory: true
    //   };
    // case userConstants.UPDATE_CATEGORY_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    // case userConstants.UPDATE_ITEM_REQUEST:
    //   return {
    //     ...state
    //   };
    // case userConstants.UPDATE_ITEM_SUCCESS:
    //   return {
    //     ...state,
    //     updateitem: true
    //   };
    // case userConstants.UPDATE_ITEM_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    // case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
    //   return {
    //     ...state, filesDetails: action.uploadImage.filesDetails,
    //   };

    // case userConstants.FILE_UPLOAD_STATUS_FAILURE:
    //   return {
    //     ...state
    //   };


    // case userConstants.STATS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.STATS_SUCCESS:
    //   return {
    //     ...state,
    //     statics: action.users.statics
    //   };
    // case userConstants.STATS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    default:
      return state
  }
}