import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { toast } from 'react-toastify';
import { users } from '../_reducers/users.reducer';
export const userActions = {
    getProfileList,
    getProfileByUserId,
    getUserByUserId,
    getAllSeries,
    getAllMatch,
    createSport,
    createSeries,
    createMatch,
    getProfile,
    adminLogin,
    logout,
    updateUserPasswordSubmit,
    registerUserAdmin,
    getUserActive,
    getUserDeactive,
    disableUser,
    deleteUser,
    verifyEmail,
    loginToThisAccount,
    getAllMarket,
    getAllMatchActive,
    updateMatchStatus,
    uploadImage,
    updateMatchMarketId,
    getOdds,
    getMatchBymatchId,
    updateMatchTeamImage,
    createMarket,
    uploadVideo,
    getUserPaymentDataByUIdOrPId,
    getAddressByUserId,
    getUserWebsiteIdByUserId,
    getAllPaymentType,
    createOrUpdateAppSetting,
    getAppSetting,
    changePasswordAdmin

};


function getAllPaymentType(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllPaymentType(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_PAYMENT_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_PAYMENT_FAILURE, error } }
}

function getUserPaymentDataByUIdOrPId(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserPaymentDataByUIdOrPId(data)
            .then(
                users => {
                    console.log('users____________???????', users);
                    dispatch(success(users))
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PAYMENT_BY_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_PAYMENT_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PAYMENT_BY_ID_FAILURE, error } }
}

function createOrUpdateAppSetting(data) {
    return dispatch => {
        dispatch(request());
        userService.createOrUpdateAppSetting(data)
            .then(
                users => {
                    console.log('users____________???????', users);
                    dispatch(alertActions.success('Update Successfully'));
                    dispatch(this.getAppSetting());
                    dispatch(success(users))

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_OR_UPDATE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_OR_UPDATE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_OR_UPDATE_APP_SETTING_FAILURE, error } }
}

function changePasswordAdmin(data) {
    return dispatch => {
        dispatch(request());
        userService.changePasswordAdmin(data)
            .then(
                users => {
                    console.log('users____________???????', users);
                    dispatch(alertActions.success('Update Successfully'));
                    dispatch(this.getAppSetting());
                    dispatch(success(users))

                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHANGE_ADMIN_PASS_REQUEST } }
    function success(users) { return { type: userConstants.CHANGE_ADMIN_PASS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHANGE_ADMIN_PASS_FAILURE, error } }
}

function getAppSetting(data) {
    return dispatch => {
        dispatch(request());
        userService.getAppSetting(data)
            .then(
                users => {
                    console.log('users____________???????', users);
                    dispatch(success(users))
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.GET_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_APP_SETTING_FAILURE, error } }
}

function getUserWebsiteIdByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserWebsiteIdByUserId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_WEBSITE_BY_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_WEBSITE_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_WEBSITE_BY_ID_FAILURE, error } }
}

function getAddressByUserId(data) {
    return dispatch => {
        dispatch(request());
        userService.getAddressByUserId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ADDRESS_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_ADDRESS_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ADDRESS_BY_USER_FAILURE, error } }
}

function createMarket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.createMarket(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMarket(data));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_MARKET_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_MARKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_MARKET_FAILURE, error } }
}

function createMatch(data, tempData) {
    console.log("tempData:::", tempData);
    return dispatch => {
        dispatch(request());
        userService.createMatch(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMatch(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.ADD_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_MATCH_FAILURE, error } }
}
function updateMatchMarketId(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.updateMatchMarketId(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatch(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_MARKET_ID_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_MARKET_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_MARKET_ID_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}

function uploadVideo(data) {
    return dispatch => {
        userService.uploadVideo(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully2222222222222.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_VIDEO_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_VIDEO_FAILURE, error } }

}

function createSeries(data) {
    return dispatch => {
        dispatch(request());
        userService.createSeries(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllSeries(data.sportId));
                },
                error => {
                    dispatch(alertActions.error(error));

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_SERIES_FAILURE, error } }
}
function updateMatchStatus(data) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateMatchStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_STATUS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_STATUS_FAILURE, error } }
}
function createSport(data) {
    return dispatch => {
        dispatch(request());
        userService.createSport(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_SPORT_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_SPORT_FAILURE, error } }
}

function getAllMarket(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMarket(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MARKET_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MARKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MARKET_FAILURE, error } }
}
function updateMatchTeamImage(data) {
    return dispatch => {
        dispatch(request());
        userService.updateMatchTeamImage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_TEAM_IMAGE_FAILURE, error } }
}
function getAllMatchActive(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatchActive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MATCH_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MATCH_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MATCH_ACTIVE_FAILURE, error } }
}
function getAllMatch(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatch(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MATCH_FAILURE, error } }
}

function getAllSeries(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllSeries(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_SERIES_FAILURE, error } }
}
function getOdds(data) {
    return dispatch => {
        // dispatch(request());
        userService.getOdds(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    // function request() { return { type: userConstants.GET_ODDS_REQUEST } }
    function success(users) { return { type: userConstants.GET_ODDS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ODDS_FAILURE, error } }
}
function getMatchBymatchId(data) {
    return dispatch => {
        dispatch(request());
        userService.getMatchBymatchId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MATCH_BY_MATCHID_REQUEST } }
    function success(users) { return { type: userConstants.GET_MATCH_BY_MATCHID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MATCH_BY_MATCHID_FAILURE, error } }
}

function getProfileList(data) {
    console.log("******************", data);
    return dispatch => {
        dispatch(request());
        userService.getProfileList(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$$$$$$$$$", users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_LIST_FAILURE, error } }
}

function getProfileByUserId(data) {
    console.log("******************", data);
    return dispatch => {
        dispatch(request());
        userService.getProfileByUserId(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$$$$$$$$$", users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_BY_USER_FAILURE, error } }
}
function getUserByUserId(data) {
    console.log("******************", data);
    return dispatch => {
        dispatch(request());
        userService.getUserByUserId(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$$$$$$$$$", users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_BY_USER_FAILURE, error } }
}


function adminLogin(data, props) {

    console.log("dataACTION:::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", data);

    return dispatch => {
        dispatch(request({ data }));
        userService.adminLogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/useractive' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function verifyEmail(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.verifyEmail(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_VERIFY_EMAIL_REQUEST } }
    function success(users) { return { type: userConstants.USER_VERIFY_EMAIL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_VERIFY_EMAIL_FAILURE, error } }
}
function deleteUser(data, paginationFilter) {

    return dispatch => {
        dispatch(request());
        userService.deleteUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationFilter));
                    dispatch(alertActions.success("Delete Successfully"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_USER_FAILURE, error } }
}
function disableUser(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.disableUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));
                    dispatch(alertActions.error("Status Update Successfully"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_USER_FAILURE, error } }
}
function getUserActive(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserActive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_ACTIVE_FAILURE, error } }
}
function getUserDeactive(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserDeactive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_DEACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_DEACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_DEACTIVE_FAILURE, error } }
}

function registerUserAdmin(data, reqData) {
    return dispatch => {
        dispatch(request());
        userService.registerUserAdmin(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("User Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserActive(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_USER_FAILURE, error } }
}
function updateUserPasswordSubmit(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        userService.updateUserPasswordSubmit(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_PASSWORD_FAILURE, error } }
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.loginToThisAccount(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ loginToThisAccount $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST } }
    function success(users) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE, error } }
}

function getProfile(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getProfile(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getProfile $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}


// function getRestaurant(data) {
//     return dispatch => {
//         dispatch(request());
//         userService.getRestaurant(data)
//             .then(
//                 users => dispatch(success(users)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.GET_RESTAURANT_REQUEST } }
//     function success(users) { return { type: userConstants.GET_RESTAURANT_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GET_RESTAURANT_FAILURE, error } }
// }
// function getAllNotification(data) {
//     return dispatch => {
//         dispatch(request());
//         userService.getAllNotification(data)
//             .then(
//                 users => dispatch(success(users)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.GETALL_USER_NOTIFY_REQUEST } }
//     function success(users) { return { type: userConstants.GETALL_USER_NOTIFY_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GETALL_USER_NOTIFY_FAILURE, error } }
// }
// function updateNotificationStatus(data) {
//     let tempdata = {
//         id: data.id
//     }
//     let listdata = {
//         "pageNo": data.pageNo,
//         "size": data.size
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.updateNotificationStatus(tempdata)
//             .then(
//                 users => {
//                     dispatch(success(users))
//                     dispatch(this.getAllNotification(listdata));

//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
//     function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
// }

// function updatePasswordUser(data) {
//     let tempdata = {
//         id: data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.updatePasswordUser(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getRestaurant(tempdata));
//                     dispatch(alertActions.success("Password updated successfully."));

//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
//     function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
// }
// function sendFrom(data) {
//     let tempdata = {
//         id: data.id
//     }
//     // let senttempdata={
//     //     address:data.address,
//     //     amount:data.amount,
//     // }
//     return dispatch => {
//         dispatch(request());
//         userService.sendFrom(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getRestaurant(tempdata));
//                     dispatch(alertActions.success("Sent successfully."));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
//     function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
// }
// function createNewUser(data) {
//     let tempdata = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.createNewUser(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getAllRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
//     function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
// }
// function deleteUser(data) {
//     let tempdata = {
//         "keyWord": data.keyWord,
//         "pageNo": data.pageNo,
//         "size": data.size
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.deleteUser({id:data.id})
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getAllRestaurant(tempdata));
//                     dispatch(alertActions.success("Deleted successfully."));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
//     function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
// }
// function addMenu(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.addMenu(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.ADD_MENU_REQUEST } }
//     function success(users) { return { type: userConstants.ADD_MENU_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.ADD_MENU_FAILURE, error } }
// }
// function addItem(data, id) {
//     let tempdata = {
//         "restaurantId": id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.addItem(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.ADD_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.ADD_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.ADD_ITEM_FAILURE, error } }
// }
// function uploadImage(data) {
//     return dispatch => {
//         userService.uploadImage(data)
//             .then(
//                 uploadImage => {
//                     toast("Image Uploaded successfully.")
//                     dispatch(success(uploadImage));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
//     function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
// }

// function onChangeImageFile(data, id) {
//     return dispatch => {
//         userService.onChangeImageFile(data, id)
//             .then(
//                 uploadImage => {
//                     dispatch(success(uploadImage));
//                     let data = {
//                         restaurantId: id
//                     }
//                     toast("Image Uploaded successfully.")
//                     dispatch(this.getRestaurant(data));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
//     function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
// }

// function onChangeImageFileLogo(data, id) {
//     return dispatch => {
//         userService.onChangeImageFileLogo(data, id)
//             .then(
//                 uploadImage => {
//                     dispatch(success(uploadImage));
//                     let data = {
//                         restaurantId: id
//                     }
//                     toast("Logo udpated successfully.")
//                     dispatch(this.getRestaurant(data));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
//     function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
// }
// function addOrRemoveImageInRestaurant(data) {
//     let tempdata = {
//         "restaurantId": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.addOrRemoveImageInRestaurant(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Image deleted successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.ADD_MENU_REQUEST } }
//     function success(users) { return { type: userConstants.ADD_MENU_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.ADD_MENU_FAILURE, error } }
// }
// function deleteItem(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.deleteItem(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Item deleted successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }

// function statics(data) {
//     return dispatch => {
//         dispatch(request());
//         userService.statics()
//             .then(
//                 users => {
//                     dispatch(success(users));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.STATS_REQUEST } }
//     function success(users) { return { type: userConstants.STATS_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.STATS_FAILURE, error } }
// }
// function disableItem(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.disableItem(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Item disabled successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }

// function deleteRestaurant(data) {
//     let tempdata = {
//         "keyWord": data.keyWord,
//         "pageNo": data.pageNo,
//         "size": data.size
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.deleteRestaurant(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Verification status udpated successfully.")
//                     dispatch(this.getAllRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }

// function disableRestaurant(data) {
//     let tempdata = {
//         "keyWord": data.keyWord,
//         "pageNo": data.pageNo,
//         "size": data.size
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.disableRestaurant(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     console.log("users  ", users);
//                     toast("Disable status updated successfully.")
//                     dispatch(this.getAllRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }


// function disableCategory(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.disableCategory(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Category status update successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }

// function updateRestaurant(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.updateRestaurant(data)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Restaurant updated successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }


// function deleteCategory(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     let maindata = {
//         "id": data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.deleteCategory(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Restaurant deleted successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.DEL_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.DEL_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.DEL_ITEM_FAILURE, error } }
// }

// function updateCategory(data) {
//     let tempdata = {
//         "restaurantId": data.restaurantId
//     }
//     let maindata = {
//         "name": data.name,
//         "menuId": data.menuId,
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.updateCategory(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Category updated successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.UPDATE_CATEGORY_REQUEST } }
//     function success(users) { return { type: userConstants.UPDATE_CATEGORY_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.UPDATE_CATEGORY_FAILURE, error } }
// }


// function updateItem(data, id) {
//     let tempdata = {
//         "restaurantId": id
//     }
//     let maindata = {
//         "id": data.id,
//         "title": data.title,
//         "desc": data.desc,
//         "price": data.price,
//         "logo": data.logo,
//         "menuId": data.menuId,
//         "availibility": data.availibility,
//     }
//     return dispatch => {
//         dispatch(request());
//         userService.updateItem(maindata)
//             .then(
//                 users => {
//                     dispatch(success(users));
//                     toast("Item updated successfully.")
//                     dispatch(this.getRestaurant(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.UPDATE_ITEM_REQUEST } }
//     function success(users) { return { type: userConstants.UPDATE_ITEM_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.UPDATE_ITEM_FAILURE, error } }
// }