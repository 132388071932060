export const matchesInfoConstants = {

    MATCHES_BY_CS_ID_REQUEST: 'MATCHES_BY_CS_ID_REQUEST',
    MATCHES_BY_CS_ID_SUCCESS: 'MATCHES_BY_CS_ID_SUCCESS',
    MATCHES_BY_CS_ID_FAILURE: 'MATCHES_BY_CS_ID_FAILURE',

    GETALL_MATCHES_INFO_REQUEST: 'GETALL_MATCHES_INFO_REQUEST',
    GETALL_MATCHES_INFO_SUCCESS: 'GETALL_MATCHES_INFO_SUCCESS',
    GETALL_MATCHES_INFO_FAILURE: 'GETALL_MATCHES_INFO_FAILURE',

    GET_LIST_MATCHES_INFO_REQUEST: 'GET_LIST_MATCHES_INFO_REQUEST',
    GET_LIST_MATCHES_INFO_SUCCESS: 'GET_LIST_MATCHES_INFO_SUCCESS',
    GET_LIST_MATCHES_INFO_FAILURE: 'GET_LIST_MATCHES_INFO_FAILURE',

    ADD_MATCHES_INFO_REQUEST: 'ADD_MATCHES_INFO_REQUEST',
    ADD_MATCHES_INFO_SUCCESS: 'ADD_MATCHES_INFO_SUCCESS',
    ADD_MATCHES_INFO_FAILURE: 'ADD_MATCHES_INFO_FAILURE',

    DELETE_MATCHES_INFO_REQUEST: 'DELETE_MATCHES_INFO_REQUEST',
    DELETE_MATCHES_INFO_SUCCESS: 'DELETE_MATCHES_INFO_SUCCESS',
    DELETE_MATCHES_INFO_FAILURE: 'DELETE_MATCHES_INFO_FAILURE',

    UPDATE_MATCHES_INFO_REQUEST: 'UPDATE_MATCHES_INFO_REQUEST',
    UPDATE_MATCHES_INFO_SUCCESS: 'UPDATE_MATCHES_INFO_SUCCESS',
    UPDATE_MATCHES_INFO_FAILURE: 'UPDATE_MATCHES_INFO_FAILURE',

    DISABLE_MATCHES_INFO_REQUEST: 'DISABLE_MATCHES_INFO_REQUEST',
    DISABLE_MATCHES_INFO_SUCCESS: 'DISABLE_MATCHES_INFO_SUCCESS',
    DISABLE_MATCHES_INFO_FAILURE: 'DISABLE_MATCHES_INFO_FAILURE',

};
