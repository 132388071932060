export const gameMarketAdminConstants = {

    GETALL_GAME_MARKET_ADMIN_REQUEST: 'GETALL_GAME_MARKET_ADMIN_REQUEST',
    GETALL_GAME_MARKET_ADMIN_SUCCESS: 'GETALL_GAME_MARKET_ADMIN_SUCCESS',
    GETALL_GAME_MARKET_ADMIN_FAILURE: 'GETALL_GAME_MARKET_ADMIN_FAILURE',

    GET_LIST_GAME_MARKET_ADMIN_REQUEST: 'GET_LIST_GAME_MARKET_ADMIN_REQUEST',
    GET_LIST_GAME_MARKET_ADMIN_SUCCESS: 'GET_LIST_GAME_MARKET_ADMIN_SUCCESS',
    GET_LIST_GAME_MARKET_ADMIN_FAILURE: 'GET_LIST_GAME_MARKET_ADMIN_FAILURE',

    ADD_GAME_MARKET_ADMIN_REQUEST: 'ADD_GAME_MARKET_ADMIN_REQUEST',
    ADD_GAME_MARKET_ADMIN_SUCCESS: 'ADD_GAME_MARKET_ADMIN_SUCCESS',
    ADD_GAME_MARKET_ADMIN_FAILURE: 'ADD_GAME_MARKET_ADMIN_FAILURE',

    DELETE_GAME_MARKET_ADMIN_REQUEST: 'DELETE_GAME_MARKET_ADMIN_REQUEST',
    DELETE_GAME_MARKET_ADMIN_SUCCESS: 'DELETE_GAME_MARKET_ADMIN_SUCCESS',
    DELETE_GAME_MARKET_ADMIN_FAILURE: 'DELETE_GAME_MARKET_ADMIN_FAILURE',

    UPDATE_GAME_MARKET_ADMIN_REQUEST: 'UPDATE_GAME_MARKET_ADMIN_REQUEST',
    UPDATE_GAME_MARKET_ADMIN_SUCCESS: 'UPDATE_GAME_MARKET_ADMIN_SUCCESS',
    UPDATE_GAME_MARKET_ADMIN_FAILURE: 'UPDATE_GAME_MARKET_ADMIN_FAILURE',

    DISABLE_GAME_MARKET_ADMIN_REQUEST: 'DISABLE_GAME_MARKET_ADMIN_REQUEST',
    DISABLE_GAME_MARKET_ADMIN_SUCCESS: 'DISABLE_GAME_MARKET_ADMIN_SUCCESS',
    DISABLE_GAME_MARKET_ADMIN_FAILURE: 'DISABLE_GAME_MARKET_ADMIN_FAILURE',

};
