export const playing11Constants = {

    GET_PLAYER_BY_MATCH_ID_REQUEST: 'GET_PLAYER_BY_MATCH_ID_REQUEST',
    GET_PLAYER_BY_MATCH_ID_SUCCESS: 'GET_PLAYER_BY_MATCH_ID_SUCCESS',
    GET_PLAYER_BY_MATCH_ID_FAILURE: 'GET_PLAYER_BY_MATCH_ID_FAILURE',
    
    GETALL_PLAYING11_REQUEST: 'GETALL_PLAYING11_REQUEST',
    GETALL_PLAYING11_SUCCESS: 'GETALL_PLAYING11_SUCCESS',
    GETALL_PLAYING11_FAILURE: 'GETALL_PLAYING11_FAILURE',

    GET_LIST_PLAYING11_REQUEST: 'GET_LIST_PLAYING11_REQUEST',
    GET_LIST_PLAYING11_SUCCESS: 'GET_LIST_PLAYING11_SUCCESS',
    GET_LIST_PLAYING11_FAILURE: 'GET_LIST_PLAYING11_FAILURE',

    ADD_PLAYING11_REQUEST: 'ADD_PLAYING11_REQUEST',
    ADD_PLAYING11_SUCCESS: 'ADD_PLAYING11_SUCCESS',
    ADD_PLAYING11_FAILURE: 'ADD_PLAYING11_FAILURE',

    DELETE_PLAYING11_REQUEST: 'DELETE_PLAYING11_REQUEST',
    DELETE_PLAYING11_SUCCESS: 'DELETE_PLAYING11_SUCCESS',
    DELETE_PLAYING11_FAILURE: 'DELETE_PLAYING11_FAILURE',

    UPDATE_PLAYING11_REQUEST: 'UPDATE_PLAYING11_REQUEST',
    UPDATE_PLAYING11_SUCCESS: 'UPDATE_PLAYING11_SUCCESS',
    UPDATE_PLAYING11_FAILURE: 'UPDATE_PLAYING11_FAILURE',

    DISABLE_PLAYING11_REQUEST: 'DISABLE_PLAYING11_REQUEST',
    DISABLE_PLAYING11_SUCCESS: 'DISABLE_PLAYING11_SUCCESS',
    DISABLE_PLAYING11_FAILURE: 'DISABLE_PLAYING11_FAILURE',

};
