export const sliderConstants = {

    GETALL_SLIDER_REQUEST: 'GETALL_SLIDER_REQUEST',
    GETALL_SLIDER_SUCCESS: 'GETALL_SLIDER_SUCCESS',
    GETALL_SLIDER_FAILURE: 'GETALL_SLIDER_FAILURE',

    GET_LIST_SLIDER_REQUEST: 'GET_LIST_SLIDER_REQUEST',
    GET_LIST_SLIDER_SUCCESS: 'GET_LIST_SLIDER_SUCCESS',
    GET_LIST_SLIDER_FAILURE: 'GET_LIST_SLIDER_FAILURE',

    GET_REACHOUT_LIST_REQUEST: 'GET_REACHOUT_LIST_REQUEST',
    GET_REACHOUT_LIST_SUCCESS: 'GET_REACHOUT_LIST_SUCCESS',
    GET_REACHOUT_LIST_FAILURE: 'GET_REACHOUT_LIST_FAILURE',

    ADD_SLIDER_REQUEST: 'ADD_SLIDER_REQUEST',
    ADD_SLIDER_SUCCESS: 'ADD_SLIDER_SUCCESS',
    ADD_SLIDER_FAILURE: 'ADD_SLIDER_FAILURE',

    NOTIFY_REQUEST: 'NOTIFY_REQUEST',
    NOTIFY_SUCCESS: 'NOTIFY_SUCCESS',
    NOTIFY_FAILURE: 'NOTIFY_FAILURE',

    DELETE_SLIDER_REQUEST: 'DELETE_SLIDER_REQUEST',
    DELETE_SLIDER_SUCCESS: 'DELETE_SLIDER_SUCCESS',
    DELETE_SLIDER_FAILURE: 'DELETE_SLIDER_FAILURE',

    DELETE_REACHOUT_REQUEST: 'DELETE_REACHOUT_REQUEST',
    DELETE_REACHOUT_SUCCESS: 'DELETE_REACHOUT_SUCCESS',
    DELETE_REACHOUT_FAILURE: 'DELETE_REACHOUT_FAILURE',

    
    DISABLE_REACHOUT_REQUEST: 'DISABLE_REACHOUT_REQUEST',
    DISABLE_REACHOUT_SUCCESS: 'DISABLE_REACHOUT_SUCCESS',
    DISABLE_REACHOUT_FAILURE: 'DISABLE_REACHOUT_FAILURE',

    UPDATE_SLIDER_REQUEST: 'UPDATE_SLIDER_REQUEST',
    UPDATE_SLIDER_SUCCESS: 'UPDATE_SLIDER_SUCCESS',
    UPDATE_SLIDER_FAILURE: 'UPDATE_SLIDER_FAILURE',


    DISABLE_SLIDER_REQUEST: 'DISABLE_SLIDER_REQUEST',
    DISABLE_SLIDER_SUCCESS: 'DISABLE_SLIDER_SUCCESS',
    DISABLE_SLIDER_FAILURE: 'DISABLE_SLIDER_FAILURE',

};
