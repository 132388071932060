import { userConstants, blogConstants } from '../_constants';
import { userService, blogService } from '../_services';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const blogActions = {

    login,
    logout,
    disableBlog,
    getAllBlog,
    createBlog,
    updateBlog,
    getBlogList,
    deleteBlog,
    // getAllCategory,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


function createBlog(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        blogService.createBlog(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Blog Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBlogList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.ADD_BLOG_REQUEST } }
    function success(users) { return { type: blogConstants.ADD_BLOG_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.ADD_BLOG_FAILURE, error } }
}
function updateBlog(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        blogService.updateBlog(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Blog Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBlogList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.UPDATE_BLOG_REQUEST } }
    function success(users) { return { type: blogConstants.UPDATE_BLOG_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.UPDATE_BLOG_FAILURE, error } }
}
function getAllBlog(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        blogService.getAllBlog(data)
            .then(
                users => {
                    console.log("getAllBlog $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.GETALL_BLOG_REQUEST } }
    function success(users) { return { type: blogConstants.GETALL_BLOG_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.GETALL_BLOG_FAILURE, error } }
}
function getBlogList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        blogService.getBlogList(data)
            .then(
                users => {
                    console.log("getBlogList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.GET_BLOG_LIST_REQUEST } }
    function success(users) { return { type: blogConstants.GET_BLOG_LIST_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.GET_BLOG_LIST_FAILURE, error } }
}

function deleteBlog(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        blogService.deleteBlog(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getBlogList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.DELETE_BLOG_REQUEST } }
    function success(users) { return { type: blogConstants.DELETE_BLOG_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.DELETE_BLOG_FAILURE, error } }
}
function disableBlog(data, paginationData) {

    return dispatch => {
        dispatch(request());
        blogService.disableBlog(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getBlogList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: blogConstants.DISABLE_BLOG_REQUEST } }
    function success(users) { return { type: blogConstants.DISABLE_BLOG_SUCCESS, users } }
    function failure(error) { return { type: blogConstants.DISABLE_BLOG_FAILURE, error } }
}
