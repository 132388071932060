import {
  sliderConstants, paymentTypeConstants
} from '../_constants';

export function paymentType(state = {}, action) {

  switch (action.type) {
    case paymentTypeConstants.ADD_PAYMENT_REQUEST:
      return {
        ...state
      };
    case paymentTypeConstants.ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case paymentTypeConstants.ADD_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case paymentTypeConstants.GET_LIST_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case paymentTypeConstants.GET_LIST_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        getPaymentList: action.users.getPaymentList.list,
        total: action.users.getPaymentList.total
      };
    case paymentTypeConstants.GET_LIST_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sliderConstants.GET_REACHOUT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GET_REACHOUT_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        reachOutItems: action.users.getReachOutList.list,
        reachOutTotal: action.users.getReachOutList.total
      };
    case sliderConstants.GET_REACHOUT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case sliderConstants.GETALL_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.GETALL_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllMarket,
      };
    case sliderConstants.GETALL_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case paymentTypeConstants.UPDATE_PAYMENT_REQUEST:
      return {
        ...state
      };
    case paymentTypeConstants.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case paymentTypeConstants.UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case paymentTypeConstants.DELETE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case paymentTypeConstants.DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case paymentTypeConstants.DELETE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case paymentTypeConstants.DISABLE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case paymentTypeConstants.DISABLE_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case paymentTypeConstants.DISABLE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      
    case sliderConstants.DELETE_REACHOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DELETE_REACHOUT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DELETE_REACHOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case sliderConstants.DISABLE_REACHOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sliderConstants.DISABLE_REACHOUT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case sliderConstants.DISABLE_REACHOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}