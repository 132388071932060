import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fancyActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddFancyModal from "./components/CreateAddFancyModal/CreateAddFancyModal";
import UpdateFancyModal from "./components/UpdateFancyModal/UpdateFancyModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class Fancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      fancyRowData: {},
      fieldsFancy: {},
      errorsFancy: {},
      fieldsFancyUpdate: {},
      errorsUpdateFancy: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateFancyCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(fancyActions.getFancyByMatchId(data));
    // this.props.dispatch(fancyActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fancy.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsFancy: {},
        errorsFancy: {},
        addUserCreateModal: false,
        UpdateFancyCreateModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(fancyActions.getFancyList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(fancyActions.getFancyList(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(fancyActions.disableFancy(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteFancy = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(fancyActions.deleteFancy(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(fancyActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //   "id": data.id
    // }
    // this.props.dispatch(fancyActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, fancyRowData: data });
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateFancyCreateModal: true, fieldsFancyUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateFancyCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(fancyActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsFancy = this.state.fieldsFancy;
    let errorsFancy = this.state.errorsFancy;
    fieldsFancy[name] = value;
    console.log(name, value);
    errorsFancy[name] = "";
    this.setState({ fieldsFancy, errorsFancy });
  }
  inputChangeUpdateFancy = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsFancyUpdate = this.state.fieldsFancyUpdate;
    let errorsUpdateFancy = this.state.errorsUpdateFancy;
    fieldsFancyUpdate[name] = value;
    errorsUpdateFancy[name] = "";
    console.log(name, value);
    this.setState({ fieldsFancyUpdate, errorsUpdateFancy });
  }

  createFancySubmit = () => {

    // let { users } = this.props;


    // let { filesDetails } = users;

    if (this.handleValidationAddUser()) {
      let reqData = {
        "match_id": this.state.fieldsFancy.match_id,
        "fancy": this.state.fieldsFancy.fancy,
        "created": this.state.fieldsFancy.created,
        "back_price": this.state.fieldsFancy.back_price,
        "back_size": this.state.fieldsFancy.back_size,
        "lay_price": this.state.fieldsFancy.lay_price,

      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(fancyActions.createFancy(reqData));
    }

  }


  updateFancySubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateFancy()) {
      let reqData = {
        "id": this.state.fieldsFancyUpdate.id,
        "title": this.state.fieldsFancyUpdate.title,
        "url": this.state.fieldsFancyUpdate.url,
        "description": this.state.fieldsFancyUpdate.description,
        "isVideo": this.state.isVideoUpdate,
        // filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsFancyUpdate.image

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(fancyActions.updateFancy(reqData, paginationData));
    }

  }
  handleValidationUpdateFancy = () => {
    let fieldsFancyUpdate = this.state.fieldsFancyUpdate;
    let errorsUpdateFancy = {};
    let formIsValid = true;

    //title
    if (!fieldsFancyUpdate["title"] || fieldsFancyUpdate["title"] === "") {
      formIsValid = false;
      errorsUpdateFancy["title"] = "Cannot be empty";
    }

    //url
    if (!fieldsFancyUpdate["url"] || fieldsFancyUpdate["url"] === "") {
      formIsValid = false;
      errorsUpdateFancy["url"] = "Cannot be empty";
    }

    //description
    if (!fieldsFancyUpdate["description"] || fieldsFancyUpdate["description"] === "") {
      formIsValid = false;
      errorsUpdateFancy["description"] = "Cannot be empty";
    }

    this.setState({ errorsUpdateFancy: errorsUpdateFancy });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsFancy = this.state.fieldsFancy;
    let errorsFancy = {};
    let formIsValid = true;

    //title
    if (!fieldsFancy["title"] || fieldsFancy["title"] === "") {
      formIsValid = false;
      errorsFancy["title"] = "Cannot be empty title";
    }
    //url
    if (!fieldsFancy["url"] || fieldsFancy["url"] === "") {
      formIsValid = false;
      errorsFancy["url"] = "Cannot be empty url";
    }
    //description
    if (!fieldsFancy["description"] || fieldsFancy["description"] === "") {
      formIsValid = false;
      errorsFancy["description"] = "Cannot be empty description";
    }

    this.setState({ errorsFancy: errorsFancy });
    return formIsValid;
  }

  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleSelectGameType = (e) => {
    console.log("handleSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleFile2 = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadVideo(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }



  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }

  render() {

    let { fancy } = this.props;
    let { items, total, loading } = fancy;
    // console.log("render___handleSelectRankType", this.state.type);
    // console.log("render___handleSelectRankType", this.state.gameType);
    // console.log("render___handleSelectRankType", this.state.rankType);

    // let { filesDetails, filesDetailsVideo } = users;

    console.log("render______items::::", items);
    // console.log("render______filesDetailsVideo::::", filesDetailsVideo);

    // console.log("render______this.state.isVideo::::", this.state.isVideo);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="bg-white border rounded-md  p-6 space-y-6">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Fancy</h3>
                    </div>



                    {/* <div className="relative mt-5 lg:mt-0">
                      <div className="absolute text-gray-600 top-4 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="w-full px-3 py-3 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-gray-200 border rounded-full appearance-none sm:w-64 border-dark-400 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div> */}

                    {/* <div className="flex justify-end ...">
                      <button className="flex justify-end py-1 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7 "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div> */}

                  </div>
                  <div className="pb-2 mt-4 overflow-hidden">
                    <div className="max-w-full ">
                      <div className="inline-block min-w-full">
                        <form autoComplete="off">
                          <div class="px-8 py-3 font-sans">

                            <div class="grid md:grid-cols-2 gap-10">

                              <div class=" space-y-2   rounded flex flex-col">
                                <label >Match Id</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["match_id"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="match_id" name="match_id" placeholder="match_id" value={this.state.fieldsFancy.match_id} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["match_id"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["match_id"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Fancy</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["fancy"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="fancy" name="fancy" placeholder="fancy" value={this.state.fieldsFancy.fancy} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["fancy"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["fancy"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Created</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["created"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="created" name="created" placeholder="created" value={this.state.fieldsFancy.created} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["created"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["created"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Back Price</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["back_price"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="back_price" name="back_price" placeholder="back_price" value={this.state.fieldsFancy.back_price} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["back_price"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["back_price"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Back Size</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["back_size"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="back_size" name="back_size" placeholder="back_size" value={this.state.fieldsFancy.back_size} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["back_size"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["back_size"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Lay Price</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["lay_price"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="lay_price" name="lay_price" placeholder="lay_price" value={this.state.fieldsFancy.lay_price} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["lay_price"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["lay_price"]}
                                  </div>
                                  : null}

                              </div>

                              <div class=" space-y-2   rounded flex flex-col">
                                <label>Lay Size</label>
                                <input className={`px-6 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsFancy && !this.state.errorsFancy["lay_size"] ? "border placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="lay_size" name="lay_size" placeholder="lay_size" value={this.state.fieldsFancy.lay_size} type="text" onChange={this.inputAddUserChange} />

                                {this.state.errorsFancy && this.state.errorsFancy["lay_size"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsFancy["lay_size"]}
                                  </div>
                                  : null}

                              </div>
                            </div>
                          </div>
                          {/* <div className="flex flex-wrap w-full ">
                            <label htmlFor="image">
                              Upload TEAM_A Image
                            </label>
                            <input
                              style={{ display: 'none' }}
                              id="image"
                              name="image"
                              type="file"
                              onChange={this.handleFile}
                            />
                          </div>

                          <div className="flex flex-wrap w-full ">
                            <label htmlFor="image">
                              Upload TEAM_B Image
                            </label>
                            <input
                              style={{ display: 'none' }}
                              id="image"
                              name="image"
                              type="file"
                              onChange={this.handleFile}
                            />
                          </div> */}


                          <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                            <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button"
                            //  onClick={createInfoSubmit}
                            >Submit</button>
                          </div>
                        </form>

                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddFancyModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsFancy={this.state.fieldsFancy}
          errorsFancy={this.state.errorsFancy}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createFancySubmit={this.createFancySubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          isVideo={this.state.isVideo}
        />

        <UpdateFancyModal
          UpdateFancyCreateModal={this.state.UpdateFancyCreateModal}
          fieldsFancyUpdate={this.state.fieldsFancyUpdate}
          errorsUpdateFancy={this.state.errorsUpdateFancy}
          inputChangeUpdateFancy={this.inputChangeUpdateFancy}
          updateFancySubmit={this.updateFancySubmit}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}

        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          fancyRowData={this.state.fancyRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        {/* <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        /> */}
      </>

    );
  }
}
function mapStateToProps(state) {
  const { fancy, users } = state;
  return {
    fancy,
    users
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Fancy);
