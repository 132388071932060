import {
  userConstants, rankingConstants
} from '../_constants';

export function ranking(state = {}, action) {

  switch (action.type) {
    case rankingConstants.ADD_RANKING_REQUEST:
      return {
        ...state
      };
    case rankingConstants.ADD_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case rankingConstants.ADD_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case rankingConstants.GET_LIST_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case rankingConstants.GET_LIST_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getRankingList.list,
        total: action.users.getRankingList.total
      };
    case rankingConstants.GET_LIST_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case rankingConstants.GETALL_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case rankingConstants.GETALL_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        rankingItems: action.users.getAllRanking,
        rankingTotal: action.users.getAllRanking
      };
    case rankingConstants.GETALL_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_RANKING_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_RANKING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}