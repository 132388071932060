export const gameResultConstants = {

    GETALL_GAME_RESULT_REQUEST: 'GETALL_GAME_RESULT_REQUEST',
    GETALL_GAME_RESULT_SUCCESS: 'GETALL_GAME_RESULT_SUCCESS',
    GETALL_GAME_RESULT_FAILURE: 'GETALL_GAME_RESULT_FAILURE',

    GET_LIST_GAME_RESULT_REQUEST: 'GET_LIST_GAME_RESULT_REQUEST',
    GET_LIST_GAME_RESULT_SUCCESS: 'GET_LIST_GAME_RESULT_SUCCESS',
    GET_LIST_GAME_RESULT_FAILURE: 'GET_LIST_GAME_RESULT_FAILURE',

    ADD_GAME_RESULT_REQUEST: 'ADD_GAME_RESULT_REQUEST',
    ADD_GAME_RESULT_SUCCESS: 'ADD_GAME_RESULT_SUCCESS',
    ADD_GAME_RESULT_FAILURE: 'ADD_GAME_RESULT_FAILURE',

    DELETE_GAME_RESULT_REQUEST: 'DELETE_GAME_RESULT_REQUEST',
    DELETE_GAME_RESULT_SUCCESS: 'DELETE_GAME_RESULT_SUCCESS',
    DELETE_GAME_RESULT_FAILURE: 'DELETE_GAME_RESULT_FAILURE',

    UPDATE_GAME_RESULT_REQUEST: 'UPDATE_GAME_RESULT_REQUEST',
    UPDATE_GAME_RESULT_SUCCESS: 'UPDATE_GAME_RESULT_SUCCESS',
    UPDATE_GAME_RESULT_FAILURE: 'UPDATE_GAME_RESULT_FAILURE',

    DISABLE_GAME_RESULT_REQUEST: 'DISABLE_GAME_RESULT_REQUEST',
    DISABLE_GAME_RESULT_SUCCESS: 'DISABLE_GAME_RESULT_SUCCESS',
    DISABLE_GAME_RESULT_FAILURE: 'DISABLE_GAME_RESULT_FAILURE',

};
