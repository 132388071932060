import React from "react";

import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";


// const customStyles = {
//   content: {
//     background: '(255,255,255,0.005)',
//     border: 'none',
//   },
// };

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, updateSelectedTag, sliderRowData, options, offset, modules, formats, handleSelectedTagUpdate, categoryListItems, subCategoryListItems,
  } = props;

  console.log("RENDER_____MarketRowData::", sliderRowData);


  return (

    <Modal
      isOpen={moreDetailsCreateModal}
    >
      <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full pb-[6rem] pt-[24rem] overflow-hidden overflow-y-auto main-modal animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-1/2 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container">
          <div className="w-full px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold">View Market</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleMoreDetailsHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>

              <form autoComplete="off">
                <div className="flex justify-between w-full">


                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      disabled
                      value={sliderRowData && sliderRowData.category ? sliderRowData.category : null}>
                      <option selected>Plz Select Category</option>
                      {
                        categoryListItems && categoryListItems && categoryListItems.length > 0 ?
                          categoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                  </div>

                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Sub Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      disabled
                      value={sliderRowData && sliderRowData.subcategory ? sliderRowData.subcategory : null}>
                      <option selected>Plz Select Category</option>
                      {
                        subCategoryListItems && subCategoryListItems && subCategoryListItems.length > 0 ?
                          subCategoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                  </div>


                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">News Id</label>
                      <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                        id="Market_id" name="Market_id" placeholder="News Id" value={sliderRowData.Market_id} type="text"
                        disabled />
                    </div>
                  </div>

                </div>

                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="relative mt-1 shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Title</label>
                      <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                        id="title" name="title" placeholder="Title" value={sliderRowData.title} type="title"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Author</label>
                      <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                        id="author" name="author" placeholder="Author" value={sliderRowData.author} type="text"
                        disabled
                      />
                    </div>
                  </div>


                  <div className="w-full mr-2">
                    <div className="relative mt-1 shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Slug</label>
                      <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                        id="slug" name="slug" placeholder="Slug" value={sliderRowData.slug} type="slug"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Description</label>
                      <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                        id="descirption" name="descirption" placeholder="Description" value={sliderRowData.descirption} type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>


                <div className="flex justify-between w-full mt-2">
                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <ReactQuill
                        modules={modules}
                        formats={formats}
                        value={sliderRowData.story}
                        disabled
                      />
                    </div>
                  </div>

                </div>

                <label class="block text-gray-700 text-sm" for="username">Image</label>
                <div className="grid grid-cols-1 gap-4 mt-2 md:grid-cols-3">

                  <Multiselect
                    options={options}
                    onSelect={handleSelectedTagUpdate}
                    selected={updateSelectedTag}
                    isObject={false}
                    displayValue="label"
                    value={(sliderRowData && sliderRowData.tag) ? sliderRowData.tag.map((val) => { return val }) : ""}
                  />
                </div>

                <div className="grid grid-cols-1 gap-4 mt-2 md:grid-cols-3">
                  {
                    sliderRowData && sliderRowData.content && sliderRowData.content.length > 0 ?
                      sliderRowData.content.map((item, index) => (
                        <React.Fragment key={index}>
                          <>
                            <div>
                              {index === 0 ?
                                <button className="px-4 py-1 text-base font-semibold text-white border rounded-lg cursor-pointer bg-shine-400" type="button"
                                >Add</button>
                                :
                                <button className="px-2 py-1 text-sm font-semibold text-white bg-red-500 border rounded-lg cursor-pointer" type="button"
                                >Delete</button>
                              }
                              <label class="block text-gray-700 text-sm" for="username">Content : {offset + index + 1}</label>
                              <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border border-red-500 border-opacity-100 rounded shadow border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus ring "
                                id="content" name="content" placeholder="content" value={item}
                                type="text"
                              />
                            </div>
                          </>
                        </React.Fragment>
                      )) : null
                  }
                </div>


                <label class="block text-gray-700 text-sm" for="username">Image</label>
                <div className="grid grid-cols-1 gap-4 mt-2 md:grid-cols-2">
                  <div className="w-full mr-2">
                    {
                      sliderRowData && sliderRowData.imageUrlLink ?
                        <>

                          <div className="w-full mt-1 mr-2 ">
                            <img src={sliderRowData && sliderRowData.imageUrlLink ? sliderRowData.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mt-1 mr-2 ">
                          <img src={sliderRowData && sliderRowData.imageLinkUrl ? sliderRowData.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div >


    </Modal >


    // <>

    //   {moreDetailsCreateModal ? <div className='fixed top-0 left-0 z-40 flex items-center justify-center w-full h-screen bg-black/40'>
    //     <div className="p-6 space-y-6 w-96 h-80">
    //       <div className='flex items-center justify-between w-full'>

    //       </div>
    //       <div className="space-y-6">

    //         <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden bg-black/40 h-100 animated fadeIn faster" >
    //           <div className="z-50 w-11/12 mx-auto overflow-y-auto border-none rounded-lg shadow-lg md:max-w-md">
    //             <div className="px-6 py-4 space-y-6 text-center bg-green-700 rounded-lg ">
    //               <div>
    //                 <h1 className='font-serif text-white'>Bet Sucessful!</h1>
    //               </div>
    //               <button className='w-20 px-6 py-2 font-serif text-base text-green-600 bg-white rounded-full'>OK</button>
    //             </div>
    //           </div>
    //         </div>

    //       </div>
    //     </div>


    //   </div> : null
    //   }
    // </>




  );
}
