import {
  userConstants, bankConstants
} from '../_constants';

export function bank(state = {}, action) {
  console.log('action___________action', action);

  switch (action.type) {
    case bankConstants.GET_BANK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case bankConstants.GET_BANK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bankListItems: action.users.getBankList.list,
        bankListTotal: action.users.getBankList.total,
      };
    case bankConstants.GET_BANK_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,

      };

    case bankConstants.UPDATE_BANK_REQUEST:
      return {
        ...state
      };
    case bankConstants.UPDATE_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case bankConstants.UPDATE_BANK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case bankConstants.ADD_BANK_REQUEST:
      return {
        ...state
      };
    case bankConstants.ADD_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case bankConstants.ADD_BANK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case bankConstants.GET_LIST_BANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.GET_LIST_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getBankList.list,
        total: action.users.getBankList.list
      };
    case bankConstants.GET_LIST_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case bankConstants.GETALL_BANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bankConstants.GETALL_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        videoItems: action.users.getAllvideo,
        videoTotal: action.users.getAllvideo
      };
    case bankConstants.GETALL_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_BANK_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_BANK_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_BANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_BANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_BANK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}