import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sliderActions, userActions, paymentTypeAction } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAdd/CreateAddSlider";
import CreateUpdatePasswordDealer from "./components/CreateUpdate/CreateUpdateSlider";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"


class profileAndAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      paymentField: '',
      viewRowData: {},

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      paymentDataType: '',

      sliderContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let userId = this.props.match.params.userId;
    console.log('user_______________ID', userId);
    let data = {
      userId: userId,
      paymentTypeSlug: this.state.paymentDataType
    }
    let reqData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserPaymentDataByUIdOrPId(data));
    // this.props.dispatch(userActions.getAddressByUserId({ userId: userId }));
    this.props.dispatch(userActions.getAllPaymentType(reqData));

    console.log('datadatadatadatadatadatadata:::::::::::::::::::::::::::::::', data);

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.users.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(sliderActions.getSliderList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(sliderActions.getSliderList(data));
  }

  disableSlider = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.author} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(sliderActions.disableSlider(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(sliderActions.deleteSlider(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }

  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(sliderActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['slider_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNewsSubmit = () => {

    let { users } = this.props;


    let { filesDetails } = users;

    if (this.handleValidationAddNews()) {
      let reqData = {
        "title": this.state.fieldsAddUser.title,
        "slug": this.state.fieldsAddUser.slug,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(sliderActions.createSlider(reqData, paginationData));
    }
  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //slider_id
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["slug"] || fieldsAddUser["slug"] === "") {
      formIsValid = false;
      errorsAddUser["slug"] = "Cannot be empty";
    }
    // //gameType
    // if (!fieldsAddUser["image"] || fieldsAddUser["image"] === "") {
    //   formIsValid = false;
    //   errorsAddUser["image"] = "Cannot be empty";
    // }

    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {


    let { users } = this.props;


    let { filesDetails } = users;

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "title": this.state.fieldsUpdatePassword.title,
        "slug": this.state.fieldsUpdatePassword.slug,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(sliderActions.updateSlider(reqData, paginationData));
    }

  }


  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //gameNo
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //gameType
    if (!fieldsUpdatePassword["slug"] || fieldsUpdatePassword["slug"] === "") {
      formIsValid = false;
      errorsUpdatePassword["slug"] = "Cannot be empty";
    }
    // //gameType
    // if (!fieldsUpdatePassword["image"] || fieldsUpdatePassword["image"] === "") {
    //   formIsValid = false;
    //   errorsUpdatePassword["image"] = "Cannot be empty";
    // }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    console.log('e___________________', e.target.value);
    let data = {
      userId: this.props.match.params.userId,
      paymentTypeSlug: e.target.value
    }
    this.props.dispatch(userActions.getUserPaymentDataByUIdOrPId(data));
    this.setState({ paymentDataType: e.target.value })
    // let { value } = e.target;
    // let paymentField = this.state.paymentField;
    // paymentField["tag"][this.state.currentConetntIndex] = value;
    // console.log("paymentField***:::", paymentField);
    // this.setState({ paymentField:  });
  }


  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };

  render() {

    let { users } = this.props;
    let { profileTotal, loading } = users;
    let { payment } = users;
    let { getAllPaymentType } = users;
    let { userAddressItems } = users;

    console.log("payment22222222222", payment);
    console.log("srjsurewiewut44444444444444", getAllPaymentType);
    console.log("gfhdghdguhuruh6666666666666", userAddressItems);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md ">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">User Payment  </h3>
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md">
                    <div className="max-w-full overflow-x-auto">
                      <div className="inline-block min-w-full">
                        <div className="p-2 overflow-hidden bg-white shadow-sm">
                          <select id="countries_disabled" data-dropdown-toggle="dropdown" class="  focus:ring-1 focus:outline-none focus:ring-black font-medium rounded-md border text-gray-900 border-gray-300 text-xs px-4 py-3 text-center inline-flex items-center   dark:focus:ring-black" type="button"

                            onChange={this.inputAddUserChangeTag}>
                            <option selected value=''>SELECT PAYMENT TYPE</option>
                            {
                              getAllPaymentType && getAllPaymentType.length > 0 ?
                                getAllPaymentType.map((element, index) => (
                                  <React.Fragment>
                                    <option value={element && element.slug ? element.slug : null}>{element && element.type ? element.type : null}</option>
                                  </React.Fragment>
                                )) : null
                            }
                          </select>

                          {/*   
                            <div className='flex justify-end pt-7'>
                              <button className="px-20 py-2 font-bold text-white bg-blue-500 rounded hover:bg-sky-600" 
                              onClick={this.addMatkaResultSubmit}
                              >
                                Go
                              </button></div> */}
                        </div>

                        {this.state.paymentDataType === 'paytm' ?
                          <>
                            <table className="min-w-full border-0 divide-y ">
                              <thead className="bg-gray-200">
                                <tr className="">
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Paytm Holder Name </th>
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Paytm Number</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                                </tr>
                              </thead>

                              <tbody>

                                {
                                  payment && payment.length > 0 ?
                                    payment.map((element, index) =>



                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.paytmName ? element.paytmName : "NA"} </td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.paytmNo ? element.paytmNo : "NA"}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                      </tr>

                                    ) : (<tr className="bg-white bg-opacity-5 " >
                                      <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                    </tr>)
                                }
                              </tbody>

                            </table></>
                          : null}
                        {this.state.paymentDataType === 'phonePe' ?
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap"># </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">phonePe Holder Name </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">phonePe No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                payment && payment.length > 0 ?
                                  payment.map((element, index) =>

                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.phonePeName ? element.phonePeName : "NA"} </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.phonePeNo ? element.phonePeNo : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                    </tr>

                                  ) : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                          : null}
                        {this.state.paymentDataType === 'gPay' ?
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap"># </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">gPay Holder Name </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">gPay No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                payment && payment.length > 0 ?
                                  payment.map((element, index) =>



                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.gPayName ? element.gPayName : "NA"} </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.gPayNo ? element.gPayNo : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                    </tr>

                                  ) : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                          : null}
                        {this.state.paymentDataType === 'whatsAppPay' ?
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap"># </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">whatsAppPay Holder Name </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">whatsAppPay No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                payment && payment.length > 0 ?
                                  payment.map((element, index) =>



                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.whatsAppPayName ? element.whatsAppPayName : "NA"} </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.whatsAppPayNo ? element.whatsAppPayNo : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                    </tr>

                                  ) : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                          : null}
                        {this.state.paymentDataType === 'bankTransfer' ?
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap"># </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Account Holder </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">bank Name </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Account Type</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Account No.</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Ifsc</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                payment && payment.length > 0 ?
                                  payment.map((element, index) =>



                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankAcHolder ? element.bankAcHolder : "NA"} </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankName ? element.bankName : "NA"} </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankAcType ? element.bankAcType : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankAccountNo ? element.bankAccountNo : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankIfsc ? element.bankIfsc : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>


                                    </tr>

                                  ) : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                          : null}
                      </div>



                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            profileTotal && profileTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={profileTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            profileTotal && profileTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={profileTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>



        <CreateAddDealerModal
          options={options}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          // allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          // allGameItems={allGameItems}
          inputAddUserChange={this.inputAddUserChange}
          addNewsSubmit={this.addNewsSubmit}
          handleFile={this.handleFile}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />

        <CreateUpdatePasswordDealer
          options={options}
          // allCategory={allCategory}
          offset={this.state.offset}
          updateSelectedTag={this.state.updateSelectedTag}
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          // allGameItems={allGameItems}
          inputChange={this.inputChange}
          handleFile={this.handleFile}
          updateNewsSubmit={this.updateNewsSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          contentFieldUpdate={this.contentFieldUpdate}
          deleteContentFieldUpdate={this.deleteContentFieldUpdate}
          setCurrentIndexUpdate={this.setCurrentIndexUpdate}
          inputChangeContentUpdate={this.inputChangeContentUpdate}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          modules={this.modules}
          formats={this.formats}
          rteChangeUpdate={this.rteChangeUpdate}
        />

        <ViewMoreDetailsModal
          options={options}
          offset={this.state.offset}
          // allCategory={allCategory}
          modules={this.modules}
          formats={this.formats}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          sliderRowData={this.state.sliderRowData}
          updateSelectedTag={this.state.updateSelectedTag}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, category, news } = state;
  return {

    users,
    // category,
    // news
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(profileAndAddress);
