export const categoryConstants = {

    GET_ALL_CATEGORY_PARENT_REQUEST: 'GET_ALL_CATEGORY_PARENT_REQUEST',
    GET_ALL_CATEGORY_PARENT_SUCCESS: 'GET_ALL_CATEGORY_PARENT_SUCCESS',
    GET_ALL_CATEGORY_PARENT_FAILURE: 'GET_ALL_CATEGORY_PARENT_FAILURE',

    GETALL_CATEGORY_REQUEST: 'GETALL_CATEGORY_REQUEST',
    GETALL_CATEGORY_SUCCESS: 'GETALL_CATEGORY_SUCCESS',
    GETALL_CATEGORY_FAILURE: 'GETALL_CATEGORY_FAILURE',

    GET_LIST_CATEGORY_REQUEST: 'GET_LIST_CATEGORY_REQUEST',
    GET_LIST_CATEGORY_SUCCESS: 'GET_LIST_CATEGORY_SUCCESS',
    GET_LIST_CATEGORY_FAILURE: 'GET_LIST_CATEGORY_FAILURE',


    ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    DISABLE_CATEGORY_REQUEST: 'DISABLE_CATEGORY_REQUEST',
    DISABLE_CATEGORY_SUCCESS: 'DISABLE_CATEGORY_SUCCESS',
    DISABLE_CATEGORY_FAILURE: 'DISABLE_CATEGORY_FAILURE',

};
