import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { CiEdit } from "react-icons/ci";
import "./style.css";
import CreateAddSlider from "./components/CreateAdd/CreateAddSlider"

class AppSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},
      inputAddUserChange: "",
      addUserCreate: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: "",
      editData: false,
      addData: false,
      addUserCreateModal: false,
      sliderContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background",
    ];
  }
  componentDidMount() {
    let data = {
      keyWord: this.state.keyWord,
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(userActions.getAppSetting());
    // this.props.dispatch(newsActions.getAllGameType());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {},
        errorsAddUser: {},
        // handleOpenCreate:false,
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",
      };
    }

    if (nextProps.users.updateUserSuccess) {
      console.log("Called______");
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""],
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""],
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",
      };
    }
    if (nextProps.users.imageUploadSuccess) {
      return {
        ...nextProps,
        imageWebLink:
          nextProps.users &&
            nextProps.users.filesDetails &&
            nextProps.users.filesDetails.imageName
            ? nextProps.users.filesDetails.imageName
            : null,
        imageUrl:
          nextProps.users &&
            nextProps.users.filesDetails &&
            nextProps.users.filesDetails.imageURL
            ? nextProps.users.filesDetails.imageURL
            : null,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  // handlePageClick = (data) => {
  //   console.log("data  ", data);
  //   let offset = Math.ceil(data.selected * this.state.size);
  //   this.setState({ offset: offset, page: data.selected });
  //   let datatemp = {
  //     "keyWord": this.state.keyWord,
  //     "pageNo": data.selected + 1,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(sliderActions.getSliderList(datatemp));
  // }
  // handleSearch = (event) => {
  //   event.preventDefault();
  //   let { value } = event.target;
  //   this.setState({ keyWord: value, offset: 0 });
  //   let data = {
  //     "keyWord": value,
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(sliderActions.getSliderList(data));
  // }

  // handleOpenCreateModalMoreDetails = (data) => {
  //   console.log("data_____?", data);
  //   this.props.history.push("/app/viewdetails/" + data.id)
  // }

  // handleOpenCreateModalUpdatePassword = (data) => {

  //   console.log("==========================>data:", data);

  //   this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  // }
  // handleMoreDetailsHideModal = () => {
  //   this.setState({ moreDetailsCreateModal: false });
  // }
  // handleloginToThisAccountHideModal = () => {
  //   this.setState({ loginToThisAccountModal: false });
  // }
  // handleOpenloginToThisAccountModal = (data) => {
  //   // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
  //   let req = {
  //     "id": data.id
  //   }
  //   this.props.dispatch(sliderActions.loginToThisAccount(req));
  //   this.setState({ loginToThisAccountModal: true });
  // }
  // handleAddUserHideModal = () => {
  //   this.setState({
  //     addUserCreateModal: false,
  //     fieldsAddUser: {
  //       content: [""],
  //       tag: [""]
  //     }
  //   });
  //   // this.setState({ appsettingUpdateModal: false });
  // }
  // handleUpdatePasswordHideModal = () => {
  //   this.setState({ UpdatePasswordCreateModal: false });
  // }

  handleOpenCreate = (data) => {
    console.log("datadata__________", data);
    this.setState({ editData: !this.state.editData });
    this.setState({ fieldsAddUser: data });
  };

  handleOpenAdd = (data) => {
    console.log("datadata__________", data);
    this.setState({ addData: !this.state.addData });
  };

  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value);
    this.setState({ fieldsAddUser, errorsAddUser });
  };
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  };

  changePasswordAdmin = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationChangePassword()) {
      let reqData = {
        newpassword: this.state.fieldsAddUser.newpassword,
        confirmpassword: this.state.fieldsAddUser.confirmpassword,
      };
      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(userActions.changePasswordAdmin(reqData));
    }
  };

  addNewsSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    // if (this.handleValidationAddNews()) {
    let reqData = {
      appName: this.state.fieldsAddUser.appName,
      email: this.state.fieldsAddUser.email,
      mobile1: this.state.fieldsAddUser.mobile1,
      mobile2: this.state.fieldsAddUser.mobile2,
      mobile3: this.state.fieldsAddUser.mobile3,
      // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
    };
    console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
    this.props.dispatch(userActions.createOrUpdateAppSetting(reqData));
    // this.setState({ editData: !this.state.editData });
    this.setState({ addData: !this.state.addData });
    // }
  };

  handleValidationChangePassword = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //slider_id
    if (!fieldsAddUser["newpassword"] || fieldsAddUser["newpassword"] === "") {
      formIsValid = false;
      errorsAddUser["newpassword"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["confirmpassword"] || fieldsAddUser["confirmpassword"] === "") {
      formIsValid = false;
      errorsAddUser["confirmpassword"] = "Cannot be empty";
    }

    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  };

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //slider_id
    if (!fieldsAddUser["appName"] || fieldsAddUser["appName"] === "") {
      formIsValid = false;
      errorsAddUser["appName"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["email"] || fieldsAddUser["email"] === "") {
      formIsValid = false;
      errorsAddUser["email"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["mobile1"] || fieldsAddUser["mobile1"] === "") {
      formIsValid = false;
      errorsAddUser["mobile1"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["mobile2"] || fieldsAddUser["mobile2"] === "") {
      formIsValid = false;
      errorsAddUser["mobile2"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["mobile3"] || fieldsAddUser["mobile3"] === "") {
      formIsValid = false;
      errorsAddUser["mobile3"] = "Cannot be empty";
    }
    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  };

  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(
        userActions.uploadImage(
          event.target.files[event.target.files.length - 1]
        )
      );
      this.setState({ selectedFile: null });
    } else {
      console.log("No File To Upload!");
    }
  };

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push("");
    this.setState({ fieldsAddUser });
    console.log("fieldsAddUser  ", fieldsAddUser);
  };

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1);
    this.setState({ fieldsAddUser });
  };

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log("kkkkkkkkkk  ", currentConetntIndex);
  };

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  };

  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push("");
    this.setState({ fieldsUpdatePassword });
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  };

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1);
    this.setState({ fieldsUpdatePassword });
  };

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {
    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log("kkkkkkkkkk  ", currentConetntUpdateIndex);
  };

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] =
      value;

    this.setState({ fieldsUpdatePassword });
  };

  handleUpload = () => {
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } =
      this.state;
    fieldsAddUser["image"] = imageWebLink;
    fieldsUpdatePassword["image"] = imageWebLink;
    fieldsAddUser["imageUrlLink"] = imageUrl;
    fieldsUpdatePassword["imageUrlLink"] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });
  };

  handleUploadImageUser = () => {
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } =
      this.state;
    fieldsAddUser["imageUser"] = imageWebLink;
    fieldsUpdatePassword["imageUser"] = imageWebLink;
    fieldsAddUser["imageUserUrlLink"] = imageUrl;
    fieldsUpdatePassword["imageUserUrlLink"] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });
  };

  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push("");
    this.setState({ fieldsAddUser });
    console.log("fieldsAddUser  ", fieldsAddUser);
  };

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1);
    this.setState({ fieldsAddUser });
  };

  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  };

  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push("");
    this.setState({ fieldsUpdatePassword });
  };

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1);
    this.setState({ fieldsUpdatePassword });
  };

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  };

  handleSelectedTag = (selectedTag) => {
    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {
    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {
    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  handleOpenModel = () => {
    this.setState({ addUserCreateModal: true })
  }
  handleHideModel = () => {
    this.setState({ addUserCreateModal: false })
  }

  rteChangeUpdate = (content, delta, source, editor) => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };

  editNewsSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    // if (this.handleValidationAddNews()) {
    let reqData = {
      appName: this.state.fieldsAddUser.appName,
      email: this.state.fieldsAddUser.email,
      mobile1: this.state.fieldsAddUser.mobile1,
      mobile2: this.state.fieldsAddUser.mobile2,
      mobile3: this.state.fieldsAddUser.mobile3,
      // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
    };
    console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
    this.props.dispatch(userActions.createOrUpdateAppSetting(reqData));
    this.setState({ editData: !this.state.editData });
    // this.setState({ addData: !this.state.addData });
    // }
  };
  render() {
    let { users } = this.props;
    let { getAppSetting, loading } = users;

    let { filesDetails } = users;

    console.log(
      "RENDER__________________________________getAppSetting",
      this.state.fieldsAddUser
    );

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>
        <div className="flex flex-1 overflow-hidden overflow-y-auto bg-gray-100">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md ">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <h3 className="text-xl font-semibold ">App Setting</h3>
                  </div>
                </section>

                <section>
                  <div className="flex items-center  my-4">
                    <button
                      className="flex items-center  w-50 px-6 py-2 text-sm font-medium text-white capitalize bg-yellow-500 rounded-md"
                      onClick={this.handleOpenModel}
                    >
                      <CiEdit
                        style={{ fontSize: "1.5rem" }}
                        className="mr-1.5"
                      />{" "}
                      Update Admin Password{" "}
                    </button>
                  </div>
                  {getAppSetting ? (
                    <>
                      <div className="flex items-center justify-center my-4">
                        <button
                          className="flex items-center justify-center w-46 px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md"
                          onClick={() => this.handleOpenCreate(getAppSetting)}
                        >
                          <CiEdit
                            style={{ fontSize: "1.5rem" }}
                            className="mr-1.5"
                          />{" "}
                          Edit App Data{" "}
                        </button>
                      </div>
                      {!this.state.editData ? (
                        <>
                          <div class="bg-white">
                            <div class="lg:w-3/4 w-full mx-auto p-6">
                              <div className="grid gap-6 mb-4 md:grid-cols-3">
                                <div class="border p-4 rounded text-sm">
                                  <div class="flex justify-center">
                                    <h1 className="text-xl font-bold ">
                                      App Name
                                    </h1>
                                  </div>
                                  <div class="flex justify-center text-sm mt-6">
                                    <p class="font-bold text-lg text-blue-800">
                                      {getAppSetting.appName}
                                    </p>
                                  </div>
                                </div>
                                <div class=" border p-4 rounded text-sm">
                                  <div class="flex justify-center">
                                    <h1 className="text-xl font-bold ">
                                      Email
                                    </h1>
                                  </div>
                                  <div class="flex justify-center text-sm mt-6">
                                    <p class="font-bold text-lg text-blue-800">
                                      {getAppSetting.email}
                                    </p>
                                  </div>
                                </div>
                                <div class="border p-4 rounded text-sm">
                                  <div class="flex justify-center">
                                    <h1 className="text-xl font-bold ">
                                      mobile1
                                    </h1>
                                  </div>
                                  <div class="flex justify-center text-sm mt-6">
                                    <p class="font-bold text-lg text-blue-800">
                                      {getAppSetting.mobile1}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="grid gap-6 mb-4 md:grid-cols-2">
                                <div class="border p-4 rounded text-sm">
                                  <div class="flex justify-center">
                                    <h1 className="text-xl font-bold ">
                                      mobile2
                                    </h1>
                                  </div>
                                  <div class="flex justify-center text-sm mt-6">
                                    <p class="font-bold text-lg text-blue-800">
                                      {getAppSetting.mobile2}
                                    </p>
                                  </div>
                                </div>

                                <div class="border p-4 rounded text-sm">
                                  <div class="flex justify-center">
                                    <h1 className="text-xl font-bold ">
                                      mobile3
                                    </h1>
                                  </div>
                                  <div class="flex justify-center text-sm mt-6">
                                    <p class="font-bold text-lg text-blue-800">
                                      {getAppSetting.mobile3}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="w-full border py-4 rounded text-sm">
                                <div class="flex justify-center">
                                  <h1 className="text-xl font-bold ">
                                    Date/Time
                                  </h1>
                                </div>
                                <div class="flex justify-center text-sm mt-6">
                                  <p class="font-bold text-lg text-blue-800">
                                    {moment(
                                      new Date(
                                        parseInt(getAppSetting.createdAt)
                                      )
                                    )
                                      .utcOffset("+05:30")
                                      .format("YYYY-MM-DD HH:mm")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="bg-white mt-4">
                            <form className="w-full mx-auto lg:w-3/4">
                              <div class="grid gap-6 mb-6 md:grid-cols-2 p-6">
                                <div>
                                  <label
                                    for="appName"
                                    class="block mb-2 text-lg ml-1 font-medium text-gray-900 dark:text-white"
                                  >
                                    App Name
                                  </label>
                                  <input
                                    type="text"
                                    id="appName"
                                    name="appName"
                                    value={this.state.fieldsAddUser.appName}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=" App Name..."
                                    required
                                    onChange={this.inputAddUserChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    for="email"
                                    class="block mb-2 text-lg ml-1 font-medium text-gray-900 dark:text-white"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={this.state.fieldsAddUser.email}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Email..."
                                    required
                                    onChange={this.inputAddUserChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    for="mobile1"
                                    class="block mb-2 text-lg ml-1 font-medium text-gray-900 dark:text-white"
                                  >
                                    Mobile 1
                                  </label>
                                  <input
                                    type="text"
                                    id="mobile1"
                                    name="mobile1"
                                    value={this.state.fieldsAddUser.mobile1}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Mobile 1"
                                    required
                                    onChange={this.inputAddUserChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    for="mobile2"
                                    class="block mb-2 text-lg ml-1 font-medium text-gray-900 dark:text-white"
                                  >
                                    Mobile 2
                                  </label>
                                  <input
                                    type="text"
                                    id="mobile2"
                                    name="mobile2"
                                    value={this.state.fieldsAddUser.mobile2}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Mobile 2..."
                                    required
                                    onChange={this.inputAddUserChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    for="website"
                                    class="block mb-2 text-lg ml-1 font-medium text-gray-900 dark:text-white"
                                  >
                                    Mobile 3
                                  </label>
                                  <input
                                    type="text"
                                    id="mobile3"
                                    name="mobile3"
                                    value={this.state.fieldsAddUser.mobile3}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Mobile 3..."
                                    required
                                    onChange={this.inputAddUserChange}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center justify-center pb-6">
                                <button
                                  class="bg-shine-400 w-56 mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out"
                                  type="button"
                                  onClick={this.editNewsSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!this.state.addData ? (
                        <div className="mx-auto mt-6 w-60">
                          <button
                            className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700"
                            type="button"
                            onClick={this.handleOpenAdd}
                          >
                            Add App Setting
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                  {this.state.addData ? (
                    <>
                      <div class="bg-white mt-4">
                        <form className="w-full mx-auto lg:w-3/4">
                          <div class="grid gap-6 mb-6 md:grid-cols-2 p-6">
                            <div>
                              <label
                                for="appName"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                App Name
                              </label>
                              <input
                                type="text"
                                id="appName"
                                name="appName"
                                value={this.state.fieldsAddUser.appName}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="John"
                                required
                                onChange={this.inputAddUserChange}
                              />
                            </div>
                            <div>
                              <label
                                for="email"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                id="email"
                                name="email"
                                value={this.state.fieldsAddUser.email}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Doe"
                                required
                                onChange={this.inputAddUserChange}
                              />
                            </div>
                            <div>
                              <label
                                for="mobile1"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                mobile1
                              </label>
                              <input
                                type="text"
                                id="mobile1"
                                name="mobile1"
                                value={this.state.fieldsAddUser.mobile1}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Flowbite"
                                required
                                onChange={this.inputAddUserChange}
                              />
                            </div>
                            <div>
                              <label
                                for="mobile2"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                mobile2
                              </label>
                              <input
                                type="text"
                                id="mobile2"
                                name="mobile2"
                                value={this.state.fieldsAddUser.mobile2}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="123-45-678"
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                required
                                onChange={this.inputAddUserChange}
                              />
                            </div>
                            <div>
                              <label
                                for="website"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                mobile3
                              </label>
                              <input
                                type="text"
                                id="mobile3"
                                name="mobile3"
                                value={this.state.fieldsAddUser.mobile3}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="flowbite.com"
                                required
                                onChange={this.inputAddUserChange}
                              />
                            </div>
                          </div>
                          <div className="flex items-center justify-center pb-6">
                            <button
                              class="bg-shine-400 w-56 mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out"
                              type="button"
                              onClick={this.addNewsSubmit}
                            >
                              Add App Setting
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : null}
                </section>
              </div>
            </div>
          </main>
        </div>
        <CreateAddSlider
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          // allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          // allGameItems={allGameItems}
          inputAddUserChange={this.inputAddUserChange}
          changePasswordAdmin={this.changePasswordAdmin}
          handleFile={this.handleFile}
          handleHideModel={this.handleHideModel}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}
export default connect(mapStateToProps)(AppSetting);
