import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sliderActions, userActions, newsActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
import CreateAddDealerModal from "./components/CreateAdd/CreateAddSlider";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"


class Notify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      sliderContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.slider.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.slider.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(sliderActions.getSliderList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(sliderActions.getSliderList(data));
  }

  disableSlider = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of slider?',
      message: `Are you sure for ${data.title} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(sliderActions.disableSlider(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.title}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(sliderActions.deleteSlider(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }

  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(sliderActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNotificationSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationAddNews()) {
      let reqData = {
        "title": this.state.fieldsAddUser.title,
        "body": this.state.fieldsAddUser.body,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }
      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(sliderActions.notifyAllUser(reqData));
    }
  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //slider_id
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //gameType
    if (!fieldsAddUser["body"] || fieldsAddUser["body"] === "") {
      formIsValid = false;
      errorsAddUser["body"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {


    let { users } = this.props;


    let { filesDetails } = users;

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "title": this.state.fieldsUpdatePassword.title,
        "slug": this.state.fieldsUpdatePassword.slug,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUpdatePassword.image,
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(sliderActions.updateSlider(reqData, paginationData));
    }

  }


  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //gameNo
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //gameType
    if (!fieldsUpdatePassword["slug"] || fieldsUpdatePassword["slug"] === "") {
      formIsValid = false;
      errorsUpdatePassword["slug"] = "Cannot be empty";
    }
    // //gameType
    // if (!fieldsUpdatePassword["image"] || fieldsUpdatePassword["image"] === "") {
    //   formIsValid = false;
    //   errorsUpdatePassword["image"] = "Cannot be empty";
    // }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }




  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };







  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }

  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true, });
  }
  render() {

    let { slider, category, news, users } = this.props;
    let { items, total, loading, getSliderList } = slider;
    let { allCategory } = category;
    let { allGameItems } = news;


    let { filesDetails } = users;

    console.log("RENDER_______items", items);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className=" rounded-md ">
                  <div className="border p-6 space-y-6 bg-white flex flex-wrap items-center justify-between w-full">
                    <div className="">
                      <h3 className="text-xl font-semibold ">Notification</h3>
                    </div>
                  </div>
                  <div>
                    <div className='flex justify-center items-center mt-36'>
                      <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Notify User's </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>


        <CreateAddDealerModal
          options={options}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          allGameItems={allGameItems}
          inputAddUserChange={this.inputAddUserChange}
          addNotificationSubmit={this.addNotificationSubmit}
          handleFile={this.handleFile}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { slider, users, category, news } = state;
  return {
    slider,
    users,
    category,
    news
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Notify);
