import React from "react";

import Modal from 'react-modal';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



export default function CreateUpdatePasswordDealer(props) {

  let { UpdatePasswordCreateModal, inputChange, handleAppSeetingHideModal, errorsUpdatePassword, updateNewsSubmit, fieldsUpdatePassword, handleFile, allCategory, contentFieldUpdate,
    setCurrentIndexUpdate, handleUpload, handleUploadImageUser, handleSelectedTagUpdate, options, updateSelectedTag,
    deleteContentFieldUpdate, inputChangeContentUpdate, offset, tagFieldUpdate, deletetagFieldUpdate, inputChangetagUpdate, rteChangeUpdate, modules, formats, categoryListItems, subCategoryListItems } = props;

  console.log("MODAL______fieldsUpdatePassword::", fieldsUpdatePassword);
  // console.log("MODAL______fieldsUpdatePassword.category::", fieldsUpdatePassword.category);

  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*Title*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Update News</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>

              <form autoComplete="off">
                <div className="flex justify-between w-full">

                  {/* <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      onChange={inputChange} id="categoryId" name="categoryId"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.categoryId ? fieldsUpdatePassword.categoryId : null}>
                      <option selected>Plz Select Category</option>
                      {
                        allCategory && allCategory && allCategory.length > 0 ?
                          allCategory.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsUpdatePassword && errorsUpdatePassword["categoryId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["categoryId"]}
                      </div>
                      : null}
                  </div> */}

                  <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      onChange={inputChange} id="category" name="category"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.category ? fieldsUpdatePassword.category : null}>
                      <option selected>Plz Select Category</option>
                      {
                        categoryListItems && categoryListItems && categoryListItems.length > 0 ?
                          categoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsUpdatePassword && errorsUpdatePassword["category"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["category"]}
                      </div>
                      : null}
                  </div>

                  <div class="mr-2 w-full">
                    <label className="text-gray-700 text-sm">Category :</label>
                    <select class="form-select border-1 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example"
                      onChange={inputChange} id="subcategory" name="subcategory"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.subcategory ? fieldsUpdatePassword.subcategory : null}>
                      <option selected>Plz Select Category</option>
                      {
                        subCategoryListItems && subCategoryListItems && subCategoryListItems.length > 0 ?
                          subCategoryListItems.map((element, index) => (
                            <option value={element.value}>{element && element.label ? element.label : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsUpdatePassword && errorsUpdatePassword["subcategory"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["subcategory"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">News Id</label>
                      <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["news_id"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="news_id" name="news_id" placeholder="News Id" value={fieldsUpdatePassword.news_id} type="text"
                        // onChange={inputChange}
                        disabled />
                      {errorsUpdatePassword && errorsUpdatePassword["news_id"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdatePassword["news_id"]}
                        </div>
                        : null}
                    </div>
                  </div>

                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Title</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["title"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="title" name="title" placeholder="Title" value={fieldsUpdatePassword.title} type="title" onChange={inputChange} />
                      {errorsUpdatePassword && errorsUpdatePassword["title"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdatePassword["title"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Author</label>
                      <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["author"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="author" name="author" placeholder="Author" value={fieldsUpdatePassword.author} type="text" onChange={inputChange} />
                      {errorsUpdatePassword && errorsUpdatePassword["author"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdatePassword["author"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Slug</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["slug"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="slug" name="slug" placeholder="Slug" value={fieldsUpdatePassword.slug} type="slug" onChange={inputChange} />
                      {errorsUpdatePassword && errorsUpdatePassword["slug"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdatePassword["slug"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Description</label>
                      <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["descirption"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="descirption" name="descirption" placeholder="Description" value={fieldsUpdatePassword.descirption} type="text" onChange={inputChange} />
                      {errorsUpdatePassword && errorsUpdatePassword["descirption"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdatePassword["descirption"]}
                        </div>
                        : null}
                    </div>
                  </div>

                </div>



                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <ReactQuill
                        // theme="snow"
                        modules={modules}
                        formats={formats}
                        onChange={rteChangeUpdate}
                        value={fieldsUpdatePassword.story}
                      />
                    </div>
                  </div>

                </div>


                <label class="block text-gray-700 text-sm" for="username">Tag :</label>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                  <Multiselect
                    options={options}
                    onSelect={handleSelectedTagUpdate}
                    selected={updateSelectedTag}
                    isObject={false}
                    // disableSearch={true}
                    // renderHeader={true}
                    displayValue="label"
                    // value={fieldsUpdatePassword && fieldsUpdatePassword.tag ? fieldsUpdatePassword.tag : null}
                    value={(fieldsUpdatePassword && fieldsUpdatePassword.tag && fieldsUpdatePassword.tag.length > 0) ? fieldsUpdatePassword.tag.map((val) => { return (val ? val : "N/A") }) : "-"}
                  />
                  {/* {(fieldsUpdatePassword && fieldsUpdatePassword.tag && fieldsUpdatePassword.tag.length > 0) ? fieldsUpdatePassword.tag.map((val) => val) : "N/A"} */}
                </div>




                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                  {
                    fieldsUpdatePassword && fieldsUpdatePassword.content && fieldsUpdatePassword.content.length > 0 ?
                      fieldsUpdatePassword.content.map((item, index) => (
                        <React.Fragment key={index}>
                          <>
                            <div>
                              {index === 0 ?
                                <button className="border bg-shine-400 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => contentFieldUpdate(index)}>Add</button>
                                :
                                <button className="border bg-red-500 text-white px-2 py-1 text-sm font-semibold rounded-lg cursor-pointer" type="button" onClick={() => deleteContentFieldUpdate(index)}>Delete</button>
                              }
                              <label class="block text-gray-700 text-sm" for="username">Content : {offset + index + 1}</label>
                              <input className={`px-3 py-1 shadow-md placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["content"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                id="content" name="content" placeholder="content" value={item}
                                onClick={() => setCurrentIndexUpdate(index)}
                                type="text" onChange={inputChangeContentUpdate} />
                            </div>
                          </>
                        </React.Fragment>
                      )) : null
                  }
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-2">
                  <div className="w-full mr-2">
                    {
                      fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ?
                        <>

                          <div className="w-full mr-2 mt-1 ">
                            <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageUrlLink ? fieldsUpdatePassword.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mr-2 mt-1 ">
                          <img src={fieldsUpdatePassword && fieldsUpdatePassword.imageLinkUrl ? fieldsUpdatePassword.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                    <div className="mt-1 relative shadow-md">
                      <div className="w-full flex justify-between flex-wrap">
                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                          <input type="file" name="image" onChange={handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 space-x-4 mt-1">
                          <button className="border bg-blue-500 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateNewsSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
