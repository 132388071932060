export const videoConstants = {

    GET_VIDEO_LIST_REQUEST: 'GET_VIDEO_LIST_REQUEST',
    GET_VIDEO_LIST_SUCCESS: 'GET_VIDEO_LIST_SUCCESS',
    GET_VIDEO_LIST_FAILURE: 'GET_VIDEO_LIST_FAILURE',

    GETALL_VIDEO_REQUEST: 'GETALL_VIDEO_REQUEST',
    GETALL_VIDEO_SUCCESS: 'GETALL_VIDEO_SUCCESS',
    GETALL_VIDEO_FAILURE: 'GETALL_VIDEO_FAILURE',

    GET_LIST_VIDEO_REQUEST: 'GET_LIST_VIDEO_REQUEST',
    GET_LIST_VIDEO_SUCCESS: 'GET_LIST_VIDEO_SUCCESS',
    GET_LIST_VIDEO_FAILURE: 'GET_LIST_VIDEO_FAILURE',

    ADD_VIDEO_REQUEST: 'ADD_VIDEO_REQUEST',
    ADD_VIDEO_SUCCESS: 'ADD_VIDEO_SUCCESS',
    ADD_VIDEO_FAILURE: 'ADD_VIDEO_FAILURE',

    DELETE_VIDEO_REQUEST: 'DELETE_VIDEO_REQUEST',
    DELETE_VIDEO_SUCCESS: 'DELETE_VIDEO_SUCCESS',
    DELETE_VIDEO_FAILURE: 'DELETE_VIDEO_FAILURE',

    UPDATE_VIDEO_REQUEST: 'UPDATE_VIDEO_REQUEST',
    UPDATE_VIDEO_SUCCESS: 'UPDATE_VIDEO_SUCCESS',
    UPDATE_VIDEO_FAILURE: 'UPDATE_VIDEO_FAILURE',

    DISABLE_VIDEO_REQUEST: 'DISABLE_VIDEO_REQUEST',
    DISABLE_VIDEO_SUCCESS: 'DISABLE_VIDEO_SUCCESS',
    DISABLE_VIDEO_FAILURE: 'DISABLE_VIDEO_FAILURE',

};
