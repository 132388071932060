import {
   gameResultConstants
} from '../_constants';

export function gameResult(state = {}, action) {

  switch (action.type) {
    case gameResultConstants.ADD_GAME_RESULT_REQUEST:
      return {
        ...state
      };
    case gameResultConstants.ADD_GAME_RESULT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case gameResultConstants.ADD_GAME_RESULT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case gameResultConstants.GET_LIST_GAME_RESULT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultConstants.GET_LIST_GAME_RESULT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getGameResultList.list,
        total: action.users.getGameResultList.total
      };
    case gameResultConstants.GET_LIST_GAME_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameResultConstants.GETALL_GAME_RESULT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultConstants.GETALL_GAME_RESULT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        adsItems: action.users.getAllAds,
        adsTotal: action.users.getAllAds
      };
    case gameResultConstants.GETALL_GAME_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gameResultConstants.UPDATE_GAME_RESULT_REQUEST:
      return {
        ...state
      };
    case gameResultConstants.UPDATE_GAME_RESULT_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case gameResultConstants.UPDATE_GAME_RESULT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case gameResultConstants.DELETE_GAME_RESULT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultConstants.DELETE_GAME_RESULT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameResultConstants.DELETE_GAME_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gameResultConstants.DISABLE_GAME_RESULT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gameResultConstants.DISABLE_GAME_RESULT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gameResultConstants.DISABLE_GAME_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}