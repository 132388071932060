import { userConstants, upiConstants } from '../_constants';
import { userService, upiService } from '../_services';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const upiActions = {

    login,
    logout,
    disableUpi,
    getAllUpi,
    createUpi,
    updateUpi,
    getUpiList,
    deleteUpi,
    // getAllCategory,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


function createUpi(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        upiService.createUpi(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Upi Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUpiList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.ADD_UPI_REQUEST } }
    function success(users) { return { type: upiConstants.ADD_UPI_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.ADD_UPI_FAILURE, error } }
}
function updateUpi(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        upiService.updateUpi(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Upi Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUpiList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.UPDATE_UPI_REQUEST } }
    function success(users) { return { type: upiConstants.UPDATE_UPI_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.UPDATE_UPI_FAILURE, error } }
}
function getAllUpi(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        upiService.getAllUpi(data)
            .then(
                users => {
                    console.log("getAllUpi $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.GETALL_UPI_REQUEST } }
    function success(users) { return { type: upiConstants.GETALL_UPI_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.GETALL_UPI_FAILURE, error } }
}
function getUpiList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        upiService.getUpiList(data)
            .then(
                users => {
                    console.log("getUpiList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.GET_UPI_LIST_REQUEST } }
    function success(users) { return { type: upiConstants.GET_UPI_LIST_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.GET_UPI_LIST_FAILURE, error } }
}

function deleteUpi(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        upiService.deleteUpi(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUpiList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.DELETE_UPI_REQUEST } }
    function success(users) { return { type: upiConstants.DELETE_UPI_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.DELETE_UPI_FAILURE, error } }
}
function disableUpi(data, paginationData) {

    return dispatch => {
        dispatch(request());
        upiService.disableUpi(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUpiList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: upiConstants.DISABLE_UPI_REQUEST } }
    function success(users) { return { type: upiConstants.DISABLE_UPI_SUCCESS, users } }
    function failure(error) { return { type: upiConstants.DISABLE_UPI_FAILURE, error } }
}
