import React from "react";
import Modal from 'react-modal';
// import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';


export default function UpdateVideoModal(props) {

  let { UpdateVideoCreateModal, handleUpdatePasswordHideModal, errorsUpdateVideo, inputChangeUpdateCategory, updateVideoSubmit, fieldsUpdateVideo, handleFileVideo, filesDetails, handleFile } = props;
  console.log("modal::fieldsUpdateVideo", fieldsUpdateVideo);

  return (


    <Modal
      isOpen={UpdateVideoCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Update Upi</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleUpdatePasswordHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">



                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Name</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="name" name="name" placeholder="Name" value={fieldsUpdateVideo.name} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["name"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["name"]}
                      </div>
                      : null}

                  </div>
                </div>


                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Slug</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["slug"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="slug" name="slug" placeholder="Slug" value={fieldsUpdateVideo.slug} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["slug"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["slug"]}
                      </div>
                      : null}

                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Upi</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["upi"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="upi" name="upi" placeholder="Upi" value={fieldsUpdateVideo.upi} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["upi"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["upi"]}
                      </div>
                      : null}

                  </div>
                </div>



                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateVideoSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
