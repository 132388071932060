import {
  userConstants, playing11Constants
} from '../_constants';

export function playing11(state = {}, action) {

  switch (action.type) {

    case playing11Constants.GET_PLAYER_BY_MATCH_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case playing11Constants.GET_PLAYER_BY_MATCH_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getPlayersByMatchId,
        total: action.users.getPlayersByMatchId
      };
    case playing11Constants.GET_PLAYER_BY_MATCH_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case playing11Constants.ADD_PLAYING11_REQUEST:
      return {
        ...state
      };
    case playing11Constants.ADD_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case playing11Constants.ADD_PLAYING11_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case playing11Constants.GET_LIST_PLAYING11_REQUEST:
      return {
        ...state,
        loading: true
      };
    case playing11Constants.GET_LIST_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getPlaying11List.list,
        total: action.users.getPlaying11List.total
      };
    case playing11Constants.GET_LIST_PLAYING11_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case playing11Constants.GETALL_PLAYING11_REQUEST:
      return {
        ...state,
        loading: true
      };
    case playing11Constants.GETALL_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        playing11Items: action.users.getAllPlaying11,
        playing11Total: action.users.getAllPlaying11
      };
    case playing11Constants.GETALL_PLAYING11_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_PLAYING11_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_PLAYING11_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_PLAYING11_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_PLAYING11_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_PLAYING11_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_PLAYING11_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_PLAYING11_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}