
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const offerService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getOffersList,
    disableOffer,
    deleteOffer,
    createOffers,
    updateSlider,
    getAllBet,
    getAllUser,
    updateBet,
    getAllBetByBetTypeId
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getOffersList(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptions___________requestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getOffersList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getOffersList: data.data
            }
            console.log("i am in service getOffersList", userObj);

            return userObj;
        });
}
function getAllBet(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllBid`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllBet: data.data
            }
            console.log("i am in service getAllBid", userObj);

            return userObj;
        });
}
function getAllUser(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllUserr: data.data
            }
            console.log("i am in service getAllBid", userObj);

            return userObj;
        });
}
function getAllBetByBetTypeId(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllBetByBidTypeId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllBetByBetTypeId: data.data
            }
            console.log("i am in service getAllBetByBidTypeId", userObj);

            return userObj;
        });
}



function deleteOffer(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteOffers`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteOffer: data.data
            }
            console.log("i am in service''...>> deleteOffer ::", userObj);

            return userObj;
        });
}
function disableOffer(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateOffersStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateBetStatus: data.data
            }
            console.log("i am in service''...>> updateOffersStatus ::", userObj);

            return userObj;
        });
}

function updateSlider(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateOffers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateSlider: data.data
            }
            console.log("I am in service updateSlider", userObj);

            return userObj;
        });
}
function updateBet(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBid`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateBet: data.data
            }
            console.log("I am in service updateBet", userObj);

            return userObj;
        });
}
function createOffers(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createOffers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createOffers: data.data
            }
            console.log("I am in service createOffers", userObj);

            return userObj;
        });
}


function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}