import { userConstants, eventConstants } from '../_constants';
import { userService, eventService } from '../_services';
import { toast } from 'react-toastify';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const eventActions = {
    disableEvent,
    getEventById,
    createEvent,
    updateEvent,
    getEventList,
    deleteEvent,
    uploadImageClear,
    uploadImage
};

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: eventConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: eventConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: eventConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}


function createEvent(data, reqData) {
    console.log('data, reqDatadata, reqData_________', data, reqData);

    return dispatch => {
        dispatch(request());
        eventService.createEvent(data)
            .then(

                users => {
                    dispatch(alertActions.success("Event Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEventList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.ADD_EVENT_REQUEST } }
    function success(users) { return { type: eventConstants.ADD_EVENT_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.ADD_EVENT_FAILURE, error } }
}
function updateEvent(data, paginationData) {

    return dispatch => {
        dispatch(request());
        eventService.updateEvent(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Event Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEventList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.UPDATE_EVENT_REQUEST } }
    function success(users) { return { type: eventConstants.UPDATE_EVENT_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.UPDATE_EVENT_FAILURE, error } }
}
function getEventById(data) {
    return dispatch => {
        dispatch(request());
        eventService.getEventById(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.GET_EVENT_BY_ID_REQUEST } }
    function success(users) { return { type: eventConstants.GET_EVENT_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.GET_EVENT_BY_ID_FAILURE, error } }
}
function getEventList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        eventService.getEventList(data)
            .then(
                users => {
                    console.log("getEventList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.GET_LIST_EVENT_REQUEST } }
    function success(users) { return { type: eventConstants.GET_LIST_EVENT_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.GET_LIST_EVENT_FAILURE, error } }
}
function deleteEvent(data, paginationData) {
    return dispatch => {
        dispatch(request());
        eventService.deleteEvent(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Event Delete Successfully."));
                    dispatch(this.getEventList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.DELETE_EVENT_REQUEST } }
    function success(users) { return { type: eventConstants.DELETE_EVENT_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.DELETE_EVENT_FAILURE, error } }
}
function disableEvent(data, paginationData) {

    return dispatch => {
        dispatch(request());
        eventService.disableEvent(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Event Status Changed Successfully."));
                    dispatch(this.getEventList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventConstants.DISABLE_EVENT_REQUEST } }
    function success(users) { return { type: eventConstants.DISABLE_EVENT_SUCCESS, users } }
    function failure(error) { return { type: eventConstants.DISABLE_EVENT_FAILURE, error } }
}
