import React from "react";

import Modal from 'react-modal';



export default function CreateAddFancyModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsFancy, inputAddUserChange, createFancySubmit, fieldsFancy, handleFile, inputChangeIsVideo, isVideo,
    //  handleFile2

  } = props;
  console.log("isVideo_modal::", isVideo);

  return (

    <Modal
      isOpen={addUserCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Add Fancy</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAddUserHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsFancy && !errorsFancy["title"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="title" name="title" placeholder="Title" value={fieldsFancy.title} type="text" onChange={inputAddUserChange} />
                    {errorsFancy && errorsFancy["title"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsFancy["title"]}
                      </div>
                      : null}
                    {/* icon email */}
                    <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>
                </div>

                <div >
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsFancy && !errorsFancy["url"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="url" name="url" placeholder="Url" value={fieldsFancy.url} type="text" onChange={inputAddUserChange} />
                    {errorsFancy && errorsFancy["url"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsFancy["url"]}
                      </div>
                      : null}
                    {/* icon email */}
                    <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>
                </div>

                {/* <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload video 1234
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile2}
                  />
                </div> */}

                <div >
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsFancy && !errorsFancy["description"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="description" name="description" placeholder="Description" value={fieldsFancy.description} type="text" onChange={inputAddUserChange} />
                    {errorsFancy && errorsFancy["description"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsFancy["description"]}
                      </div>
                      : null}
                    {/* icon email */}
                    <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>
                </div>

                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>






                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" onChange={inputChangeIsVideo} value={isVideo} />
                  <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <label for="toggle" class="text-xs text-gray-700">IS VIDEO.</label>

                {/* <input
                  type="checkbox"
                  checked={checked}
                  disabled={disabled}
                  onChange={e => onChange(e)}
                /> */}


                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-blue-500 w-full mx-auto flex justify-center py-3 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-blue-500 hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500 transition duration-150 ease-in-out" type="button" onClick={createFancySubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
