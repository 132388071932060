import React, { useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import Modal from 'react-modal';
// import moment from 'moment';
// import { MultiSelect } from "react-multi-select-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";


import Multiselect from 'multiselect-react-dropdown';




export default function CreateAddDealerModal(props) {


  let { addUserCreateModal, handleAddUserHideModal, errorsAddUser, inputAddUserChange, addEventSubmit, fieldsAddUser, handleFile, filesDetails, allGameItems, changeStartDate, changeEndDate,
    handleUpload,
    options, handleSelectedTag, selectedTag,
    rteChange, modules, formats, categoryListItems, subCategoryListItems } = props;
  console.log("addUserCreateModal______________________", addUserCreateModal);



  return (

    <Modal
      isOpen={addUserCreateModal}

    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed left-0 top-0 w-full h-full inset-0 z-90 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full ">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Add Event</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAddUserHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">
              <form autoComplete="on">
                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Question</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsAddUser && !errorsAddUser["que"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="que" name="que" placeholder="Question..." value={fieldsAddUser.que} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["que"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["que"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Match</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsAddUser && !errorsAddUser["currentMatch"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="currentMatch" name="currentMatch" placeholder="Match..." value={fieldsAddUser.currentMatch} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["currentMatch"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["currentMatch"]}
                      </div>
                      : null}
                  </div>


                  <div className="w-full ">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Winning Rate</label>
                    <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsAddUser && !errorsAddUser["winAmtPercent"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="winAmtPercent" name="winAmtPercent" placeholder="Winning Rate..." value={fieldsAddUser.winAmtPercent} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["winAmtPercent"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["winAmtPercent"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Select Start Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          value={fieldsAddUser.eventStartTime}
                          name="eventStartTime"
                          id="eventStartTime"
                          onChange={(newValue) => changeStartDate(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {errorsAddUser && errorsAddUser["eventStartTime"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["eventStartTime"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Select End Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          value={fieldsAddUser.eventEndTime}
                          onChange={(newValue) => changeEndDate(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {errorsAddUser && errorsAddUser["eventEndTime"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["eventEndTime"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="flex space-x-2 mt-2">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Current Score</label>
                    <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsAddUser && !errorsAddUser["currentScore"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="currentScore" name="currentScore" placeholder="Current Score..." value={fieldsAddUser.currentScore} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["currentScore"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["currentScore"]}
                      </div>
                      : null}
                  </div>


                  <div className="w-full ">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Question Source</label>
                    <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsAddUser && !errorsAddUser["queSource"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                      id="queSource" name="queSource" placeholder="Question Source..." value={fieldsAddUser.queSource} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["queSource"] ?
                      <div className="invalid-feedback text-red-500 text-xs">
                        {errorsAddUser["queSource"]}
                      </div>
                      : null}
                  </div>
                </div>

                {
                  filesDetails ?
                    <>

                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }

                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input
                    // style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>


                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={addEventSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
