import {
 quoteConstants
} from '../_constants';

export function quote(state = {}, action) {

  switch (action.type) {
    case quoteConstants.ADD_QUOTE_REQUEST:
      return {
        ...state
      };
    case quoteConstants.ADD_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case quoteConstants.ADD_QUOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case quoteConstants.GET_LIST_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case quoteConstants.GET_LIST_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getQuoteList.list,
        total: action.users.getQuoteList.total
      };
    case quoteConstants.GET_LIST_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case quoteConstants.GETALL_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case quoteConstants.GETALL_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        quoteItems: action.users.getAllQuote,
        quoteTotal: action.users.getAllQuote
      };
    case quoteConstants.GETALL_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case quoteConstants.UPDATE_QUOTE_REQUEST:
      return {
        ...state
      };
    case quoteConstants.UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case quoteConstants.UPDATE_QUOTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case quoteConstants.DELETE_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case quoteConstants.DELETE_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case quoteConstants.DELETE_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case quoteConstants.DISABLE_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case quoteConstants.DISABLE_QUOTE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case quoteConstants.DISABLE_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}