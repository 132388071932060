
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const sliderService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getAllSlider,
    createSlider,
    updateSlider,
    getSliderList,
    getReachOutList,
    deleteReachOut,
    disableReachout,
    deleteSlider,
    disableSlider,
    notifyAllUser
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getSliderList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSliderList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getSliderList: data.data
            }
            console.log("i am in service getSliderList", userObj);

            return userObj;
        });
}
function getReachOutList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getReachOutList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getReachOutList: data.data
            }
            console.log("i am in service getReachOutList", userObj);

            return userObj;
        });
}
function getAllSlider() {
    // console.log("data m kya aa rha h::service:::::::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllSlider`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllSlider: data.data
            }
            console.log("i am in service getAllSlider", userObj);

            return userObj;
        });
}



function deleteSlider(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteSlider`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteSlider: data.data
            }
            console.log("i am in service''...>> deleteSlider ::", userObj);

            return userObj;
        });
}
function deleteReachOut(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteReachOut`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteReachOut: data.data
            }
            console.log("i am in service''...>> deleteReachOut ::", userObj);

            return userObj;
        });
}
function disableReachout(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateReachOutStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateReachOutStatus: data.data
            }
            console.log("i am in service''...>> updateReachOutStatus ::", userObj);

            return userObj;
        });
}
function disableSlider(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSliderStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateSliderStatus: data.data
            }
            console.log("i am in service''...>> updateSliderStatus ::", userObj);

            return userObj;
        });
}

function updateSlider(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSlider`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateSlider: data.data
            }
            console.log("I am in service updateSlider", userObj);

            return userObj;
        });
}
function createSlider(data) {
    console.log('service mai data aa rhe hai :::::', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSlider`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createSlider: data.data
            }
            console.log("I am in service createSlider", userObj);

            return userObj;
        });
}
function notifyAllUser(data) {
    console.log('service mai data aa rhe hai :::::', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/notifyAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                notifyAllUser: data.data
            }
            console.log("I am in service notifyAllUser", userObj);

            return userObj;
        });
}


function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}