import React, { Component } from 'react';
import { connect } from 'react-redux';
import { websiteActions, userActions, newsActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAdd/CreateAddSlider";
import CreateUpdatePasswordDealer from "./components/CreateUpdate/CreateUpdateOffer";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { CgMoreVerticalO } from "react-icons/cg";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import Modal from 'react-modal';
import "./style.css"


class Website extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderRowData: {},
      imageRowData: {},
      txData: {},
      selectedTag: [""],
      imageTemp: "",
      approveTx: false,
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      sliderContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let data = {

      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }
    this.props.dispatch(websiteActions.getTicketList(data));
    // this.props.dispatch(newsActions.getAllGameType());
    console.log('______________________________________', data);
    this.interval = setInterval(() => {
      this.props.dispatch(websiteActions.getTicketList(data));
    }, 15000);

  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.website.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        approveTx: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.website.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }
    this.props.dispatch(websiteActions.getTicketList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }
    this.props.dispatch(websiteActions.getTicketList(data));
  }

  disableWebsite = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of Website?',
      message: `Are you sure for ${data.websiteName} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(websiteActions.disableWebsite(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteWebsite = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.websiteName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(websiteActions.deleteWebsite(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/walletticketdetails/" + data.id)
  }

  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(websiteActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['slider_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }


  handleValidationAddWebsite = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    if (!fieldsAddUser["websiteName"] || fieldsAddUser["websiteName"] === "") {
      formIsValid = false;
      errorsAddUser["websiteName"] = "Cannot be empty";
    }

    if (!fieldsAddUser["websiteUrl"] || fieldsAddUser["websiteUrl"] === "") {
      formIsValid = false;
      errorsAddUser["websiteUrl"] = "Cannot be empty";
    }
    //demoId
    if (!fieldsAddUser["demoId"] || fieldsAddUser["demoId"] === "") {
      formIsValid = false;
      errorsAddUser["demoId"] = "Cannot be empty";
    }
    //demoPass
    if (!fieldsAddUser["demoPass"] || fieldsAddUser["demoPass"] === "") {
      formIsValid = false;
      errorsAddUser["demoPass"] = "Cannot be empty";
    }

    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateWebsiteSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationUpdateWebsite()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "websiteName": this.state.fieldsUpdatePassword.websiteName,
        "websiteUrl": this.state.fieldsUpdatePassword.websiteUrl,
        "demoId": this.state.fieldsUpdatePassword.demoId,
        "demoPass": this.state.fieldsUpdatePassword.demoPass,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(websiteActions.updateWebsite(reqData, paginationData));
    }

  }


  handleValidationUpdateWebsite = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //websiteName
    if (!fieldsUpdatePassword["websiteName"] || fieldsUpdatePassword["websiteName"] === "") {
      formIsValid = false;
      errorsUpdatePassword["websiteName"] = "Cannot be empty";
    }
    //websiteUrl
    if (!fieldsUpdatePassword["websiteUrl"] || fieldsUpdatePassword["websiteUrl"] === "") {
      formIsValid = false;
      errorsUpdatePassword["websiteUrl"] = "Cannot be empty";
    }
    if (!fieldsUpdatePassword["demoId"] || fieldsUpdatePassword["demoId"] === "") {
      formIsValid = false;
      errorsUpdatePassword["demoId"] = "Cannot be empty";
    }
    if (!fieldsUpdatePassword["demoPass"] || fieldsUpdatePassword["demoPass"] === "") {
      formIsValid = false;
      errorsUpdatePassword["demoPass"] = "Cannot be empty";
    }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

      this.setState({
        imageTemp: URL.createObjectURL(event.target.files[event.target.files.length - 1])
      });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['websitelogo'] = imageWebLink;
    fieldsUpdatePassword['websitelogo'] = imageWebLink;
    fieldsAddUser['websiteLogoUrl'] = imageUrl;
    fieldsUpdatePassword['websiteLogoUrl'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }




  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };


  onClose = () => {


    this.setState({ txData: {}, approveTx: false })

  }

  approveWithdrawWithImage = () => {
    let reqData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }
    let { users } = this.props;
    let { filesDetails } = users;
    this.props.dispatch(websiteActions.approveTicket({
      "id": this.state.txData.id,
      "status": 1,
      "reply": this.state.fieldsAddUser.reply,
      "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
    }, reqData))
  }

  approveWithdraw = () => {
    let reqData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "type": "WALLET_REQ"
    }

    this.props.dispatch(websiteActions.approveTicket({
      "id": this.state.txData.id,
      "status": 1,
      "reply": this.state.fieldsAddUser.reply,
    }, reqData))
  }

  approveTicket = (data) => {


    this.setState({ txData: data, approveTx: true })

    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     let { users } = this.props;


    //     let { filesDetails } = users;
    //     return (

    //       <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
    //         <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
    //           <div className="modal-content py-4 text-left px-6">
    //             <div className="my-5">
    //               {data && data.txType === "WITHDRAW" ? <>
    //                 <div autoComplete="off">
    //                   <div className="m-2 ">

    //                     <div className="mt-1  shadow-sm relative">
    //                       <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
    //                         placeholder="Enter reason ..." type="text" name="reply" value={this.state.fieldsAddUser.reply} onChange={this.inputAddUserChange} />
    //                     </div>
    //                   </div>
    //                   <div className="m-2 ">
    //                     <div className="mt-1  shadow-sm relative">
    //                       <span>
    //                         {this.state.imageTemp}
    //                         1111  <img src={this.state.imageTemp} />
    //                         <label htmlFor="image">
    //                           Upload Logo
    //                         </label>
    //                         <input
    //                           style={{ display: 'none' }}
    //                           id="image"
    //                           name="image"
    //                           type="file"
    //                           onChange={this.handleFile}
    //                         />
    //                       </span>
    //                     </div>
    //                   </div>

    //                   <>
    //                     <span class="inline-block pl-1">
    //                       <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
    //                     </span>
    //                     <span className="inline-block pl-1 text-center">
    //                       {JSON.stringify(filesDetails)}
    //                       <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => {
    //                         let reqData = {
    //                           "keyWord": this.state.keyWord,
    //                           "pageNo": this.state.pageNo,
    //                           "size": this.state.size,
    //                           "type": "WALLET_REQ"
    //                         }
    //                         let { users } = this.props;


    //                         let { filesDetails } = users;
    //                         console.log('datadatadatadatadatadata111111111111111111', filesDetails);


    //                         this.props.dispatch(websiteActions.approveTicket({
    //                           "id": data.id,
    //                           "status": 1,
    //                           "reply": this.state.fieldsAddUser.reply,
    //                           "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
    //                         }, reqData))
    //                         onClose();
    //                       }}>Approve</button>


    //                       <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => {

    //                         let { filesDetails } = users;
    //                         console.log('datadatadatadatadatadata111111111111111111', filesDetails);

    //                         // onClose();
    //                       }}>View Image</button>
    //                     </span>
    //                   </>
    //                 </div>
    //               </>
    //                 :
    //                 <>
    //                   <div autoComplete="off">
    //                     <div className="m-2 ">

    //                       <div className="mt-1  shadow-sm relative">
    //                         <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
    //                           placeholder="Enter reason ..." type="text" name="reply" value={this.state.fieldsAddUser.reply} onChange={this.inputAddUserChange} />
    //                       </div>
    //                     </div>

    //                     <>
    //                       <span class="inline-block pl-1">
    //                         <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
    //                       </span>
    //                       <span className="inline-block pl-1 text-center">
    //                         <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => {
    //                           let reqData = {
    //                             "keyWord": this.state.keyWord,
    //                             "pageNo": this.state.pageNo,
    //                             "size": this.state.size,
    //                             "type": "WALLET_REQ"
    //                           }

    //                           this.props.dispatch(websiteActions.approveTicket({
    //                             "id": data.id,
    //                             "status": 1,
    //                             "reply": this.state.fieldsAddUser.reply,
    //                           }, reqData))
    //                           onClose();
    //                         }}>Approve</button>
    //                       </span>
    //                     </>


    //                   </div>
    //                 </>
    //               }


    //             </div>
    //             {/*Footer*/}
    //           </div>
    //         </div>
    //       </div >

    //     );
    //   }
    // });
  }



  rejectTicket = (data) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
            <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded  z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="my-5">
                  <div autoComplete="off">
                    <div className="m-2 ">
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter reason ..." type="text" name="reply" value={this.state.fieldsAddUser.reply} onChange={this.inputAddUserChange} />
                      </div>
                    </div>
                    <>
                      <span class="inline-block pl-1">
                        <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
                      </span>
                      <span className="inline-block pl-1 text-center">
                        <button class="bg-red-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => {
                          let reqData = {
                            "keyWord": this.state.keyWord,
                            "pageNo": this.state.pageNo,
                            "size": this.state.size,
                            "type": "WALLET_REQ"
                          }
                          this.props.dispatch(websiteActions.rejectTicket({
                            "id": data.id,
                            "status": 2,
                            "reply": this.state.fieldsAddUser.reply,
                          }, reqData))
                          onClose();
                        }}>Reject</button>
                      </span>
                    </>


                  </div>

                </div>
                {/*Footer*/}
              </div>
            </div>
          </div>

        );
      }
    });
  }

  render() {

    let { website, users } = this.props;
    let { ticketItems, websiteTotal, loading, getWebsiteList, ticketTotal } = website;


    let { filesDetails } = users;

    console.log("RENDER_______items-------------------------------------------", ticketItems);
    console.log("imageTemp-------------------------------------------", this.state.imageTemp);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-1 overflow-hidden overflow-x-auto overflow-y-auto bg-gray-100">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md ">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">WALLET TICKET MANAGEMENT</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      {/* <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add Website </button> */}

                    </div>

                  </div>

                  <div className="pb-2 mt-4 overflow-hidden ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Request From </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Username</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Mode</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Transaction Type</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Amount</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Image</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Updated At</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Reply</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">More</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Status</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                ticketItems && ticketItems.length > 0 ?
                                  ticketItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId && element.userId.mobile ? element.userId.mobile : "-"} </td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.websiteId && element.websiteId.websiteUrl ? element.websiteId.websiteUrl : "-"} </td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId ? element.userId.userName : "-"} </td>
                                      
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.userId && element.mode ? element.mode : "-"} </td> */}
                                      {/* {element && element.upiId && element.upiId !== null ?
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element.upiId.upi}</td>
                                        :
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.bankId ? element.bankId.acHolder : "-"}</td>
                                      } */}

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className=''>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "-"} alt="not found" />
                                        </div>
                                      </td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.updatedAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.reply ? element.reply : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"><span className="relative">
                                        <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                          <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>

                                          <CgMoreVerticalO style={{ fontSize: "1.5rem" }} />
                                        </div>
                                      </span></td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {
                                        element && element.status !== 0 ?
                                          <>
                                            {element.status === 1 ?
                                              <div className="pt-1 text-left">
                                                <span class="bg-green-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Approved</span>

                                              </div>
                                              :
                                              <div className="pt-1 text-left">
                                                <span class="bg-green-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Rejected</span>

                                              </div>
                                            }

                                          </>
                                          :
                                          <div className="pt-1 text-left">
                                            <span class="bg-yellow-500 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Pending</span>

                                          </div>
                                      }</td> */}




                                      <td className="flex justify-center px-6 py-3 space-x-2 text-center text-gray-600  whitespace-nowrap">
                                        <div className='flex space-x-2'>


                                          {element && element.status === 0 ? <>
                                            <span className="relative">
                                              <div className="pt-1 text-left">
                                                <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded"
                                                  onClick={() => this.approveTicket(element)} >Approve</button>

                                              </div>
                                            </span>
                                            <span className="relative">
                                              <div className="pt-1 text-left">
                                                <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 mr-1 border border-blue-700 rounded"
                                                  onClick={() => this.rejectTicket(element)}>Reject</button>

                                              </div>
                                            </span></>
                                            : <>
                                              {element.status === 1 ?
                                                <div className="pt-1 text-left">
                                                  <span class="text-green-600 font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Approved</span>

                                                </div>
                                                :
                                                <div className="pt-1 text-left">
                                                  <span class="text-red-600 font-bold py-1 px-2 mr-1 border border-blue-700 rounded" >Rejected</span>

                                                </div>
                                              }

                                            </>}


                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            ticketTotal && ticketTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={ticketTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            ticketTotal && ticketTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={ticketTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>


        <CreateAddDealerModal
          options={options}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          // allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          // allGameItems={allGameItems}
          inputAddUserChange={this.inputAddUserChange}
          addWebsiteSubmit={this.addWebsiteSubmit}
          handleFile={this.handleFile}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />

        <CreateUpdatePasswordDealer
          options={options}
          // allCategory={allCategory}
          offset={this.state.offset}
          updateSelectedTag={this.state.updateSelectedTag}
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          // allGameItems={allGameItems}
          inputChange={this.inputChange}
          handleFile={this.handleFile}
          updateWebsiteSubmit={this.updateWebsiteSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          contentFieldUpdate={this.contentFieldUpdate}
          deleteContentFieldUpdate={this.deleteContentFieldUpdate}
          setCurrentIndexUpdate={this.setCurrentIndexUpdate}
          inputChangeContentUpdate={this.inputChangeContentUpdate}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          modules={this.modules}
          formats={this.formats}
          rteChangeUpdate={this.rteChangeUpdate}
        />

        <ViewMoreDetailsModal
          options={options}
          offset={this.state.offset}
          // allCategory={allCategory}
          modules={this.modules}
          formats={this.formats}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          sliderRowData={this.state.sliderRowData}
          updateSelectedTag={this.state.updateSelectedTag}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />


        <Modal
          isOpen={this.state.approveTx}
        // contentLabel="Example Modal"
        >


          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
            <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="my-5">
                  {this.state.txData && this.state.txData.txType === "WITHDRAW" ? <>
                    <div autoComplete="off">
                      <div className="m-2 ">

                        <div className="mt-1  shadow-sm relative">
                          <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                            placeholder="Enter reason ..." type="text" name="reply" value={this.state.fieldsAddUser.reply} onChange={this.inputAddUserChange} />
                        </div>
                      </div>
                      <div className="m-2 ">
                        <div className="mt-1 relative">
                          <div className='flex justify-between'>
                            <div className='w-56 h-36 mb-2'>
                              <img className='w-56 h-36' src={this.state.imageTemp} />
                            </div>
                            <label className='bg-yellow-500 px-2 py-3 rounded h-6 flex justify-center items-center mt-14' htmlFor="image">
                              Upload Logo
                            </label>
                            <input
                              style={{ display: 'none' }}
                              id="image"
                              name="image"
                              type="file"
                              onChange={this.handleFile}
                            />
                          </div>
                        </div>
                      </div>

                      <>
                        <span class="inline-block pl-1">
                          <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={() => this.onClose()}>No</button>
                        </span>
                        <span className="inline-block pl-1 text-center">
                          {/* {JSON.stringify(filesDetails)} */}
                          <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700"
                            onClick={this.approveWithdrawWithImage}
                          // onClick={() => {
                          //   let reqData = {
                          //     "keyWord": this.state.keyWord,
                          //     "pageNo": this.state.pageNo,
                          //     "size": this.state.size,
                          //     "type": "WALLET_REQ"
                          //   }
                          //   let { users } = this.props;


                          //   let { filesDetails } = users;
                          //   console.log('datadatadatadatadatadata111111111111111111', filesDetails);


                          //   this.props.dispatch(websiteActions.approveTicket({
                          //     "id": data.id,
                          //     "status": 1,
                          //     "reply": this.state.fieldsAddUser.reply,
                          //     "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
                          //   }, reqData))
                          //   onClose();
                          // }}

                          >Approve</button>


                          {/* <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => {

                            let { filesDetails } = users;
                            console.log('datadatadatadatadatadata111111111111111111', filesDetails);

                            // onClose();
                          }}>View Image</button> */}
                        </span>
                      </>
                    </div>
                  </>
                    :
                    <>
                      <div autoComplete="off">
                        <div className="m-2 ">

                          <div className="mt-1  shadow-sm relative">
                            <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                              placeholder="Enter reason ..." type="text" name="reply" value={this.state.fieldsAddUser.reply} onChange={this.inputAddUserChange} />
                          </div>
                        </div>

                        <>
                          <span class="inline-block pl-1">
                            <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={this.onClose}>No</button>
                          </span>
                          <span className="inline-block pl-1 text-center">
                            <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-green-700"
                              onClick={this.approveWithdraw}
                            // onClick={() => {
                            //   let reqData = {
                            //     "keyWord": this.state.keyWord,
                            //     "pageNo": this.state.pageNo,
                            //     "size": this.state.size,
                            //     "type": "WALLET_REQ"
                            //   }

                            //   this.props.dispatch(websiteActions.approveTicket({
                            //     "id": data.id,
                            //     "status": 1,
                            //     "reply": this.state.fieldsAddUser.reply,
                            //   }, reqData))
                            //   onClose();
                            // }}

                            >Approve</button>
                          </span>
                        </>


                      </div>
                    </>
                  }


                </div>
                {/*Footer*/}
              </div>
            </div>
          </div >


        </Modal>
      </>

    );
  }
}
function mapStateToProps(state) {
  const { website, users, category, news } = state;
  return {
    website,
    users,
    category,
    news
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Website);
