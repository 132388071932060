export const eventOptionConstants = {

    GETALL_EVENTOPTION_REQUEST: 'GETALL_EVENTOPTION_REQUEST',
    GETALL_EVENTOPTION_SUCCESS: 'GETALL_EVENTOPTION_SUCCESS',
    GETALL_EVENTOPTION_FAILURE: 'GETALL_EVENTOPTION_FAILURE',

    GET_LIST_EVENTOPTION_REQUEST: 'GET_LIST_EVENTOPTION_REQUEST',
    GET_LIST_EVENTOPTION_SUCCESS: 'GET_LIST_EVENTOPTION_SUCCESS',
    GET_LIST_EVENTOPTION_FAILURE: 'GET_LIST_EVENTOPTION_FAILURE',

    GET_YES_LIST_EVENTOPTION_REQUEST: 'GET_YES_LIST_EVENTOPTION_REQUEST',
    GET_YES_LIST_EVENTOPTION_SUCCESS: 'GET_YES_LIST_EVENTOPTION_SUCCESS',
    GET_YES_LIST_EVENTOPTION_FAILURE: 'GET_YES_LIST_EVENTOPTION_FAILURE',

    GET_NO_LIST_EVENTOPTION_REQUEST: 'GET_NO_LIST_EVENTOPTION_REQUEST',
    GET_NO_LIST_EVENTOPTION_SUCCESS: 'GET_NO_LIST_EVENTOPTION_SUCCESS',
    GET_NO_LIST_EVENTOPTION_FAILURE: 'GET_NO_LIST_EVENTOPTION_FAILURE',

    ADD_EVENTOPTION_REQUEST: 'ADD_EVENTOPTION_REQUEST',
    ADD_EVENTOPTION_SUCCESS: 'ADD_EVENTOPTION_SUCCESS',
    ADD_EVENTOPTION_FAILURE: 'ADD_EVENTOPTION_FAILURE',

    DELETE_EVENTOPTION_REQUEST: 'DELETE_EVENTOPTION_REQUEST',
    DELETE_EVENTOPTION_SUCCESS: 'DELETE_EVENTOPTION_SUCCESS',
    DELETE_EVENTOPTION_FAILURE: 'DELETE_EVENTOPTION_FAILURE',


    UPDATE_EVENTOPTION_REQUEST: 'UPDATE_EVENTOPTION_REQUEST',
    UPDATE_EVENTOPTION_SUCCESS: 'UPDATE_EVENTOPTION_SUCCESS',
    UPDATE_EVENTOPTION_FAILURE: 'UPDATE_EVENTOPTION_FAILURE',


    DISABLE_EVENTOPTION_REQUEST: 'DISABLE_EVENTOPTION_REQUEST',
    DISABLE_EVENTOPTION_SUCCESS: 'DISABLE_EVENTOPTION_SUCCESS',
    DISABLE_EVENTOPTION_FAILURE: 'DISABLE_EVENTOPTION_FAILURE',

    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',
};
