import { userConstants, sliderConstants, paymentTypeConstants } from '../_constants';
import { userService, sliderService, paymentService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const paymentTypeAction = {

    login,
    logout,
    disableSlider,
    getAllSlider,
    createPaymentType,
    updatePaymentType,
    getPaymentTypeList,
    updatePaymentTypeStatus,
    getReachOutList,

    deletePaymentType,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createPaymentType(data, reqData) {
    console.log('data, reqDatadata, reqData_________', data, reqData);

    return dispatch => {
        dispatch(request());
        paymentService.createPayment(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("PaymentType Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPaymentTypeList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: paymentTypeConstants.ADD_PAYMENT_REQUEST } }
    function success(users) { return { type: paymentTypeConstants.ADD_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: paymentTypeConstants.ADD_PAYMENT_FAILURE, error } }
}
function updatePaymentType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        paymentService.updatePayment(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Payment type Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPaymentTypeList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: paymentTypeConstants.UPDATE_PAYMENT_REQUEST } }
    function success(users) { return { type: paymentTypeConstants.UPDATE_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: paymentTypeConstants.UPDATE_PAYMENT_FAILURE, error } }
}
function getAllSlider() {
    // console.log("data m kya aa rha h::action:::::::::", data);
    return dispatch => {
        dispatch(request());
        sliderService.getAllSlider()
            .then(
                users => {
                    console.log("getAllSlider $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.GETALL_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.GETALL_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.GETALL_SLIDER_FAILURE, error } }
}
function getPaymentTypeList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        paymentService.getPaymentList(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: paymentTypeConstants.GET_LIST_PAYMENT_REQUEST } }
    function success(users) { return { type: paymentTypeConstants.GET_LIST_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: paymentTypeConstants.GET_LIST_PAYMENT_FAILURE, error } }
}
function getReachOutList(data) {
    console.log("data m kya aa rha h::action::1111111111111111111111111111111111111111111111111:", data);
    return dispatch => {
        dispatch(request());
        sliderService.getReachOutList(data)
            .then(
                users => {
                    console.log("getReachOutList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.GET_REACHOUT_LIST_REQUEST } }
    function success(users) { return { type: sliderConstants.GET_REACHOUT_LIST_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.GET_REACHOUT_LIST_FAILURE, error } }
}

function deletePaymentType(data, paginationData) {
    return dispatch => {
        dispatch(request());
        paymentService.deletePayment(data)
            .then(
                users => {
                    dispatch(alertActions.success('Delete Successfully'));
                    dispatch(success(users));
                    dispatch(this.getPaymentTypeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: paymentTypeConstants.DELETE_PAYMENT_REQUEST } }
    function success(users) { return { type: paymentTypeConstants.DELETE_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: paymentTypeConstants.DELETE_PAYMENT_FAILURE, error } }
}


function updatePaymentTypeStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        paymentService.updatePaymentTypeStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getPaymentTypeList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: paymentTypeConstants.DISABLE_PAYMENT_REQUEST } }
    function success(users) { return { type: paymentTypeConstants.DISABLE_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: paymentTypeConstants.DISABLE_PAYMENT_FAILURE, error } }
}
function disableSlider(data, paginationData) {

    return dispatch => {
        dispatch(request());
        sliderService.disableSlider(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.DISABLE_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.DISABLE_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.DISABLE_SLIDER_FAILURE, error } }
}

























