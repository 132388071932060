export const newsConstants = {

    GET_ALL_GAME_TYPE_REQUEST: 'GET_ALL_GAME_TYPE_REQUEST',
    GET_ALL_GAME_TYPE_SUCCESS: 'GET_ALL_GAME_TYPE_SUCCESS',
    GET_ALL_GAME_TYPE_FAILURE: 'GET_ALL_GAME_TYPE_FAILURE',

    GETALL_NEWS_REQUEST: 'GETALL_NEWS_REQUEST',
    GETALL_NEWS_SUCCESS: 'GETALL_NEWS_SUCCESS',
    GETALL_NEWS_FAILURE: 'GETALL_NEWS_FAILURE',

    GET_GAME_TYPE_BY_ID_REQUEST: 'GET_GAME_TYPE_BY_ID_REQUEST',
    GET_GAME_TYPE_BY_ID_SUCCESS: 'GET_GAME_TYPE_BY_ID_SUCCESS',
    GET_GAME_TYPE_BY_ID_FAILURE: 'GET_GAME_TYPE_BY_ID_FAILURE',

    GET_LIST_NEWS_REQUEST: 'GET_LIST_NEWS_REQUEST',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILURE: 'GET_LIST_NEWS_FAILURE',

    GET_LIST_NEWS_BY_ID_REQUEST: 'GET_LIST_NEWS_BY_ID_REQUEST',
    GET_LIST_NEWS_BY_ID_SUCCESS: 'GET_LIST_NEWS_BY_ID_SUCCESS',
    GET_LIST_NEWS_BY_ID_FAILURE: 'GET_LIST_NEWS_BY_ID_FAILURE',

    ADD_GAME_TYPE_REQUEST: 'ADD_GAME_TYPE_REQUEST',
    ADD_GAME_TYPE_SUCCESS: 'ADD_GAME_TYPE_SUCCESS',
    ADD_GAME_TYPE_FAILURE: 'ADD_GAME_TYPE_FAILURE',

    UPDATE_GAME_TYPE_REQUEST: 'UPDATE_GAME_TYPE_REQUEST',
    UPDATE_GAME_TYPE_SUCCESS: 'UPDATE_GAME_TYPE_SUCCESS',
    UPDATE_GAME_TYPE_FAILURE: 'UPDATE_GAME_TYPE_FAILURE',

    GET_GAME_TYPE_LIST_REQUEST: 'GET_GAME_TYPE_LIST_REQUEST',
    GET_GAME_TYPE_LIST_SUCCESS: 'GET_GAME_TYPE_LIST_SUCCESS',
    GET_GAME_TYPE_LIST_FAILURE: 'GET_GAME_TYPE_LIST_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    DELETE_GAME_TYPE_REQUEST: 'DELETE_GAME_TYPE_REQUEST',
    DELETE_GAME_TYPE_SUCCESS: 'DELETE_GAME_TYPE_SUCCESS',
    DELETE_GAME_TYPE_FAILURE: 'DELETE_GAME_TYPE_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

    UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
    UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',

    DISABLE_NEWS_REQUEST: 'DISABLE_NEWS_REQUEST',
    DISABLE_NEWS_SUCCESS: 'DISABLE_NEWS_SUCCESS',
    DISABLE_NEWS_FAILURE: 'DISABLE_NEWS_FAILURE',

    DISABLE_NOTIFICATION_REQUEST: 'DISABLE_NOTIFICATION_REQUEST',
    DISABLE_NOTIFICATION_SUCCESS: 'DISABLE_NOTIFICATION_SUCCESS',
    DISABLE_NOTIFICATION_FAILURE: 'DISABLE_NOTIFICATION_FAILURE',


    GET_LIST_NOTIFICATION_REQUEST: 'GET_LIST_NOTIFICATION_REQUEST',
    GET_LIST_NOTIFICATION_SUCCESS: 'GET_LIST_NOTIFICATION_SUCCESS',
    GET_LIST_NOTIFICATION_FAILURE: 'GET_LIST_NOTIFICATION_FAILURE',

};
