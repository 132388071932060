import { userConstants, sliderConstants } from '../_constants';
import { userService, sliderService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const sliderActions = {

    login,
    logout,
    disableSlider,
    getAllSlider,
    createSlider,
    updateSlider,
    getSliderList,
    getReachOutList,
    deleteReachOut,
    disableReachout,
    deleteSlider,
    uploadImageClear,
    notifyAllUser
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createSlider(data, reqData) {
    console.log('data, reqDatadata, reqData_________', data, reqData);

    return dispatch => {
        dispatch(request());
        sliderService.createSlider(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Slider Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSliderList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.ADD_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.ADD_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.ADD_SLIDER_FAILURE, error } }
}

function notifyAllUser(data) {
    return dispatch => {
        dispatch(request());
        sliderService.notifyAllUser(data)
            .then(
                users => {
                    dispatch(alertActions.success("Notify Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.NOTIFY_REQUEST } }
    function success(users) { return { type: sliderConstants.NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.NOTIFY_FAILURE, error } }
}
function updateSlider(data, paginationData) {

    return dispatch => {
        dispatch(request());
        sliderService.updateSlider(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Slider Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.UPDATE_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.UPDATE_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.UPDATE_SLIDER_FAILURE, error } }
}
function getAllSlider() {
    // console.log("data m kya aa rha h::action:::::::::", data);
    return dispatch => {
        dispatch(request());
        sliderService.getAllSlider()
            .then(
                users => {
                    console.log("getAllSlider $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.GETALL_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.GETALL_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.GETALL_SLIDER_FAILURE, error } }
}
function getSliderList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        sliderService.getSliderList(data)
            .then(
                users => {
                    console.log("getSliderList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.GET_LIST_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.GET_LIST_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.GET_LIST_SLIDER_FAILURE, error } }
}
function getReachOutList(data) {
    console.log("data m kya aa rha h::action::1111111111111111111111111111111111111111111111111:", data);
    return dispatch => {
        dispatch(request());
        sliderService.getReachOutList(data)
            .then(
                users => {
                    console.log("getReachOutList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.GET_REACHOUT_LIST_REQUEST } }
    function success(users) { return { type: sliderConstants.GET_REACHOUT_LIST_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.GET_REACHOUT_LIST_FAILURE, error } }
}

function deleteSlider(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        sliderService.deleteSlider(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.DELETE_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.DELETE_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.DELETE_SLIDER_FAILURE, error } }
}
function deleteReachOut(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        sliderService.deleteReachOut(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.DELETE_REACHOUT_REQUEST } }
    function success(users) { return { type: sliderConstants.DELETE_REACHOUT_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.DELETE_REACHOUT_FAILURE, error } }
}

function disableReachout(data, paginationData) {

    return dispatch => {
        dispatch(request());
        sliderService.disableReachout(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.DISABLE_REACHOUT_REQUEST } }
    function success(users) { return { type: sliderConstants.DISABLE_REACHOUT_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.DISABLE_REACHOUT_FAILURE, error } }
}
function disableSlider(data, paginationData) {

    return dispatch => {
        dispatch(request());
        sliderService.disableSlider(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getSliderList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: sliderConstants.DISABLE_SLIDER_REQUEST } }
    function success(users) { return { type: sliderConstants.DISABLE_SLIDER_SUCCESS, users } }
    function failure(error) { return { type: sliderConstants.DISABLE_SLIDER_FAILURE, error } }
}
