export const tripConstants = {

    GET_ALL_TRIP_REQUEST: 'GET_ALL_TRIP_REQUEST',
    GET_ALL_TRIP_SUCCESS: 'GET_ALL_TRIP_SUCCESS',
    GET_ALL_TRIP_FAILURE: 'GET_ALL_TRIP_FAILURE',

    GET_TRIP_BY_CATEGORY_REQUEST: 'GET_TRIP_BY_CATEGORY_REQUEST',
    GET_TRIP_BY_CATEGORY_SUCCESS: 'GET_TRIP_BY_CATEGORY_SUCCESS',
    GET_TRIP_BY_CATEGORY_FAILURE: 'GET_TRIP_BY_CATEGORY_FAILURE',

    GET_TRIP_BY_ID_REQUEST: 'GET_TRIP_BY_ID_REQUEST',
    GET_TRIP_BY_ID_SUCCESS: 'GET_TRIP_BY_ID_SUCCESS',
    GET_TRIP_BY_ID_FAILURE: 'GET_TRIP_BY_ID_FAILURE',

    GET_LIST_NEWS_REQUEST: 'GET_LIST_NEWS_REQUEST',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILURE: 'GET_LIST_NEWS_FAILURE',

    GET_LIST_NEWS_BY_ID_REQUEST: 'GET_LIST_NEWS_BY_ID_REQUEST',
    GET_LIST_NEWS_BY_ID_SUCCESS: 'GET_LIST_NEWS_BY_ID_SUCCESS',
    GET_LIST_NEWS_BY_ID_FAILURE: 'GET_LIST_NEWS_BY_ID_FAILURE',

    ADD_TRIP_REQUEST: 'ADD_TRIP_REQUEST',
    ADD_TRIP_SUCCESS: 'ADD_TRIP_SUCCESS',
    ADD_TRIP_FAILURE: 'ADD_TRIP_FAILURE',

    UPDATE_TRIP_REQUEST: 'UPDATE_TRIP_REQUEST',
    UPDATE_TRIP_SUCCESS: 'UPDATE_TRIP_SUCCESS',
    UPDATE_TRIP_FAILURE: 'UPDATE_TRIP_FAILURE',

    GET_TRIP_LIST_REQUEST: 'GET_TRIP_LIST_REQUEST',
    GET_TRIP_LIST_SUCCESS: 'GET_TRIP_LIST_SUCCESS',
    GET_TRIP_LIST_FAILURE: 'GET_TRIP_LIST_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    DELETE_TRIP_REQUEST: 'DELETE_TRIP_REQUEST',
    DELETE_TRIP_SUCCESS: 'DELETE_TRIP_SUCCESS',
    DELETE_TRIP_FAILURE: 'DELETE_TRIP_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

    UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
    UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',

    DISABLE_TRIP_REQUEST: 'DISABLE_TRIP_REQUEST',
    DISABLE_TRIP_SUCCESS: 'DISABLE_TRIP_SUCCESS',
    DISABLE_TRIP_FAILURE: 'DISABLE_TRIP_FAILURE',

    DISABLE_NOTIFICATION_REQUEST: 'DISABLE_NOTIFICATION_REQUEST',
    DISABLE_NOTIFICATION_SUCCESS: 'DISABLE_NOTIFICATION_SUCCESS',
    DISABLE_NOTIFICATION_FAILURE: 'DISABLE_NOTIFICATION_FAILURE',


    GET_LIST_NOTIFICATION_REQUEST: 'GET_LIST_NOTIFICATION_REQUEST',
    GET_LIST_NOTIFICATION_SUCCESS: 'GET_LIST_NOTIFICATION_SUCCESS',
    GET_LIST_NOTIFICATION_FAILURE: 'GET_LIST_NOTIFICATION_FAILURE',

};
