import React from "react";
import Modal from 'react-modal';
// import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';


export default function UpdateVideoModal(props) {

  let { UpdateVideoCreateModal, handleUpdatePasswordHideModal, errorsUpdateVideo, inputChangeUpdateCategory, updateVideoSubmit, fieldsUpdateVideo, handleFileVideo, filesDetails, handleFile } = props;
  console.log("modal::fieldsUpdateVideo", fieldsUpdateVideo);

  return (


    <Modal
      isOpen={UpdateVideoCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Update Video</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleUpdatePasswordHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">

                <div class="flex justify-center">
                  <div class="mb-3 ">
                    <label className="block text-gray-700 text-base font-bold mb-2">Video :</label>
                    <select class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal  text-gray-700  bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" name="type" value={fieldsUpdateVideo.type} onChange={inputChangeUpdateCategory} >
                      <option selected>Open this select menu</option>
                      <option value="VIDEO">Upload Video</option>
                      <option value="YOUTUBE">Add With Youtube Link</option>
                    </select>
                    {errorsUpdateVideo && errorsUpdateVideo["type"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["type"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Video Id</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["video_id"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="video_id" name="video_id" placeholder="Video Id" value={fieldsUpdateVideo.video_id} type="text"
                      onChange={inputChangeUpdateCategory}
                      disabled
                    />
                    {errorsUpdateVideo && errorsUpdateVideo["video_id"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["video_id"]}
                      </div>
                      : null}

                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Title</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["title"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="title" name="title" placeholder="Title" value={fieldsUpdateVideo.title} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["title"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["title"]}
                      </div>
                      : null}

                  </div>
                </div>


                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Description</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["description"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="description" name="description" placeholder="Description" value={fieldsUpdateVideo.description} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["description"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["description"]}
                      </div>
                      : null}

                  </div>
                </div>

                {/* <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Video Link</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["videoFileName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="videoFileName" name="videoFileName" placeholder="Video" value={fieldsUpdateVideo.videoFileName} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["videoFileName"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["videoFileName"]}
                      </div>
                      : null}

                  </div>
                </div> */}


                {
                  fieldsUpdateVideo && fieldsUpdateVideo.type && fieldsUpdateVideo.type === "VIDEO" ?
                    <>
                      <div className="{otpSent?'disableArea':''}">
                        <div className="mt-1  shadow-sm relative">
                          <label class="block text-gray-700 text-base font-bold mb-2" for="username">Video Link</label>
                          <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["videoFileName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                            id="videoFileName" name="videoFileName" placeholder="Video"
                            disabled
                            value={filesDetails && filesDetails.imageName ? filesDetails.imageName : fieldsUpdateVideo && fieldsUpdateVideo.videoFileName ? fieldsUpdateVideo.videoFileName : null}
                            type="text" />
                          {errorsUpdateVideo && errorsUpdateVideo["videoFileName"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {errorsUpdateVideo["videoFileName"]}
                            </div>
                            : null}

                        </div>
                      </div>
                    </> :
                    <>
                      <div className="{otpSent?'disableArea':''}">
                        <div className="mt-1  shadow-sm relative">
                          <label class="block text-gray-700 text-base font-bold mb-2" for="username">Video Link</label>
                          <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["videoFileName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                            id="videoFileName" name="videoFileName" placeholder="Video" value={fieldsUpdateVideo.videoFileName} type="text" onChange={inputChangeUpdateCategory} />
                          {errorsUpdateVideo && errorsUpdateVideo["videoFileName"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {errorsUpdateVideo["videoFileName"]}
                            </div>
                            : null}

                        </div>
                      </div>
                    </>

                }

                {
                  fieldsUpdateVideo && fieldsUpdateVideo.type && fieldsUpdateVideo.type === "VIDEO" ?
                    <>
                      <div className="{otpSent?'disableArea':''}">
                        <div className="mt-1  shadow-sm relative">
                          <label htmlFor="image">
                            Upload Video
                          </label>
                          <input
                            id="image"
                            name="image"
                            type="file"
                            onChange={handleFileVideo}
                          />
                        </div>
                      </div>
                    </> :
                    null
                }


                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <label class="block text-gray-700 text-base font-bold mb-2" for="username">Author</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateVideo && !errorsUpdateVideo["author"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="author" name="author" placeholder="Author" value={fieldsUpdateVideo.author} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateVideo && errorsUpdateVideo["author"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateVideo["author"]}
                      </div>
                      : null}

                  </div>
                </div>
                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input
                    // style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>

                {/* <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
            <Multiselect
              options={options}
              onSelect={handleSelectedTag}
              selected={selectedTag}
              isObject={false}
              // disableSearch={true}
              // renderHeader={true}
              displayValue="label"
            // value={this.state.selected ? this.state.selected : null}
            />
          </div> */}

                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateVideoSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
