import {
  userConstants, upiConstants
} from '../_constants';

export function upi(state = {}, action) {
  console.log('action___________action', action);

  switch (action.type) {
    case upiConstants.GET_UPI_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case upiConstants.GET_UPI_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        upiListItems: action.users.getUpiList.list,
        upiListTotal: action.users.getUpiList.total,
      };
    case upiConstants.GET_UPI_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,

      };

    case upiConstants.UPDATE_UPI_REQUEST:
      return {
        ...state
      };
    case upiConstants.UPDATE_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case upiConstants.UPDATE_UPI_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case upiConstants.ADD_UPI_REQUEST:
      return {
        ...state
      };
    case upiConstants.ADD_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case upiConstants.ADD_UPI_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case upiConstants.GET_LIST_UPI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case upiConstants.GET_LIST_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUpiList.list,
        total: action.users.getUpiList.list
      };
    case upiConstants.GET_LIST_UPI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case upiConstants.GETALL_UPI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case upiConstants.GETALL_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        videoItems: action.users.getAllvideo,
        videoTotal: action.users.getAllvideo
      };
    case upiConstants.GETALL_UPI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_UPI_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_UPI_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_UPI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_UPI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_UPI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_UPI_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_UPI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}