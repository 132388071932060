import { userConstants, eventOptionConstants } from '../_constants';
import { userService, eventOptionService } from '../_services';
import { toast } from 'react-toastify';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const eventOptionActions = {
    disableEventOption,
    getAllEventOption,
    createEventOption,
    updateEventOption,
    getEventOptionList,
    getEventOptionNoList,
    getEventOptionYesList,
    deleteEventOption,
    uploadImageClear,
    uploadImage
};

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: eventOptionConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: eventOptionConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: eventOptionConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}


function createEventOption(data, reqData) {
    console.log('data, reqDatadata, reqData_________', data, reqData);

    return dispatch => {
        dispatch(request());
        eventOptionService.createEventOption(data)
            .then(

                users => {
                    dispatch(alertActions.success("EventOption Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEventOptionList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.ADD_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.ADD_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.ADD_EVENTOPTION_FAILURE, error } }
}
function updateEventOption(data, paginationData) {

    return dispatch => {
        dispatch(request());
        eventOptionService.updateEventOption(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("EventOption Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getEventOptionList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.UPDATE_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.UPDATE_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.UPDATE_EVENTOPTION_FAILURE, error } }
}
function getAllEventOption() {
    // console.log("data m kya aa rha h::action:::::::::", data);
    return dispatch => {
        dispatch(request());
        eventOptionService.getAllEventOption()
            .then(
                users => {
                    console.log("getAllEventOption $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.GETALL_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.GETALL_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.GETALL_EVENTOPTION_FAILURE, error } }
}
function getEventOptionYesList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        eventOptionService.getEventOptionYesList(data)
            .then(
                users => {
                    console.log("getEventOptionYesList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.GET_YES_LIST_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.GET_YES_LIST_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.GET_YES_LIST_EVENTOPTION_FAILURE, error } }
}
function getEventOptionNoList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        eventOptionService.getEventOptionNoList(data)
            .then(
                users => {
                    console.log("getEventOptionNoList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.GET_NO_LIST_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.GET_NO_LIST_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.GET_NO_LIST_EVENTOPTION_FAILURE, error } }
}
function getEventOptionList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        eventOptionService.getEventOptionList(data)
            .then(
                users => {
                    console.log("getEventOptionList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.GET_LIST_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.GET_LIST_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.GET_LIST_EVENTOPTION_FAILURE, error } }
}
function deleteEventOption(data, paginationData) {
    return dispatch => {
        dispatch(request());
        eventOptionService.deleteEventOption(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("EventOption Delete Successfully."));
                    dispatch(this.getEventOptionList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.DELETE_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.DELETE_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.DELETE_EVENTOPTION_FAILURE, error } }
}
function disableEventOption(data, paginationData) {

    return dispatch => {
        dispatch(request());
        eventOptionService.disableEventOption(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("EventOption Status Changed Successfully."));
                    dispatch(this.getEventOptionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: eventOptionConstants.DISABLE_EVENTOPTION_REQUEST } }
    function success(users) { return { type: eventOptionConstants.DISABLE_EVENTOPTION_SUCCESS, users } }
    function failure(error) { return { type: eventOptionConstants.DISABLE_EVENTOPTION_FAILURE, error } }
}
