import { userConstants, offerConstants } from '../_constants';
import { userService, offerService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const offerActions = {

    login,
    logout,
    getOffersList,
    disableOffer,
    deleteOffer,
    getAllBet,
    getAllUser,
    createOffers,
    updateBet,
    uploadImageClear,
    getAllBetByBetTypeId,
    updateSlider
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createOffers(data, reqData) {

    return dispatch => {
        dispatch(request());
        offerService.createOffers(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Offer Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getOffersList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.ADD_OFFER_REQUEST } }
    function success(users) { return { type: offerConstants.ADD_OFFER_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.ADD_OFFER_FAILURE, error } }
}
function updateSlider(data, paginationData) {

    return dispatch => {
        dispatch(request());
        offerService.updateSlider(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Offer Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getOffersList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.UPDATE_OFFER_REQUEST } }
    function success(users) { return { type: offerConstants.UPDATE_OFFER_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.UPDATE_OFFER_FAILURE, error } }
}
function updateBet(data, paginationData) {

    return dispatch => {
        dispatch(request());
        offerService.updateBet(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Offer Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getOffersList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.UPDATE_BET_REQUEST } }
    function success(users) { return { type: offerConstants.UPDATE_BET_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.UPDATE_BET_FAILURE, error } }
}
function getAllBet(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        offerService.getAllBet(data)
            .then(
                users => {
                    console.log("getAllBet $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.GETALL_BET_REQUEST } }
    function success(users) { return { type: offerConstants.GETALL_BET_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.GETALL_BET_FAILURE, error } }
}
function getAllUser(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        offerService.getAllUser(data)
            .then(
                users => {
                    console.log("getAllUser $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.GETALL_USER_REQUEST } }
    function success(users) { return { type: offerConstants.GETALL_USER_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.GETALL_USER_FAILURE, error } }
}
function getAllBetByBetTypeId(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        offerService.getAllBetByBetTypeId(data)
            .then(
                users => {
                    console.log("getAllBetByBetTypeId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.GETALL_BET_BY_ID_REQUEST } }
    function success(users) { return { type: offerConstants.GETALL_BET_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.GETALL_BET_BY_ID_FAILURE, error } }
}
function getOffersList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        offerService.getOffersList(data)
            .then(
                users => {
                    console.log("getOffersList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.GET_OFFER_LIST_REQUEST } }
    function success(users) { return { type: offerConstants.GET_OFFER_LIST_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.GET_OFFER_LIST_FAILURE, error } }
}

function deleteOffer(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        offerService.deleteOffer(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getOffersList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.DELETE_OFFER_REQUEST } }
    function success(users) { return { type: offerConstants.DELETE_OFFER_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.DELETE_OFFER_FAILURE, error } }
}
function disableOffer(data, paginationData) {

    return dispatch => {
        dispatch(request());
        offerService.disableOffer(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getOffersList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: offerConstants.DISABLE_OFFER_REQUEST } }
    function success(users) { return { type: offerConstants.DISABLE_OFFER_SUCCESS, users } }
    function failure(error) { return { type: offerConstants.DISABLE_OFFER_FAILURE, error } }
}
