export const betConstants = {

    GETALL_BET_REQUEST: 'GETALL_BET_REQUEST',
    GETALL_BET_SUCCESS: 'GETALL_BET_SUCCESS',
    GETALL_BET_FAILURE: 'GETALL_BET_FAILURE',

    GETALL_BET_BY_ID_REQUEST: 'GETALL_BET_BY_ID_REQUEST',
    GETALL_BET_BY_ID_SUCCESS: 'GETALL_BET_BY_ID_SUCCESS',
    GETALL_BET_BY_ID_FAILURE: 'GETALL_BET_BY_ID_FAILURE',

    GET_LIST_BET_REQUEST: 'GET_LIST_BET_REQUEST',
    GET_LIST_BET_SUCCESS: 'GET_LIST_BET_SUCCESS',
    GET_LIST_BET_FAILURE: 'GET_LIST_BET_FAILURE',

    GETALL_USER_REQUEST: 'GETALL_USER_REQUEST',
    GETALL_USER_SUCCESS: 'GETALL_USER_SUCCESS',
    GETALL_USER_FAILURE: 'GETALL_USER_FAILURE',

    ADD_BET_REQUEST: 'ADD_BET_REQUEST',
    ADD_BET_SUCCESS: 'ADD_BET_SUCCESS',
    ADD_BET_FAILURE: 'ADD_BET_FAILURE',

    DELETE_BET_REQUEST: 'DELETE_BET_REQUEST',
    DELETE_BET_SUCCESS: 'DELETE_BET_SUCCESS',
    DELETE_BET_FAILURE: 'DELETE_BET_FAILURE',

    UPDATE_BET_REQUEST: 'UPDATE_BET_REQUEST',
    UPDATE_BET_SUCCESS: 'UPDATE_BET_SUCCESS',
    UPDATE_BET_FAILURE: 'UPDATE_BET_FAILURE',

    DISABLE_BET_REQUEST: 'DISABLE_BET_REQUEST',
    DISABLE_BET_SUCCESS: 'DISABLE_BET_SUCCESS',
    DISABLE_BET_FAILURE: 'DISABLE_BET_FAILURE',

};
