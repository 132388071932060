export const fancyConstants = {

    GETALL_FANCY_REQUEST: 'GETALL_FANCY_REQUEST',
    GETALL_FANCY_SUCCESS: 'GETALL_FANCY_SUCCESS',
    GETALL_FANCY_FAILURE: 'GETALL_FANCY_FAILURE',

    GET_FANCY_By_Match_Id_REQUEST: 'GET_FANCY_By_Match_Id_REQUEST',
    GET_FANCY_By_Match_Id_SUCCESS: 'GET_FANCY_By_Match_Id_SUCCESS',
    GET_FANCY_By_Match_Id_FAILURE: 'GET_FANCY_By_Match_Id_FAILURE',
   
    ADD_FANCY_REQUEST: 'ADD_FANCY_REQUEST',
    ADD_FANCY_SUCCESS: 'ADD_FANCY_SUCCESS',
    ADD_FANCY_FAILURE: 'ADD_FANCY_FAILURE',

    DELETE_FANCY_REQUEST: 'DELETE_FANCY_REQUEST',
    DELETE_FANCY_SUCCESS: 'DELETE_FANCY_SUCCESS',
    DELETE_FANCY_FAILURE: 'DELETE_FANCY_FAILURE',

    UPDATE_FANCY_REQUEST: 'UPDATE_FANCY_REQUEST',
    UPDATE_FANCY_SUCCESS: 'UPDATE_FANCY_SUCCESS',
    UPDATE_FANCY_FAILURE: 'UPDATE_FANCY_FAILURE',

    DISABLE_FANCY_REQUEST: 'DISABLE_FANCY_REQUEST',
    DISABLE_FANCY_SUCCESS: 'DISABLE_FANCY_SUCCESS',
    DISABLE_FANCY_FAILURE: 'DISABLE_FANCY_FAILURE',

};
