import {
  userConstants, videoConstants
} from '../_constants';

export function video(state = {}, action) {

  switch (action.type) {
    case videoConstants.GET_VIDEO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case videoConstants.GET_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        videoListItems: action.users.getVideoList.list,
        videoListTotal: action.users.getVideoList.total,
      };
    case videoConstants.GET_VIDEO_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,

      };

    case videoConstants.UPDATE_VIDEO_REQUEST:
      return {
        ...state
      };
    case videoConstants.UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case videoConstants.UPDATE_VIDEO_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case videoConstants.ADD_VIDEO_REQUEST:
      return {
        ...state
      };
    case videoConstants.ADD_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case videoConstants.ADD_VIDEO_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case videoConstants.GET_LIST_VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case videoConstants.GET_LIST_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getvideoList.list,
        total: action.users.getvideoList.list
      };
    case videoConstants.GET_LIST_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case videoConstants.GETALL_VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case videoConstants.GETALL_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        videoItems: action.users.getAllvideo,
        videoTotal: action.users.getAllvideo
      };
    case videoConstants.GETALL_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_VIDEO_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_VIDEO_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_VIDEO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}