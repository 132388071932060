export const upiConstants = {

    GET_UPI_LIST_REQUEST: 'GET_UPI_LIST_REQUEST',
    GET_UPI_LIST_SUCCESS: 'GET_UPI_LIST_SUCCESS',
    GET_UPI_LIST_FAILURE: 'GET_UPI_LIST_FAILURE',

    GETALL_UPI_REQUEST: 'GETALL_UPI_REQUEST',
    GETALL_UPI_SUCCESS: 'GETALL_UPI_SUCCESS',
    GETALL_UPI_FAILURE: 'GETALL_UPI_FAILURE',

    GET_LIST_UPI_REQUEST: 'GET_LIST_UPI_REQUEST',
    GET_LIST_UPI_SUCCESS: 'GET_LIST_UPI_SUCCESS',
    GET_LIST_UPI_FAILURE: 'GET_LIST_UPI_FAILURE',

    ADD_UPI_REQUEST: 'ADD_UPI_REQUEST',
    ADD_UPI_SUCCESS: 'ADD_UPI_SUCCESS',
    ADD_UPI_FAILURE: 'ADD_UPI_FAILURE',

    DELETE_UPI_REQUEST: 'DELETE_UPI_REQUEST',
    DELETE_UPI_SUCCESS: 'DELETE_UPI_SUCCESS',
    DELETE_UPI_FAILURE: 'DELETE_UPI_FAILURE',

    UPDATE_UPI_REQUEST: 'UPDATE_UPI_REQUEST',
    UPDATE_UPI_SUCCESS: 'UPDATE_UPI_SUCCESS',
    UPDATE_UPI_FAILURE: 'UPDATE_UPI_FAILURE',

    DISABLE_UPI_REQUEST: 'DISABLE_UPI_REQUEST',
    DISABLE_UPI_SUCCESS: 'DISABLE_UPI_SUCCESS',
    DISABLE_UPI_FAILURE: 'DISABLE_UPI_FAILURE',

};
