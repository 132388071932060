import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventActions, userActions, newsActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAdd/CreateAddEvent";
import CreateUpdatePasswordDealer from "./components/CreateUpdate/CreateUpdateEvent";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},
      eventStartTime: "",
      eventEndTime: "",

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      eventContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let data = {
      "status": "DISABLE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(eventActions.getEventList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.event.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.event.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "DISABLE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(eventActions.getEventList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "DISABLE",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(eventActions.getEventList(data));
  }

  disableEvent = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "status": "DISABLE",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of event?',
      message: `Are you sure for ${data.que} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(eventActions.disableEvent(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteEvent = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "status": "DISABLE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.que}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(eventActions.deleteEvent(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/eventoption/" + data.id)
  }



  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(eventActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true, });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  changeStartDate = (value) => {
    console.log('value_______', value);
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["eventStartTime"] = value;
    errorsAddUser["eventStartTime"] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  changeEndDate = (value) => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["eventEndTime"] = value;
    errorsAddUser["eventEndTime"] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  changeStartUpdateDate = (value) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword["eventStartTime"] = value;
    errorsUpdatePassword["eventStartTime"] = "";
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  changeEndUpdateDate = (value) => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword["eventEndTime"] = value;
    errorsUpdatePassword["eventEndTime"] = "";
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addEventSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationAddEvent()) {
      let reqData = {
        "que": this.state.fieldsAddUser.que,
        "currentMatch": this.state.fieldsAddUser.currentMatch,
        "winAmtPercent": this.state.fieldsAddUser.winAmtPercent,
        "eventStartTime": this.state.fieldsAddUser.eventStartTime,
        "eventEndTime": this.state.fieldsAddUser.eventEndTime,
        "currentScore": this.state.fieldsAddUser.currentScore,
        "queSource": this.state.fieldsAddUser.queSource,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null,
      }
      let paginationData = {
        "status": "DISABLE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(eventActions.createEvent(reqData, paginationData));
    }
  }

  handleValidationAddEvent = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;
    //que
    if (!fieldsAddUser["que"] || fieldsAddUser["que"] === "") {
      formIsValid = false;
      errorsAddUser["que"] = "Cannot be empty";
    }
    //currentMatch
    if (!fieldsAddUser["currentMatch"] || fieldsAddUser["currentMatch"] === "") {
      formIsValid = false;
      errorsAddUser["currentMatch"] = "Cannot be empty";
    }
    //winAmtPercent
    if (!fieldsAddUser["winAmtPercent"] || fieldsAddUser["winAmtPercent"] === "") {
      formIsValid = false;
      errorsAddUser["winAmtPercent"] = "Cannot be empty";
    }
    //eventStartTime
    if (!fieldsAddUser["eventStartTime"] || fieldsAddUser["eventStartTime"] === "") {
      formIsValid = false;
      errorsAddUser["eventStartTime"] = "Cannot be empty";
    }
    //eventEndTime
    if (!fieldsAddUser["eventEndTime"] || fieldsAddUser["eventEndTime"] === "") {
      formIsValid = false;
      errorsAddUser["eventEndTime"] = "Cannot be empty";
    }
    //currentScore
    if (!fieldsAddUser["currentScore"] || fieldsAddUser["currentScore"] === "") {
      formIsValid = false;
      errorsAddUser["currentScore"] = "Cannot be empty";
    }
    //queSource
    if (!fieldsAddUser["queSource"] || fieldsAddUser["queSource"] === "") {
      formIsValid = false;
      errorsAddUser["queSource"] = "Cannot be empty";
    }
    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateEventSubmit = () => {


    let { users } = this.props;


    let { filesDetails } = users;

    if (this.handleValidationUpdateEvent()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "que": this.state.fieldsUpdatePassword.que,
        "currentMatch": this.state.fieldsUpdatePassword.currentMatch,
        "winAmtPercent": this.state.fieldsUpdatePassword.winAmtPercent,
        "eventStartTime": this.state.fieldsUpdatePassword.eventStartTime,
        "eventEndTime": this.state.fieldsUpdatePassword.eventEndTime,
        "currentScore": this.state.fieldsUpdatePassword.currentScore,
        "queSource": this.state.fieldsUpdatePassword.queSource,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUpdatePassword.image,
      }
      let paginationData = {
        "status": "DISABLE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(eventActions.updateEvent(reqData, paginationData));
    }

  }


  handleValidationUpdateEvent = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //que
    if (!fieldsUpdatePassword["que"] || fieldsUpdatePassword["que"] === "") {
      formIsValid = false;
      errorsUpdatePassword["que"] = "Cannot be empty";
    }
    //currentMatch
    if (!fieldsUpdatePassword["currentMatch"] || fieldsUpdatePassword["currentMatch"] === "") {
      formIsValid = false;
      errorsUpdatePassword["currentMatch"] = "Cannot be empty";
    }
    //winAmtPercent
    if (!fieldsUpdatePassword["winAmtPercent"] || fieldsUpdatePassword["winAmtPercent"] === "") {
      formIsValid = false;
      errorsUpdatePassword["winAmtPercent"] = "Cannot be empty";
    }
    //eventStartTime
    if (!fieldsUpdatePassword["eventStartTime"] || fieldsUpdatePassword["eventStartTime"] === "") {
      formIsValid = false;
      errorsUpdatePassword["eventStartTime"] = "Cannot be empty";
    }
    //eventEndTime
    if (!fieldsUpdatePassword["eventEndTime"] || fieldsUpdatePassword["eventEndTime"] === "") {
      formIsValid = false;
      errorsUpdatePassword["eventEndTime"] = "Cannot be empty";
    }
    //currentScore
    if (!fieldsUpdatePassword["currentScore"] || fieldsUpdatePassword["currentScore"] === "") {
      formIsValid = false;
      errorsUpdatePassword["currentScore"] = "Cannot be empty";
    }
    //queSource
    if (!fieldsUpdatePassword["queSource"] || fieldsUpdatePassword["queSource"] === "") {
      formIsValid = false;
      errorsUpdatePassword["queSource"] = "Cannot be empty";
    }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(eventActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }




  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };

  render() {

    let { event, category, news, users } = this.props;
    let { items, total, loading, getEventList } = event;
    let { allCategory } = category;
    let { allGameItems } = news;


    let { filesDetails } = users;

    console.log("RENDER_______items", this.state.fieldsAddUser);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md ">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">Disable Event</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      {/* <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add Event </button> */}

                    </div>

                  </div>

                  <div className="pb-2 mt-4 overflow-hidden ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Question </th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Match</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Current Score</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Winning Rate</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Start Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">End Time</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">image</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Enable / Disable</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.que ? element.que : "NA"} </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.currentMatch ? element.currentMatch : "NA"}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.currentScore ? element.currentScore : "NA"} </td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.winAmtPercent ? element.winAmtPercent : "NA"} </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element.eventStartTime}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element.eventEndTime}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.eventStartTime))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.eventEndTime))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td> */}

                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className=''>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="not found" />
                                        </div>
                                      </td> */}

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="flex pl-1 justify-left">
                                          {element.isDisable == false ?
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableEvent(element)}>

                                              <label>Enabled</label>
                                            </div>
                                            : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableEvent(element)}>

                                              <label>Disabled</label>
                                            </div>}
                                        </span>
                                      </td>

                                      <td className="flex justify-center px-6 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteEvent(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>


        <CreateAddDealerModal
          options={options}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          allGameItems={allGameItems}
          filesDetails={filesDetails}
          inputAddUserChange={this.inputAddUserChange}
          addEventSubmit={this.addEventSubmit}
          handleFile={this.handleFile}
          changeStartDate={this.changeStartDate}
          changeEndDate={this.changeEndDate}
          changeStartUpdateDate={this.changeStartUpdateDate}
          changeEndUpdateDate={this.changeEndUpdateDate}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />

        <CreateUpdatePasswordDealer
          options={options}
          allCategory={allCategory}
          offset={this.state.offset}
          updateSelectedTag={this.state.updateSelectedTag}
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          allGameItems={allGameItems}
          inputChange={this.inputChange}
          handleFile={this.handleFile}
          updateEventSubmit={this.updateEventSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          contentFieldUpdate={this.contentFieldUpdate}
          deleteContentFieldUpdate={this.deleteContentFieldUpdate}
          setCurrentIndexUpdate={this.setCurrentIndexUpdate}
          inputChangeContentUpdate={this.inputChangeContentUpdate}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          modules={this.modules}
          formats={this.formats}
          rteChangeUpdate={this.rteChangeUpdate}
        />

        {/* <ViewMoreDetailsModal
          options={options}
          offset={this.state.offset}
          allCategory={allCategory}
          modules={this.modules}
          formats={this.formats}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          eventRowData={this.state.eventRowData}
          updateSelectedTag={this.state.updateSelectedTag}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        /> */}

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { event, users, category, news } = state;
  return {
    event,
    users,
    category,
    news
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Event);
