import {
  websiteConstants
} from '../_constants';

export function website(state = {}, action) {

  switch (action.type) {
    case websiteConstants.ADD_WEBSITE_REQUEST:
      return {
        ...state
      };
    case websiteConstants.ADD_WEBSITE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case websiteConstants.ADD_WEBSITE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case websiteConstants.APPROVE_TICKET_REQUEST:
      return {
        ...state
      };
    case websiteConstants.APPROVE_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case websiteConstants.APPROVE_TICKET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case websiteConstants.APPROVE_ID_TICKET_REQUEST:
      return {
        ...state
      };
    case websiteConstants.APPROVE_ID_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case websiteConstants.APPROVE_ID_TICKET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case websiteConstants.REJECT_TICKET_REQUEST:
      return {
        ...state
      };
    case websiteConstants.REJECT_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case websiteConstants.REJECT_TICKET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case websiteConstants.GET_WEBSITE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GET_WEBSITE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        websiteItems: action.users.getWebsiteList.list,
        websiteTotal: action.users.getWebsiteList.total
      };
    case websiteConstants.GET_WEBSITE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case websiteConstants.GET_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        ticketItems: action.users.getTicketList.list,
        ticketTotal: action.users.getTicketList.total
      };
    case websiteConstants.GET_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case websiteConstants.GETALL_BET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GETALL_BET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case websiteConstants.GETALL_BET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case websiteConstants.GETALL_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GETALL_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllUser: action.users.getAllUserr,
      };
    case websiteConstants.GETALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case websiteConstants.GETALL_BET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GETALL_BET_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGamesListById: action.users.getAllGameByGameTypeId,
      };
    case websiteConstants.GETALL_BET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case websiteConstants.GET_TICKET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.GET_TICKET_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        ticketDetail: action.users.getTicketByTicketId,
      };
    case websiteConstants.GET_TICKET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case websiteConstants.UPDATE_WEBSITE_REQUEST:
      return {
        ...state
      };
    case websiteConstants.UPDATE_WEBSITE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case websiteConstants.UPDATE_WEBSITE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case websiteConstants.DELETE_WEBSITE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.DELETE_WEBSITE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case websiteConstants.DELETE_WEBSITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case websiteConstants.DISABLE_WEBSITE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteConstants.DISABLE_WEBSITE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case websiteConstants.DISABLE_WEBSITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}