
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const eventService = {
    getEventById,
    createEvent,
    updateEvent,
    getEventList,
    deleteEvent,
    disableEvent,
};


function getEventList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getFilteredEventList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEventList: data.data
            }
            console.log("i am in service getEventList", userObj);

            return userObj;
        });
}
function getEventById(data) {
    // console.log("data m kya aa rha h::service:::::::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEventById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEventById: data.data
            }
            console.log("i am in service getEventById", userObj);

            return userObj;
        });
}
function deleteEvent(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteEvent`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteEvent: data.data
            }
            console.log("i am in service''...>> deleteEvent ::", userObj);

            return userObj;
        });
}
function disableEvent(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEventStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateEventStatus: data.data
            }
            console.log("i am in service''...>> updateEventStatus ::", userObj);

            return userObj;
        });
}

function updateEvent(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEvent`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateEvent: data.data
            }
            console.log("I am in service updateEvent", userObj);

            return userObj;
        });
}
function createEvent(data) {
    console.log('service mai data aa rhe hai :::::', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createEvent`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createEvent: data.data
            }
            console.log("I am in service createEvent", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                // logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}