export const liveScoreConstants = {

    GETALL_LIVE_SCORE_REQUEST: 'GETALL_LIVE_SCORE_REQUEST',
    GETALL_LIVE_SCORE_SUCCESS: 'GETALL_LIVE_SCORE_SUCCESS',
    GETALL_LIVE_SCORE_FAILURE: 'GETALL_LIVE_SCORE_FAILURE',

    GET_LIST_LIVE_SCORE_REQUEST: 'GET_LIST_LIVE_SCORE_REQUEST',
    GET_LIST_LIVE_SCORE_SUCCESS: 'GET_LIST_LIVE_SCORE_SUCCESS',
    GET_LIST_LIVE_SCORE_FAILURE: 'GET_LIST_LIVE_SCORE_FAILURE',

    ADD_LIVE_SCORE_REQUEST: 'ADD_LIVE_SCORE_REQUEST',
    ADD_LIVE_SCORE_SUCCESS: 'ADD_LIVE_SCORE_SUCCESS',
    ADD_LIVE_SCORE_FAILURE: 'ADD_LIVE_SCORE_FAILURE',

    DELETE_LIVE_SCORE_REQUEST: 'DELETE_LIVE_SCORE_REQUEST',
    DELETE_LIVE_SCORE_SUCCESS: 'DELETE_LIVE_SCORE_SUCCESS',
    DELETE_LIVE_SCORE_FAILURE: 'DELETE_LIVE_SCORE_FAILURE',

    UPDATE_LIVE_SCORE_REQUEST: 'UPDATE_LIVE_SCORE_REQUEST',
    UPDATE_LIVE_SCORE_SUCCESS: 'UPDATE_LIVE_SCORE_SUCCESS',
    UPDATE_LIVE_SCORE_FAILURE: 'UPDATE_LIVE_SCORE_FAILURE',

    DISABLE_LIVE_SCORE_REQUEST: 'DISABLE_LIVE_SCORE_REQUEST',
    DISABLE_LIVE_SCORE_SUCCESS: 'DISABLE_LIVE_SCORE_SUCCESS',
    DISABLE_LIVE_SCORE_FAILURE: 'DISABLE_LIVE_SCORE_FAILURE',

};
