import { userConstants, websiteConstants } from '../_constants';
import { userService, websiteService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const websiteActions = {

    login,
    logout,
    getWebsiteList,
    disableWebsite,
    deleteWebsite,
    getAllBet,
    getAllUser,
    createWebsite,
    updateWebsite,
    uploadImageClear,
    getAllBetByBetTypeId,
    getTicketList,
    approveTicket,
    rejectTicket,
    getTicketByTicketId,
    updateTicketStatusOfId
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createWebsite(data, reqData) {

    return dispatch => {
        dispatch(request());
        websiteService.createWebsite(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Website Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getWebsiteList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.ADD_WEBSITE_REQUEST } }
    function success(users) { return { type: websiteConstants.ADD_WEBSITE_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.ADD_WEBSITE_FAILURE, error } }
}

function approveTicket(data, reqData) {

    return dispatch => {
        dispatch(request());
        websiteService.approveTicket(data)
            .then(

                users => {
                    dispatch(alertActions.success("Approve Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTicketList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.APPROVE_TICKET_REQUEST } }
    function success(users) { return { type: websiteConstants.APPROVE_TICKET_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.APPROVE_TICKET_FAILURE, error } }
}

function updateTicketStatusOfId(data, reqData) {

    return dispatch => {
        dispatch(request());
        websiteService.updateTicketStatusOfId(data)
            .then(

                users => {
                    dispatch(alertActions.success("Approve Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTicketList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.APPROVE_ID_TICKET_REQUEST } }
    function success(users) { return { type: websiteConstants.APPROVE_ID_TICKET_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.APPROVE_ID_TICKET_FAILURE, error } }
}

function rejectTicket(data, reqData) {

    return dispatch => {
        dispatch(request());
        websiteService.rejectTicket(data)
            .then(

                users => {
                    dispatch(alertActions.success("Reject Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTicketList(reqData));
                    // dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.REJECT_TICKET_REQUEST } }
    function success(users) { return { type: websiteConstants.REJECT_TICKET_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.REJECT_TICKET_FAILURE, error } }
}
function updateWebsite(data, paginationData) {

    return dispatch => {
        dispatch(request());
        websiteService.updateWebsite(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Website Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getWebsiteList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.UPDATE_WEBSITE_REQUEST } }
    function success(users) { return { type: websiteConstants.UPDATE_WEBSITE_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.UPDATE_WEBSITE_FAILURE, error } }
}
function getAllBet(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getAllBet(data)
            .then(
                users => {
                    console.log("getAllBet $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GETALL_BET_REQUEST } }
    function success(users) { return { type: websiteConstants.GETALL_BET_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GETALL_BET_FAILURE, error } }
}
function getAllUser(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getAllUser(data)
            .then(
                users => {
                    console.log("getAllUser $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GETALL_USER_REQUEST } }
    function success(users) { return { type: websiteConstants.GETALL_USER_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GETALL_USER_FAILURE, error } }
}
function getAllBetByBetTypeId(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getAllBetByBetTypeId(data)
            .then(
                users => {
                    console.log("getAllBetByBetTypeId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GETALL_BET_BY_ID_REQUEST } }
    function success(users) { return { type: websiteConstants.GETALL_BET_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GETALL_BET_BY_ID_FAILURE, error } }
}
function getTicketByTicketId(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getTicketByTicketId(data)
            .then(
                users => {
                    console.log("getTicketByTicketId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GET_TICKET_BY_ID_REQUEST } }
    function success(users) { return { type: websiteConstants.GET_TICKET_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GET_TICKET_BY_ID_FAILURE, error } }
}
function getWebsiteList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getWebsiteList(data)
            .then(
                users => {
                    console.log("getWebsiteList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GET_WEBSITE_LIST_REQUEST } }
    function success(users) { return { type: websiteConstants.GET_WEBSITE_LIST_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GET_WEBSITE_LIST_FAILURE, error } }
}
function getTicketList(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        websiteService.getTicketList(data)
            .then(
                users => {
                    console.log("getTicketList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.GET_TICKET_LIST_REQUEST } }
    function success(users) { return { type: websiteConstants.GET_TICKET_LIST_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.GET_TICKET_LIST_FAILURE, error } }
}

function deleteWebsite(data, paginationData) {
    return dispatch => {
        dispatch(request());
        websiteService.deleteWebsite(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Website Delete Successfully."));
                    dispatch(this.getWebsiteList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.DELETE_WEBSITE_REQUEST } }
    function success(users) { return { type: websiteConstants.DELETE_WEBSITE_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.DELETE_WEBSITE_FAILURE, error } }
}
function disableWebsite(data, paginationData) {

    return dispatch => {
        dispatch(request());
        websiteService.disableWebsite(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getWebsiteList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: websiteConstants.DISABLE_WEBSITE_REQUEST } }
    function success(users) { return { type: websiteConstants.DISABLE_WEBSITE_SUCCESS, users } }
    function failure(error) { return { type: websiteConstants.DISABLE_WEBSITE_FAILURE, error } }
}
