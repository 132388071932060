import {
  userConstants, tripConstants
} from '../_constants';

export function trip(state = {}, action) {
  console.log('action_________', action);

  switch (action.type) {
    case tripConstants.GET_TRIP_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tripConstants.GET_TRIP_BY_ID_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        getTripItems: action.users.getTripById,
      };
    case tripConstants.GET_TRIP_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tripConstants.GET_ALL_TRIP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tripConstants.GET_ALL_TRIP_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        allGameItems: action.users.getAllGameTypes,
      };
    case tripConstants.GET_ALL_TRIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tripConstants.ADD_TRIP_REQUEST:
      return {
        ...state
      };
    case tripConstants.ADD_TRIP_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tripConstants.ADD_TRIP_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case tripConstants.ADD_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case tripConstants.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tripConstants.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tripConstants.GET_TRIP_BY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case tripConstants.GET_TRIP_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        getTripByCategory: action.users.getTripByCategoryId,
        // total: action.users.getNewsById
      };
    case tripConstants.GET_TRIP_BY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tripConstants.GET_LIST_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case tripConstants.GET_LIST_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.users.getNewsList.list,
        total: action.users.getNewsList.total
      };
    case tripConstants.GET_LIST_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      
    // case tripConstants.GETALL_NEWS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case tripConstants.GETALL_NEWS_SUCCESS:
    //   return {
    //     ...state,
    //     // addUserSuccess: false,
    //     loading: false,
    //     tripItems2: action.users.getAllNews,
    //     tripTotal: action.users.getAllNews
    //   };
    // case tripConstants.GETALL_NEWS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };

    case tripConstants.UPDATE_TRIP_REQUEST:
      return {
        ...state
      };
    case tripConstants.UPDATE_TRIP_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        addUserSuccess: true,
      };
    case tripConstants.UPDATE_TRIP_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case tripConstants.DELETE_TRIP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tripConstants.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tripConstants.DELETE_TRIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case tripConstants.DISABLE_TRIP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tripConstants.DISABLE_TRIP_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tripConstants.DISABLE_TRIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

 
    case tripConstants.GET_TRIP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tripConstants.GET_TRIP_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        tripItems: action.users.getTripList.list,
        tripTotal: action.users.getTripList.tripTotal
      };
    case tripConstants.GET_TRIP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}