import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventOptionActions, eventActions, newsActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAdd/CreateAddEventOption";
import CreateUpdatePasswordDealer from "./components/CreateUpdate/CreateUpdateEventOption";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"

class EventOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventOptionRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},
      eventOptionStartTime: "",
      eventOptionEndTime: "",

      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      currentOption: 'YES',
      eventOptionContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let data = {
      "eventId": this.props.match.params.eventId,
      "option": this.state.currentOption,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let reqData = {
      "id": this.props.match.params.eventId,
    }
    this.props.dispatch(eventActions.getEventById(reqData));
    this.props.dispatch(eventOptionActions.getEventOptionList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.eventOption.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.eventOption.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("Page_click_________data", data, "<<<<<<<<<<>>>>>>>>>>>>>", this.state);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, pageNo: data.selected });
    let datatemp = {
      "eventId": this.props.match.params.eventId,
      "option": this.state.currentOption,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(eventOptionActions.getEventOptionList(datatemp));
  }
  handleSearch = (eventOption) => {
    // eventOption.preventOptionDefault();
    let { value } = eventOption.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "eventId": this.props.match.params.eventId,
      "option": this.state.currentOption,
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(eventOptionActions.getEventOptionList(data));
  }

  disableEventOption = (data) => {
    console.log('data________', data);
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "eventId": this.props.match.params.eventId,
      "option": this.state.currentOption,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status of event option?',
      message: `Are you sure for ${data.price} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(eventOptionActions.disableEventOption(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteEventOption = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "eventId": this.props.match.params.eventId,
      "option": this.state.currentOption,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.price}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(eventOptionActions.deleteEventOption(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }

  changeOptionApiData = (param) => {
    let data = {
      "eventId": this.props.match.params.eventId,
      "option": param,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.setState({ option: param, offset: 0, pageNo: 1 })
    this.props.dispatch(eventOptionActions.getEventOptionList(data));
  }



  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(eventOptionActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {
    this.setState({ addUserCreateModal: true, });
  }
  inputAddUserChange = (e) => {
    // e.preventOptionDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    // e.preventOptionDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  changeStartDate = (value) => {
    console.log('value_______', value);
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["eventOptionStartTime"] = value;
    errorsAddUser["eventOptionStartTime"] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  changeEndDate = (value) => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["eventOptionEndTime"] = value;
    errorsAddUser["eventOptionEndTime"] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  changeStartUpdateDate = (value) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword["eventOptionStartTime"] = value;
    errorsUpdatePassword["eventOptionStartTime"] = "";
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  changeEndUpdateDate = (value) => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword["eventOptionEndTime"] = value;
    errorsUpdatePassword["eventOptionEndTime"] = "";
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addEventOptionSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    if (this.handleValidationAddEventOption()) {
      let reqData = {
        "eventId": this.props.match.params.eventId,
        "option": this.state.fieldsAddUser.option,
        "price": this.state.fieldsAddUser.price,
        "quantity": this.state.fieldsAddUser.quantity,
      }
      let paginationData = {
        "eventId": this.props.match.params.eventId,
        "option": this.state.currentOption,
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(eventOptionActions.createEventOption(reqData, paginationData));
    }
  }

  handleValidationAddEventOption = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;
    //option
    if (!fieldsAddUser["option"] || fieldsAddUser["option"] === "") {
      formIsValid = false;
      errorsAddUser["option"] = "Cannot be empty";
    }
    //price
    if (!fieldsAddUser["price"] || fieldsAddUser["price"] === "") {
      formIsValid = false;
      errorsAddUser["price"] = "Cannot be empty";
    }
    //quantity
    if (!fieldsAddUser["quantity"] || fieldsAddUser["quantity"] === "") {
      formIsValid = false;
      errorsAddUser["quantity"] = "Cannot be empty";
    }

    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateEventOptionSubmit = () => {


    let { users } = this.props;


    let { filesDetails } = users;

    if (this.handleValidationUpdateEventOption()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "eventId": this.props.match.params.eventId,
        "option": this.state.fieldsUpdatePassword.option,
        "price": this.state.fieldsUpdatePassword.price,
        "quantity": this.state.fieldsUpdatePassword.quantity,
      }
      let paginationData = {
        "eventId": this.props.match.params.eventId,
        "option": this.state.currentOption,
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(eventOptionActions.updateEventOption(reqData, paginationData));
    }

  }


  handleValidationUpdateEventOption = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    if (!fieldsUpdatePassword["option"] || fieldsUpdatePassword["option"] === "") {
      formIsValid = false;
      errorsUpdatePassword["option"] = "Cannot be empty";
    }
    //price
    if (!fieldsUpdatePassword["price"] || fieldsUpdatePassword["price"] === "") {
      formIsValid = false;
      errorsUpdatePassword["price"] = "Cannot be empty";
    }
    //quantity
    if (!fieldsUpdatePassword["quantity"] || fieldsUpdatePassword["quantity"] === "") {
      formIsValid = false;
      errorsUpdatePassword["quantity"] = "Cannot be empty";
    }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleFile = (eventOption) => {

    this.setState({ selectedFile: eventOption.target.files[0] });

    if (eventOption.target.files[0]) {
      this.props.dispatch(eventOptionActions.uploadImage(eventOption.target.files[eventOption.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventOptionDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventOptionDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }




  handleUpload = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  handleUploadImageUser = () => {

    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }


  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventOptionDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }




  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventOptionDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }



  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;

    fieldsUpdatePassword["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsUpdatePassword });
  };

  render() {

    let { eventOption, category, news, users, event } = this.props;
    let { eventData } = event;
    let { items, total, loading, getEventOptionList, noOptionItems, noOptionTotal, yesOptionItem, yesOptionTotal } = eventOption;
    let { allCategory } = category;
    let { allGameItems } = news;


    let { filesDetails } = users;

    console.log("RENDER_______items", this.state);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md ">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">EventOption</h3>
                    </div>

                    <div className='flex space-x-6 '>

                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-eventOptions-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>

                      <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add EventOption </button>

                    </div>

                  </div>

                  <div className='flex space-x-6 '>
                    <span className="flex items-center text-lg font-medium">
                      Question : {eventData && eventData.que ? eventData.que : "NA"}
                    </span>
                  </div>
                  <div className='flex space-x-6 '>
                    <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-green-500 rounded-md " onClick={() => this.changeOptionApiData("YES")}>  YES </button>
                    <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-red-500 rounded-md " onClick={() => this.changeOptionApiData("NO")}>  NO </button>
                  </div>
                  <>
                    <div className="pb-2 mt-4 overflow-hidden ">
                      <div className="max-w-full overflow-x-auto ">
                        <div className="inline-block min-w-full ">
                          <div className="">
                            <table className="min-w-full border-0 divide-y ">
                              <thead className="bg-gray-200">
                                <tr className="">
                                  {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th> */}
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Price </th>
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Quantity</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Option</th>

                                  <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Enable / Disable</th>
                                  <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  items && items.length > 0 ?
                                    items.map((element, index) => (<React.Fragment key={element.id}>
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        {/* <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td> */}


                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.price ? element.price : "NA"} </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.quantity ? element.quantity : "NA"}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.option ? element.option : "NA"}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          <span className="flex pl-1 justify-left">
                                            {element.isDisable == false ?
                                              <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableEventOption(element)}>

                                                <label>Enabled</label>
                                              </div>
                                              : <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.disableEventOption(element)}>

                                                <label>Disabled</label>
                                              </div>}
                                          </span>
                                        </td>

                                        <td className="flex justify-center px-6 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                          <div className='flex space-x-2'>
                                            {/* <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span>
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span> */}

                                            <span className="relative">
                                              <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                                <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span>
                                                <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                              </div>
                                            </span>

                                            <span className="relative">
                                              <div class="targetablepx-4 tooltip p-2 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" onClick={() => this.deleteEventOption(element)}>
                                                <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span>
                                                <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                              </div>
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>))
                                    : (<tr className="bg-white bg-opacity-5 " >
                                      <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                    </tr>)
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              total && total > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={total / this.state.size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              total && total > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={total / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }
                    </div>
                  </>

                </section>
              </div>
            </div>
          </main>
        </div>


        <CreateAddDealerModal
          options={options}
          selectedTag={this.state.selectedTag}
          offset={this.state.offset}
          addUserCreateModal={this.state.addUserCreateModal}
          allCategory={allCategory}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          allGameItems={allGameItems}
          filesDetails={filesDetails}
          inputAddUserChange={this.inputAddUserChange}
          addEventOptionSubmit={this.addEventOptionSubmit}
          handleFile={this.handleFile}
          changeStartDate={this.changeStartDate}
          changeEndDate={this.changeEndDate}
          changeStartUpdateDate={this.changeStartUpdateDate}
          changeEndUpdateDate={this.changeEndUpdateDate}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          addContentField={this.addContentField}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          addTagField={this.addTagField}
          deleteTagField={this.deleteTagField}
          inputAddUserChangeTag={this.inputAddUserChangeTag}
          handleSelectedTag={this.handleSelectedTag}
          modules={this.modules}
          formats={this.formats}
          rteChange={this.rteChange}
        />

        <CreateUpdatePasswordDealer
          options={options}
          allCategory={allCategory}
          offset={this.state.offset}
          updateSelectedTag={this.state.updateSelectedTag}
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          allGameItems={allGameItems}
          inputChange={this.inputChange}
          handleFile={this.handleFile}
          updateEventOptionSubmit={this.updateEventOptionSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          contentFieldUpdate={this.contentFieldUpdate}
          deleteContentFieldUpdate={this.deleteContentFieldUpdate}
          setCurrentIndexUpdate={this.setCurrentIndexUpdate}
          inputChangeContentUpdate={this.inputChangeContentUpdate}
          handleUpload={this.handleUpload}
          handleUploadImageUser={this.handleUploadImageUser}
          tagFieldUpdate={this.tagFieldUpdate}
          deletetagFieldUpdate={this.deletetagFieldUpdate}
          inputChangetagUpdate={this.inputChangetagUpdate}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          modules={this.modules}
          formats={this.formats}
          rteChangeUpdate={this.rteChangeUpdate}
        />

        {/* <ViewMoreDetailsModal
          options={options}
          offset={this.state.offset}
          allCategory={allCategory}
          modules={this.modules}
          formats={this.formats}
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          eventOptionRowData={this.state.eventOptionRowData}
          updateSelectedTag={this.state.updateSelectedTag}
          handleSelectedTagUpdate={this.handleSelectedTagUpdate}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        /> */}

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { eventOption, users, category, news, event } = state;
  return {
    eventOption,
    event,
    users,
    category,
    news
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(EventOption);
