export const quoteConstants = {

    GETALL_QUOTE_REQUEST: 'GETALL_QUOTE_REQUEST',
    GETALL_QUOTE_SUCCESS: 'GETALL_QUOTE_SUCCESS',
    GETALL_QUOTE_FAILURE: 'GETALL_QUOTE_FAILURE',

    GET_LIST_QUOTE_REQUEST: 'GET_LIST_QUOTE_REQUEST',
    GET_LIST_QUOTE_SUCCESS: 'GET_LIST_QUOTE_SUCCESS',
    GET_LIST_QUOTE_FAILURE: 'GET_LIST_QUOTE_FAILURE',

    ADD_QUOTE_REQUEST: 'ADD_QUOTE_REQUEST',
    ADD_QUOTE_SUCCESS: 'ADD_QUOTE_SUCCESS',
    ADD_QUOTE_FAILURE: 'ADD_QUOTE_FAILURE',

    DELETE_QUOTE_REQUEST: 'DELETE_QUOTE_REQUEST',
    DELETE_QUOTE_SUCCESS: 'DELETE_QUOTE_SUCCESS',
    DELETE_QUOTE_FAILURE: 'DELETE_QUOTE_FAILURE',

    UPDATE_QUOTE_REQUEST: 'UPDATE_QUOTE_REQUEST',
    UPDATE_QUOTE_SUCCESS: 'UPDATE_QUOTE_SUCCESS',
    UPDATE_QUOTE_FAILURE: 'UPDATE_QUOTE_FAILURE',

    DISABLE_QUOTE_REQUEST: 'DISABLE_QUOTE_REQUEST',
    DISABLE_QUOTE_SUCCESS: 'DISABLE_QUOTE_SUCCESS',
    DISABLE_QUOTE_FAILURE: 'DISABLE_QUOTE_FAILURE',

};
