export const infoConstants = {

    MATCHES_BY_CS_ID_REQUEST: 'MATCHES_BY_CS_ID_REQUEST',
    MATCHES_BY_CS_ID_SUCCESS: 'MATCHES_BY_CS_ID_SUCCESS',
    MATCHES_BY_CS_ID_FAILURE: 'MATCHES_BY_CS_ID_FAILURE',

    UPDATE_MATCH_INFO_TEZ_REQUEST: 'UPDATE_MATCH_INFO_TEZ_REQUEST',
    UPDATE_MATCH_INFO_TEZ_SUCCESS: 'UPDATE_MATCH_INFO_TEZ_SUCCESS',
    UPDATE_MATCH_INFO_TEZ_FAILURE: 'UPDATE_MATCH_INFO_TEZ_FAILURE',

    GET_MATCH_INFO_BY_ID_REQUEST: 'GET_MATCH_INFO_BY_ID_REQUEST',
    GET_MATCH_INFO_BY_ID_SUCCESS: 'GET_MATCH_INFO_BY_ID_SUCCESS',
    GET_MATCH_INFO_BY_ID_FAILURE: 'GET_MATCH_INFO_BY_ID_FAILURE',
    
    GETALL_INFO_REQUEST: 'GETALL_INFO_REQUEST',
    GETALL_INFO_SUCCESS: 'GETALL_INFO_SUCCESS',
    GETALL_INFO_FAILURE: 'GETALL_INFO_FAILURE',

    GET_LIST_INFO_REQUEST: 'GET_LIST_INFO_REQUEST',
    GET_LIST_INFO_SUCCESS: 'GET_LIST_INFO_SUCCESS',
    GET_LIST_INFO_FAILURE: 'GET_LIST_INFO_FAILURE',

    ADD_INFO_REQUEST: 'ADD_INFO_REQUEST',
    ADD_INFO_SUCCESS: 'ADD_INFO_SUCCESS',
    ADD_INFO_FAILURE: 'ADD_INFO_FAILURE',

    DELETE_INFO_REQUEST: 'DELETE_INFO_REQUEST',
    DELETE_INFO_SUCCESS: 'DELETE_INFO_SUCCESS',
    DELETE_INFO_FAILURE: 'DELETE_INFO_FAILURE',

    UPDATE_INFO_REQUEST: 'UPDATE_INFO_REQUEST',
    UPDATE_INFO_SUCCESS: 'UPDATE_INFO_SUCCESS',
    UPDATE_INFO_FAILURE: 'UPDATE_INFO_FAILURE',

    DISABLE_INFO_REQUEST: 'DISABLE_INFO_REQUEST',
    DISABLE_INFO_SUCCESS: 'DISABLE_INFO_SUCCESS',
    DISABLE_INFO_FAILURE: 'DISABLE_INFO_FAILURE',

};
