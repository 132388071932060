import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';

class MainPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      sportAndSeriesId: {},
    }
  }

  componentDidMount() {
    let data = {}
    this.props.dispatch(userActions.getAllSport(data));
  }

  createSeries = (data) => {

    let reqData = {
      "sportId": data.sportId,
      "seriesId": data.seriesId,
      "name": data.name,
      "isManual": true,
      "isActive": !data.isActive
    }
    this.props.dispatch(userActions.createSeries(reqData));

  }

  getAllSeries = (data) => {
    this.setState({
      sportId: data.sportId,
      seriesId: '',
      matchId: '',
    })
    this.props.dispatch(userActions.getAllSeries(data.sportId));
  }

  getAllMatches = (data) => {

    let sportId = data.sportId;
    let seriesId = data.seriesId;
    this.setState({ seriesId: data.seriesId })
    this.props.dispatch(userActions.getAllMatch({ sportId, seriesId }));

  }

  getAllMarket = (data) => {
    this.setState({ matchId: data.matchId })
    this.props.dispatch(userActions.getAllMarket(data));
  }

  createMatch = (data) => {
    let reqData = {
      "sportId": parseInt(data.sportId),
      "seriesId": parseInt(data.seriesId),
      "matchId": parseInt(data.matchId),
      "name": data.name,
      "openDate": data.openDate,
      "isManual": true,
      "isActive": !data.isActive
    }
    let sportId = data.sportId;
    let seriesId = data.seriesId;
    this.props.dispatch(userActions.createMatch(reqData, { sportId, seriesId }));
  }

  createMarket = (data) => {
    let reqData = {
      "sportId": data.sportId + "",
      "seriesId": data.seriesId + "",
      "matchId": data.matchId + "",
      "marketId": data.marketId + "",
      "marketStartTime": data.marketStartTime,
      "name": data.name,
      "isActive": !data.isActive,
      "isManual": true
    }

    console.log("reqData:::::", reqData);
    this.props.dispatch(userActions.createMarket(reqData));
  }


  render() {

    let { users } = this.props;
    let { loading, allSport, allSeries, allMatch, allMarket } = users;

    console.log("allMarket_allMarket_allMarket::::", allMarket);
    console.log("this.state.sportId::::::::", this.state.sportId);


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">

                <section className="bg-white border rounded-md  p-6 space-y-6">
                  <div class="flex mr-2 pb-2">
                    {
                      allSport && allSport.length > 0 ?
                        allSport.map((element) => (<React.Fragment key={element.id}>
                          <span class="text-center block border border-white-500 py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white" onClick={() => this.getAllSeries(element)}>{element.name}</span>
                        </React.Fragment>))
                        : null
                    }
                  </div>
                  <div className="flex flex-wrap w-full ">

                    <table className="min-w-full border-0 divide-y divide-gray-800">
                      <thead className="bg-gray-200 rounded-t">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">serial no.</th>
                          <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">series id</th>
                          <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">series name</th>
                          <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {
                          allSeries && allSeries.length > 0 ?
                            allSeries.map((element, index) => (<React.Fragment key={element.id}>


                              <tr className="bg-white border-b border-black border-opacity-10 ">
                                <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element.seriesId}</td>
                                <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap"> {element.name}</td>
                                <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                  <div class="inline-flex">
                                    <input onClick={() => this.createSeries(element)} defaultChecked={element.isActive} type="checkbox" className="form-checkbox ppearance-none pt-2 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
                                    {element.isActive ? <button onClick={() => this.getAllMatches(element)} class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r">+</button> : null}
                                  </div>
                                </td>
                              </tr>
                              {
                                element.seriesId === this.state.seriesId ?

                                  <tr class="bg-gray-200 text-blue-900">
                                    <td className="p-0.5" colspan="4">
                                      <table class="bg-white text-gray-900 table-auto w-full shadow-none">

                                        <thead className="bg-gray-200 rounded-t">
                                          <tr>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">serial no.</th>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Match Id</th>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Match Name</th>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Open Date</th>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Db Date</th>
                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Actions</th>
                                          </tr>
                                        </thead>

                                        {
                                          allMatch && allMatch.length > 0 ?
                                            allMatch.map((element1, index) => (<React.Fragment key={element.id}>
                                              <>
                                                <tbody className="">

                                                  <tr className="bg-white border-b border-black    border-opacity-10 ">
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element1 && element1.matchId ? element1.matchId : "NA"}</td>
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element1 && element1.name ? element1.name : "NA"}</td>
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element1 && element1.openDate ? element1.openDate : "NA"}</td>
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element1 && element1.openDate ? element1.openDate : "NA"}</td>
                                                    <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                                      <div class="inline-flex">
                                                        <input onClick={() => this.createMatch(element1)} defaultChecked={element1.isActive} type="checkbox" className="form-checkbox ppearance-none pt-2 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
                                                        {element1.isActive ? <button
                                                          onClick={() => this.getAllMarket(element1)}
                                                          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r">#</button> : null}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  {element1.matchId === this.state.matchId ? <tr className="bg-white border-b border-black border-opacity-10 ">
                                                    <td className="p-0.5" colspan="4">
                                                      <table class="bg-white text-gray-900 table-auto w-full shadow-none">
                                                        <thead className="bg-gray-200 rounded-t">
                                                          <tr>
                                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">serial no.</th>
                                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">market Id</th>
                                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">name</th>
                                                            <th scope="col" className="px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Actions</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="">
                                                          {
                                                            allMarket && allMarket.length > 0 ? allMarket.map((element2, index) => (<React.Fragment key={element.id}>
                                                              <>
                                                                <tr className="bg-white border-b border-black border-opacity-10 ">
                                                                  <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{this.state.offset + index + 1}</td>
                                                                  <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element2 && element2.marketId ? element2.marketId : "NA"}</td>
                                                                  <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element2 && element2.name ? element2.name : "NA"}</td>
                                                                  <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                                                    <div class="inline-flex">
                                                                      <input onClick={() => this.createMarket(element2)} defaultChecked={element2.isActive} type="checkbox" className="form-checkbox ppearance-none pt-2 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            </React.Fragment>))
                                                              : (<tr className="bg-white bg-opacity-5 " >
                                                                <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                                              </tr>)
                                                          }
                                                        </tbody>
                                                      </table>

                                                    </td>
                                                  </tr> : null}

                                                </tbody>
                                              </>
                                            </React.Fragment>))
                                            : (<tr className="bg-white bg-opacity-5 " >
                                              <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                            </tr>)
                                        }

                                      </table>
                                    </td>
                                  </tr>

                                  : null
                              }


                            </React.Fragment>))
                            : (<tr className="bg-white bg-opacity-5 " >
                              <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                            </tr>)
                        }
                      </tbody>
                    </table>

                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(MainPage);
