import {
  marketConstants
} from '../_constants';

export function market(state = {}, action) {

  switch (action.type) {
    case marketConstants.ADD_MARKET_REQUEST:
      return {
        ...state
      };
    case marketConstants.ADD_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case marketConstants.ADD_MARKET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case marketConstants.GET_LIST_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.GET_LIST_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getMarketList.list,
        total: action.users.getMarketList.total
      };
    case marketConstants.GET_LIST_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case marketConstants.GETALL_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.GETALL_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllMarket,
        allMarketlist: action.users.getAllMarket,
      };
    case marketConstants.GETALL_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case marketConstants.UPDATE_MARKET_REQUEST:
      return {
        ...state
      };
    case marketConstants.UPDATE_MARKET_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case marketConstants.UPDATE_MARKET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case marketConstants.DELETE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.DELETE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.DELETE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case marketConstants.DISABLE_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case marketConstants.DISABLE_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case marketConstants.DISABLE_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}