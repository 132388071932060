import {
  userConstants, newsConstants
} from '../_constants';

export function news(state = {}, action) {

  switch (action.type) {
    case newsConstants.GET_GAME_TYPE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_GAME_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        gameTypeItems: action.users.getGameTypeById,
      };
    case newsConstants.GET_GAME_TYPE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.GET_ALL_GAME_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_ALL_GAME_TYPE_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        allGameItems: action.users.getAllGameTypes,
      };
    case newsConstants.GET_ALL_GAME_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.ADD_GAME_TYPE_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_GAME_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case newsConstants.ADD_GAME_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.ADD_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case newsConstants.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.GET_LIST_NEWS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_LIST_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        newsRowData: action.users.getNewsById,
        // total: action.users.getNewsById
      };
    case newsConstants.GET_LIST_NEWS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.GET_LIST_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_LIST_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.users.getNewsList.list,
        total: action.users.getNewsList.total
      };
    case newsConstants.GET_LIST_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.GETALL_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GETALL_NEWS_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        newsItems: action.users.getAllNews,
        newsTotal: action.users.getAllNews
      };
    case newsConstants.GETALL_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.UPDATE_GAME_TYPE_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_GAME_TYPE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
      };
    case newsConstants.UPDATE_GAME_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_GAME_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_GAME_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_GAME_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.GET_LIST_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        notificationItems: action.users.getNotificationList.list,
        notificationTotal: action.users.getNotificationList.total
      };
    case newsConstants.GET_LIST_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.DELETE_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case newsConstants.GET_GAME_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_GAME_TYPE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        getGameTypeList: action.users.getGameTypeList.list
      };
    case newsConstants.GET_GAME_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}