import {
  dateConstants
} from '../_constants';

export function date(state = {}, action) {
  // console.log('allGamesListByIdallGamesListByIdallGamesListById', state, "___", action);

  switch (action.type) {
    case dateConstants.ADD_DATE_REQUEST:
      return {
        ...state
      };
    case dateConstants.ADD_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case dateConstants.ADD_DATE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case dateConstants.GET_LIST_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dateConstants.GET_LIST_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        updateUserSuccess: false,
        loading: false,
        items: action.users.getDateList.list,
        total: action.users.getDateList.total
      };
    case dateConstants.GET_LIST_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dateConstants.GETALL_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dateConstants.GETALL_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGameslist: action.users.getAllGame,
      };
    case dateConstants.GETALL_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dateConstants.GETALL_DATE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dateConstants.GETALL_DATE_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allGamesListById: action.users.getAllGameByGameTypeId,
      };
    case dateConstants.GETALL_DATE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dateConstants.UPDATE_DATE_REQUEST:
      return {
        ...state
      };
    case dateConstants.UPDATE_DATE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true
      };
    case dateConstants.UPDATE_DATE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case dateConstants.DELETE_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dateConstants.DELETE_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case dateConstants.DELETE_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dateConstants.DISABLE_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dateConstants.DISABLE_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case dateConstants.DISABLE_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}