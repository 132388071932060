import {
  userConstants, infoConstants
} from '../_constants';

export function info(state = {}, action) {

  switch (action.type) {

    case infoConstants.UPDATE_MATCH_INFO_TEZ_REQUEST:
      return {
        ...state,
        loading: true
      };
    case infoConstants.UPDATE_MATCH_INFO_TEZ_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.updateMatchInfoTez.list,
        total: action.users.updateMatchInfoTez.total
      };
    case infoConstants.UPDATE_MATCH_INFO_TEZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case infoConstants.MATCHES_BY_CS_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case infoConstants.MATCHES_BY_CS_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.matchesByCsId.list,
        total: action.users.matchesByCsId.total
      };
    case infoConstants.MATCHES_BY_CS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
      case infoConstants.GET_MATCH_INFO_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case infoConstants.GET_MATCH_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getMatchInfoById,
        total: action.users.getMatchInfoById
      };
    case infoConstants.GET_MATCH_INFO_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case infoConstants.ADD_INFO_REQUEST:
      return {
        ...state
      };
    case infoConstants.ADD_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case infoConstants.ADD_INFO_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case infoConstants.GET_LIST_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case infoConstants.GET_LIST_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getInfoList.list,
        total: action.users.getInfoList.total
      };
    case infoConstants.GET_LIST_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case infoConstants.GETALL_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case infoConstants.GETALL_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        infoItems: action.users.getAllInfo,
        infoTotal: action.users.getAllInfo
      };
    case infoConstants.GETALL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_INFO_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_INFO_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}