export const blogConstants = {

    GET_BLOG_LIST_REQUEST: 'GET_BLOG_LIST_REQUEST',
    GET_BLOG_LIST_SUCCESS: 'GET_BLOG_LIST_SUCCESS',
    GET_BLOG_LIST_FAILURE: 'GET_BLOG_LIST_FAILURE',

    GETALL_BLOG_REQUEST: 'GETALL_BLOG_REQUEST',
    GETALL_BLOG_SUCCESS: 'GETALL_BLOG_SUCCESS',
    GETALL_BLOG_FAILURE: 'GETALL_BLOG_FAILURE',

    GET_LIST_BLOG_REQUEST: 'GET_LIST_BLOG_REQUEST',
    GET_LIST_BLOG_SUCCESS: 'GET_LIST_BLOG_SUCCESS',
    GET_LIST_BLOG_FAILURE: 'GET_LIST_BLOG_FAILURE',

    ADD_BLOG_REQUEST: 'ADD_BLOG_REQUEST',
    ADD_BLOG_SUCCESS: 'ADD_BLOG_SUCCESS',
    ADD_BLOG_FAILURE: 'ADD_BLOG_FAILURE',

    DELETE_BLOG_REQUEST: 'DELETE_BLOG_REQUEST',
    DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
    DELETE_BLOG_FAILURE: 'DELETE_BLOG_FAILURE',

    UPDATE_BLOG_REQUEST: 'UPDATE_BLOG_REQUEST',
    UPDATE_BLOG_SUCCESS: 'UPDATE_BLOG_SUCCESS',
    UPDATE_BLOG_FAILURE: 'UPDATE_BLOG_FAILURE',

    DISABLE_BLOG_REQUEST: 'DISABLE_BLOG_REQUEST',
    DISABLE_BLOG_SUCCESS: 'DISABLE_BLOG_SUCCESS',
    DISABLE_BLOG_FAILURE: 'DISABLE_BLOG_FAILURE',

};
