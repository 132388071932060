import { userConstants, videoConstants } from '../_constants';
import { userService, videoService } from '../_services';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions } from './';
// import { toast } from 'react-toastify';
export const videoActions = {

    login,
    logout,
    disableVideo,
    getAllVideo,
    createVideo,
    updateVideo,
    getVideoList,
    // getAllCategory,
    deleteVideo,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}


function createVideo(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        videoService.createVideo(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Video Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getVideoList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.ADD_VIDEO_REQUEST } }
    function success(users) { return { type: videoConstants.ADD_VIDEO_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.ADD_VIDEO_FAILURE, error } }
}
function updateVideo(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        videoService.updateVideo(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Video Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getVideoList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.UPDATE_VIDEO_REQUEST } }
    function success(users) { return { type: videoConstants.UPDATE_VIDEO_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.UPDATE_VIDEO_FAILURE, error } }
}
function getAllVideo(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        videoService.getAllVideo(data)
            .then(
                users => {
                    console.log("getAllVideo $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.GETALL_Video_REQUEST } }
    function success(users) { return { type: videoConstants.GETALL_Video_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.GETALL_Video_FAILURE, error } }
}
function getVideoList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        videoService.getVideoList(data)
            .then(
                users => {
                    console.log("getVideoList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.GET_VIDEO_LIST_REQUEST } }
    function success(users) { return { type: videoConstants.GET_VIDEO_LIST_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.GET_VIDEO_LIST_FAILURE, error } }
}

function deleteVideo(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        videoService.deleteVideo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getVideoList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.DELETE_VIDEO_REQUEST } }
    function success(users) { return { type: videoConstants.DELETE_VIDEO_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.DELETE_VIDEO_FAILURE, error } }
}
function disableVideo(data, paginationData) {

    return dispatch => {
        dispatch(request());
        videoService.disableVideo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getVideoList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: videoConstants.DISABLE_VIDEO_REQUEST } }
    function success(users) { return { type: videoConstants.DISABLE_VIDEO_SUCCESS, users } }
    function failure(error) { return { type: videoConstants.DISABLE_VIDEO_FAILURE, error } }
}
