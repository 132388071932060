import React from "react";

import Modal from 'react-modal';
// import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



export default function CreateUpdatePasswordDealer(props) {

  let { UpdatePasswordCreateModal, inputChange, handleAppSeetingHideModal, errorsUpdatePassword, updateNewsSubmit, fieldsUpdatePassword, handleFile,
    // allCategory, 
    contentFieldUpdate,
    setCurrentIndexUpdate, handleUpload, allGameItems,
    // handleUploadImageUser,
    handleSelectedTagUpdate, options, updateSelectedTag,
    deleteContentFieldUpdate, inputChangeContentUpdate, offset,
    // tagFieldUpdate, deletetagFieldUpdate, inputChangetagUpdate,
    rteChangeUpdate, modules, formats, categoryListItems, subCategoryListItems } = props;

  console.log("MODAL______fieldsUpdatePassword::", fieldsUpdatePassword);
  // console.log("MODAL______fieldsUpdatePassword.category::", fieldsUpdatePassword.category);

  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full">
            {/*gameNo*/}
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Update Slider</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}

            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">

                </div>
              </form>









              <form autoComplete="off">


                <div>
                  {/* <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium mt-2 mr-2">GameId:</label>
                  <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={fieldsUpdatePassword} id="gameId" name="gameId"
                    value={allGameItems && allGameItems["gameId"] ? allGameItems["gameId"] : null} >
                    <option selected>Plz Select game</option>
                    {
                      allGameItems && allGameItems && allGameItems.length > 0 ?
                        allGameItems.map((element, index) => (
                          <option value={element && element.id ? element.id : null}>{element && element.gameType ? element.gameType : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsUpdatePassword && errorsUpdatePassword["gameId"] ?
                    <div className="invalid-feedback text-yellow-600">
                      {errorsUpdatePassword["gameId"]}
                    </div>
                    : null}
                </div> */}
                </div>




                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Title</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["name"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="title" name="title" placeholder="Title" value={fieldsUpdatePassword.text} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["title"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["title"]}
                      </div>
                      : null}
                  </div>
                </div>


                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Slug</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["content"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="slug" name="slug" placeholder="Slug" value={fieldsUpdatePassword.text} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["slug"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["slug"]}
                      </div>
                      : null}
                  </div>
                </div>






                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image" className="bg-shine-400 flex justify-center py-2 uppercase px-2 border rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow " >
                    Upload Image
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>







                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateNewsSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
