
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const eventOptionService = {
    getAllEventOption,
    createEventOption,
    updateEventOption,
    getEventOptionList,
    deleteEventOption,
    disableEventOption,
    getEventOptionNoList,
    getEventOptionYesList
};


function getEventOptionYesList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEventOptionListByEventId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEventOptionYesList: data.data
            }
            console.log("i am in service getEventOptionYesList", userObj);

            return userObj;
        });
}

function getEventOptionNoList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEventOptionListByEventId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEventOptionNoList: data.data
            }
            console.log("i am in service getEventOptionNoList", userObj);

            return userObj;
        });
}

function getEventOptionList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEventOptionListByEventId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getEventOptionList: data.data
            }
            console.log("i am in service getEventOptionList", userObj);

            return userObj;
        });
}
function getAllEventOption() {
    // console.log("data m kya aa rha h::service:::::::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllEventOption`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllEventOption: data.data
            }
            console.log("i am in service getAllEventOption", userObj);

            return userObj;
        });
}
function deleteEventOption(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteEventOption`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteEventOption: data.data
            }
            console.log("i am in service''...>> deleteEventOption ::", userObj);

            return userObj;
        });
}
function disableEventOption(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEventOptionStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateEventOptionStatus: data.data
            }
            console.log("i am in service''...>> updateEventOptionStatus ::", userObj);

            return userObj;
        });
}

function updateEventOption(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEventOption`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateEventOption: data.data
            }
            console.log("I am in service updateEventOption", userObj);

            return userObj;
        });
}
function createEventOption(data) {
    console.log('service mai data aa rhe hai :::::', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createEventOption`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createEventOption: data.data
            }
            console.log("I am in service createEventOption", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                // logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}