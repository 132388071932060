import React from "react";

import Modal from 'react-modal';
// import { MultiSelect } from "react-multi-select-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



export default function CreateAddDealerModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsAddUser, inputAddUserChange, addNotificationSubmit, fieldsAddUser, handleFile, newsItems } = props;
  console.log("UserCreateModal__________________!!!!!!!!!!!!!", addUserCreateModal);

  return (

    <Modal
      isOpen={addUserCreateModal}
    // `content`Label="Example Modal"
    >


      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center newsItems-center animated fadeIn faster mt-10 overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full ">
            {/*Title*/}
            <div className="flex justify-between newsItems-center">
              <p className="text-2xl font-bold">Add News</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAddUserHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">
              <form autoComplete="on">
                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium mt-2 mr-2">News :</label>
                  <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="category" name="newsId"
                    value={fieldsAddUser && fieldsAddUser["newsId"] ? fieldsAddUser["newsId"] : null} >
                    <option selected>Plz Select Category</option>
                    {
                      newsItems && newsItems && newsItems.length > 0 ?
                        newsItems.map((element, index) => (
                          <option value={element.id}>{element && element.title ? element.title : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["newsId"] ?
                    <div className="invalid-feedback text-yellow-600">
                      {errorsAddUser["newsId"]}
                    </div>
                    : null}
                </div>
                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Title</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["title"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="title" name="title" placeholder="Title" value={fieldsAddUser.title} type="title" onChange={inputAddUserChange} />
                      {errorsAddUser && errorsAddUser["title"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsAddUser["title"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Body</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["body"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="body" name="body" placeholder="body" value={fieldsAddUser.body} type="body" onChange={inputAddUserChange} />
                      {errorsAddUser && errorsAddUser["body"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsAddUser["body"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Color</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["color"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="color" name="color" placeholder="color" value={fieldsAddUser.color} type="color" onChange={inputAddUserChange} />
                      {errorsAddUser && errorsAddUser["color"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsAddUser["color"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="mt-1  shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Priority</label>
                      <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["priority"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="priority" name="priority" placeholder="priority" value={fieldsAddUser.priority} type="priority" onChange={inputAddUserChange} />
                      {errorsAddUser && errorsAddUser["priority"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsAddUser["priority"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>




                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input
                    // style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>
                {/* <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                  <Multiselect
                    options={options}
                    onSelect={handleSelectedTag}
                    selected={selectedTag}
                    isObject={false}
                    // disableSearch={true}
                    // renderHeader={true}
                    displayValue="label"
                  // value={this.state.selected ? this.state.selected : null}
                  />
                </div> */}

                {/* <div className="flex justify-between w-full">
                  <div className="w-full mr-2">
                    {
                      fieldsAddUser && fieldsAddUser.imageUrlLink ?
                        <>

                          <div className="w-full mr-2 mt-1 ">
                            <img src={fieldsAddUser && fieldsAddUser.imageUrlLink ? fieldsAddUser.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        null
                    }
                    <div className="mt-1 relative shadow-md">
                      <div className="w-full flex justify-between flex-wrap">

                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                          <input type="file" name="image" onChange={handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>


                        <div className="w-20 space-x-4 mt-1">
                          <button className="border bg-blue-500 text-white px-4 text-base font-semibold py-1 rounded-lg cursor-pointer" type="button" onClick={() => handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </div> */}

                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 uppercase px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={addNotificationSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
